import React, {FC} from "react";
import {observer} from "mobx-react-lite";
import Button from "@material-ui/core/Button";
import SubTypeText from "./SubTypeText";
import store from "../store/store";
import {SUB_TYPES} from "../Constants/Constants";

const SubscribeButton: FC = observer(() => {
    return (
        <>
            <Button
                style={{
                    borderRadius: 5
                }}
                color="primary"
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                onClick={() => {
                    store.hostSubTypes === SUB_TYPES.BEVNET_NOSH ?
                        window.location.href = "https://app.bevnet.com/subscribe" :
                        window.location.href = "https://app.bevnet.com/subscribe/brewbound"

                }}
            >
                Become a <SubTypeText/> Insider
            </Button>
        </>
    )
})

export default SubscribeButton
