import {makeStyles} from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    grid_wrapper: {
        fontFamily: "adelle-sans",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#020608",
        "&.light": {
            backgroundColor: "#fff",
        },
    },
    register_wrapper: {
        position: "relative",
        maxWidth: 602,
        padding: '110px 0',
        "@media(max-width: 650px)": {
            padding: '50px 0',
            maxWidth: "92%",
        },
    },
    register_logo_wrapper: {
        textAlign: "center",
        padding: "4% 0",
        marginBottom: 40,
        borderRadius: 10,
        backgroundColor: "#020608",
    },
}));
