import React, {createContext, useContext, useEffect} from "react";
import {COOKIE_NAMES, NOMBASE_THEMES} from "../../Constants/Constants";
import {useCookies} from "react-cookie";
import {isPrefersColorSchemeLight} from "../../utils/isPrefersColorSchemeLight";

type ThemeContextType = {
    theme: string;
    isLightMode: boolean;
};

const defaultContext: ThemeContextType = {
    theme: NOMBASE_THEMES.DARK,
    isLightMode: false,
};

const ThemeContext = createContext<ThemeContextType | undefined>(
    defaultContext
);

export const useTheme = () => {
    // Ensure the hook is used within a ThemeProvider
    const context = useContext(ThemeContext);
    if (!context) {
        throw new Error("useTheme must be used within a ThemeProvider");
    }

    const [cookies] = useCookies([COOKIE_NAMES.COLOR_SCHEME]);

    const getTheme = () => {
        if (cookies && cookies[COOKIE_NAMES.COLOR_SCHEME]) {
            return cookies[COOKIE_NAMES.COLOR_SCHEME];
        } else {
            // Use prefers color scheme if no cookies found
            return isPrefersColorSchemeLight()
                ? NOMBASE_THEMES.LIGHT
                : NOMBASE_THEMES.DARK;
        }
    };

    const setBodyClass = (isLightMode: boolean) => {
        // If theme is not light mode, then add "dark" class to the body tag
        document.body.classList.toggle("dark", !isLightMode);
    };

    useEffect(() => {
        const theme = getTheme();
        setBodyClass(theme === NOMBASE_THEMES.LIGHT);
    }, [cookies]);

    return {
        theme: getTheme(),
        isLightMode: getTheme() === NOMBASE_THEMES.LIGHT,
    };
};

export const ThemeProvider: React.FC<{value: ThemeContextType}> = ({
    children,
    value,
}) => {
    return (
        <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
    );
};

export default ThemeContext;
