import {ReactElement, ReactNode} from "react";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";

import {useStyles} from "./styles";

interface ILogoItemProps {
    isCancelTopOffset?: boolean;
    isActive?: boolean;
    activeColor: string;
    orderNumber?: number;
    svg?: {
        color: string;
        activeColor: string;
    };
    onlyIcon?: boolean;
    logo: ReactNode | ReactElement;
}

const LogoItem = ({
    logo,
    activeColor,
    isActive = false,
    orderNumber = 1,
    svg,
    onlyIcon,
    isCancelTopOffset,
}: ILogoItemProps) => {
    const useStylesInner = makeStyles(() => ({
        item: {
            "& svg path": {
                fill: svg && (isActive ? svg?.activeColor : svg?.color),
            },
        },
    }));
    const classesInner = useStylesInner();

    const st = isActive
        ? {
              background: activeColor,
          }
        : {};
    const stOrder = {
        order: orderNumber,
    };
    const classes = useStyles();
    if (!onlyIcon) {
        return (
            <div
                className={clsx(classes.item_logo, classesInner.item, {
                    [classes.active]: isActive,
                    [classes.isCancelTopOffset]: isCancelTopOffset,
                })}
                style={{...st, ...stOrder}}
            >
                {logo}
            </div>
        );
    }
    return <>{logo}</>;
};

export default LogoItem;
