import {makeStyles} from "@material-ui/core";
import GoogleButton from "react-google-button";
import Store from "../../../store/store";
import {
    INPUT,
    UI_COLORS,
    FONT_FAMILY,
    FONT_WEIGHT,
    APP_COLORS,
} from "../../../Constants/LoginMagicStyleConstants";

const useStyles = makeStyles(() => ({
    google_btn: {
        color: `${APP_COLORS.GRAY} !important`,
        fontFamily: `${FONT_FAMILY} !important`,
        fontWeight: `${FONT_WEIGHT.MEDIUM} !important`,
        fontSize: "14px !important",
        lineHeight: "10px !important",
        display: "flex !important",
        alignItems: "center",
        justifyContent: "center",
        padding: INPUT.PADDING,
        width: "100% !important",
        borderRadius: `${INPUT.BORDER_RADIUS}px !important`,
        boxShadow: "none !important",
        border: `1px ${UI_COLORS.BORDER_COLOR} solid !important`,
        "&:hover": {
            border: `1px ${UI_COLORS.BORDER_COLOR_HOVER} solid !important`,
        },
        "& div": {
            display: "flex !important",
            alignItems: "center",
            justifyContent: "center",
            width: "23px !important",
            height: "23px !important",
            marginRight: "4px !important",
            "& svg": {
                position: "absolute",
                width: "42px !important",
                height: "42px !important",
            },
        },
    },
}));

const LoginGoogleButton = () => {
    const classes = useStyles();

    return (
        <GoogleButton
            className={classes.google_btn}
            label={"Continue with Google"}
            type={"light"}
            onClick={() => {
                window.location.href =
                    "/external/LoginGoogle?returnUrl=" + Store.returnUrl;
            }}
        />
    );
};

export default LoginGoogleButton;
