import {makeStyles} from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    nombase_logos_wrapper: {
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        gap: 40,
        marginBottom: 40,
        "&.light svg": {
            fill: "#000",
        },
    },
}));
