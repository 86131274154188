import React, {FC} from "react";
import Box from "@material-ui/core/Box";
import {FormHelperText} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {RegistrationStep} from "../../../Constants/RegistrationStep";
import registrationStore from "../../../store/registrationStore";
import useFetch from "../../../Hooks/useFetch";
import Loading from "../../../Components/Loading";
import Store from "../../../store/store";
import {observer} from "mobx-react-lite";
import BevnetTextField from "../../../Components/BevnetTextField/BevnetTextField";

type RegisterStep1Form = {
    email: string;
};

const RegisterStep1FormValidationSchema: Yup.SchemaOf<RegisterStep1Form> = Yup.object(
    {
        email: Yup.string()
            .required("Email is required")
            .email("Email is incorrect"),
    }
).defined();

const RegistrationFreeStep1: FC = observer(() => {
    const {
        register: registerStep1,
        handleSubmit: handleSubmitStep1,
        formState: {errors},
    } = useForm<RegisterStep1Form>({
        resolver: yupResolver(RegisterStep1FormValidationSchema),
    });
    const {isLoading, serverError, fetchNow} = useFetch(`/user/RegistrationStep1`);

    async function onSubmitStep1(data: RegisterStep1Form) {
        await fetchNow({
            data: {
                ...data,
                subType: Store.hostSubTypes
            },
            onSuccess: () => {
                registrationStore.setEmail(data.email);
                registrationStore.setCurrentStep(RegistrationStep.Step2);
            },
        });
    }

    return (
        <>
            <form noValidate onSubmit={handleSubmitStep1(onSubmitStep1)}>
                <BevnetTextField
                    autoFocus
                    error={!!errors.email}
                    helperText={errors.email?.message}
                    labelField="Email Address"
                    register={registerStep1("email")}
                />
                {serverError && (
                    <Box sx={{mt: 3}}>
                        <FormHelperText error>
                            <div dangerouslySetInnerHTML={{__html: serverError}}/>
                        </FormHelperText>
                    </Box>
                )}
                <Box sx={{mt: 2}}>
                    <Button
                        style={{
                            borderRadius: 5
                        }}
                        color="primary"
                        disabled={isLoading}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                    >
                        Send Verification Code
                    </Button>
                </Box>
            </form>
            <Loading isLoading={isLoading}/>
        </>
    );
});

export default RegistrationFreeStep1;
