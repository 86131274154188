import {FC, PropsWithChildren, useEffect} from "react";
import {useCookies} from "react-cookie";
import {useHistory} from "react-router-dom";
import Pages, {PageUrl} from "../Constants/Pages";
import Store from "../store/store";
import {observer} from "mobx-react-lite";
import useFetch from "../Hooks/useFetch";

const RedirectIfLoggedIn: FC<PropsWithChildren<any>> = observer(({children}) => {
    const [cookies] = useCookies(['bevnet_auth_token']);
    const history = useHistory();
    const {fetchNow} = useFetch(`/user/IsUserAuth`);

    async function IsTokenValid(token: string) {
        await fetchNow({
            data: {token: token},
            onSuccess: () => {
                history.push(PageUrl(Pages.LOGIN_SUCCESS, Store.returnUrl))
            }
        });
    }

    useEffect(()=>{
        if (cookies.bevnet_auth_token !== undefined) {
            IsTokenValid(cookies.bevnet_auth_token);
        }
    }, [])

    return (
        <>
            {children}
        </>
    );
})

export default RedirectIfLoggedIn
