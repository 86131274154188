import {ReactNode, memo} from "react";
import {Box, FormHelperText} from "@material-ui/core";
import {useStyles} from "./styles";

interface IProps {
    message: string | ReactNode;
    error?: boolean;
}

const NombaseFormHelperText = memo(({message, error = false}: IProps) => {
    const classes = useStyles();

    return (
        <Box className={classes.nombase_form_helper_text_wrapper}>
            <FormHelperText
                className={classes.nombase_form_helper_text}
                error={error}
            >
                {message}
            </FormHelperText>
        </Box>
    );
});

export default NombaseFormHelperText;
