export const APP_COLORS = {
    BEVNET: "#CF152D",
    BREWBOUND: "#14AAC0",
    NOSH: "#FFD54C",
    NOSH_BLACK: "#231F20",
    TASTE_RADIO: "#F15927",
    OFF_BLACK: "#020608",
    DARK_BLUE: "#091F2B",
    ACCENT: "#0079C4",
    ACCENT_DARK: "#005F99",
    GRAY: "#3E3E3E",
    GRAY_MEDIUM: "#666",
    GRAY_LIGHT: "#DEDEDE",
    GRAY_LIGHTER: "#CBD9E2",
};

export const UI_COLORS = {
    PLACEHOLDER_TEXT: APP_COLORS.GRAY_MEDIUM,
    BORDER_COLOR: APP_COLORS.GRAY_LIGHT,
    BORDER_COLOR_HOVER: APP_COLORS.GRAY,
    BORDER_COLOR_FOCUS: APP_COLORS.DARK_BLUE,
    BORDER_COLOR_LIGHT: APP_COLORS.GRAY_LIGHTER,
    BUTTON_COLOR_PRIMARY: APP_COLORS.OFF_BLACK,
    BUTTON_COLOR_PRIMARY_HOVER: APP_COLORS.DARK_BLUE,
    BUTTON_COLOR_SECONDARY: APP_COLORS.ACCENT,
    BUTTON_COLOR_SECONDARY_HOVER: APP_COLORS.ACCENT_DARK,
};

export const INPUT = {
    PADDING: "11px 16px 11px 16px",
    BORDER_RADIUS: 10,
};

export const LOGIN_WRAPPER = {
    BORDER: `1px solid ${UI_COLORS.BORDER_COLOR_LIGHT}`,
    BORDER_RADIUS: 20,
};

export const FONT_FAMILY =
    'Geomanist, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"';

export const FONT_WEIGHT = {
    REGULAR: 400,
    MEDIUM: 500,
    BOLD: 700,
};