import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles(() => ({
    field_label: {
        marginBottom: '4px !important',
        fontSize: '13px !important',
    },
    field: {
        marginBottom: 15,

        marginTop: '5px !important',
        '& fieldset': {
            borderColor: '#ACB0B7',
        },
        '& .MuiFormControl-root': {
            marginTop: 0,
        },
        '& .MuiOutlinedInput-root': {
            background: '#fff',
        },
        '& .MuiInputBase-root': {
            borderRadius: 5,
        },
        '& input': {
            height: 34,
            padding: '.5px 14px'
        }
    }
}))