import React, { FC } from "react";
import {
  Divider,
} from "@material-ui/core";
import Link from "@material-ui/core/Link";
import { Link as RouterLink } from "react-router-dom";
import Pages, {PageUrl} from "../Constants/Pages";
import OneColumnLayout from "../Components/OneColumnLayout";
import Header from "../Components/Header";
import Store from "../store/store";
import {observer} from "mobx-react-lite";

const PasswordSent: FC = observer(() => {
  return (
    <>
      <div data-cy="passwordSent">
        <OneColumnLayout title={"Password sent"}>
            <Header
                title={"Password sent"}
                secondTitle={"Your password has been sent to you via email."}
                />

            <Divider sx={{my: 3}}/>
            <Link
                color="textSecondary"
                component={RouterLink}
                to={PageUrl(Pages.LOGIN, Store.returnUrl)}
                variant="body2"
                data-cy="return"
                >
                Return to Login
            </Link>
        </OneColumnLayout>
      </div>
    </>
  );
});

export default PasswordSent;
