import {FC} from "react";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import MediaQuery from "react-responsive";
import {observer} from "mobx-react-lite";
import {CardContent, Typography} from "@material-ui/core";
import {Helmet} from "react-helmet-async";

import store from "../../store/store";

import Copyright from "../../Components/Copyright";
import LogoHeader from "./components/LogoHeader";
import { useStyles } from "./styles";
import { HOST } from "../../Constants/Constants";
import { useLocation } from "react-router-dom";

const SubscriptionRequired: FC = observer(() => {
    const classes = useStyles();

    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const email = query.get('email');

    const loginUrl = function getLoginUrl() {
        if(store.host === HOST.BEVNET) {
            return "https://auth.bevnet.com/?returnUrl=" + store.returnUrl;
        }
        if(store.host === HOST.NOSH) {
            return "https://auth.nosh.com/?returnUrl=" + store.returnUrl;
        }
        if(store.host === HOST.BREWBOUND) {
            return "https://auth.brewbound.com/?returnUrl=" + store.returnUrl;
        }
        if(store.host === HOST.NOMBASE) {
            return "https://auth.nombase.com/?returnUrl=" + store.returnUrl;
        }

        return "https://auth.bevnet.com/?returnUrl=" + store.returnUrl;
    }   

    return (
        <>
            <Helmet>
                <title>Login | BevNET.com</title>
            </Helmet>

            <div className={classes.wrapper}>
                <LogoHeader/>
                <CardContent className={classes.right_side}>
                    <div className={classes.right_inner_container}>
                    <Typography color="textPrimary" gutterBottom variant="h4" style={{
                                            marginBottom: 20,
                                            fontWeight: 700
                                        }}>
                            Whoops!
                    </Typography>
                    <Typography color="textPrimary" variant="body1" component="p" fontSize={16} style={{marginBottom: 20}}>
                        Looks like <Box fontWeight='fontWeightMedium' display='inline'>{email}</Box> is not associated with an Insider account. If you are a registered Insider, please log in with the email you used to create your account.
                    </Typography>       
                    <Typography color="textPrimary" variant="body1" component="p" fontSize={16}>
                        If you are not currently an Insider, we’d love to have you! Join our Insider community to gain access to an impossibly wide and deeply helpful body of content including daily news and insights, expert advice, educational videos and networking opportunities specifically for the food and beverage industry.
                    </Typography>
                    <div className={classes.buttons_wrapper}>
                        <Box sx={{mt: 2}} className={classes.buttons_flex}>
                            <Button
                                className={classes.btn_outline}
                                color="primary"
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                href={loginUrl()}
                            >
                                Log In
                            </Button>
                        </Box>
                        
                        <Box sx={{mt: 2}} className={classes.buttons_flex}>
                            <Button
                                className={classes.btn}
                                color="primary"
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                href={(store.host === HOST.BEVNET || store.host === HOST.NOSH) ?
                                    "https://app.bevnet.com/subscribe" :
                                    "https://app.brewbound.com/subscribe"}
                            >
                                Become an Insider
                            </Button>
                        </Box>
                    </div>
                    </div>
                    <MediaQuery maxWidth={768} >
                        <Copyright extraStyle={classes.mobile_copyright}/>
                    </MediaQuery>
                </CardContent>
            </div>

            <MediaQuery minWidth={769}>
                <Copyright/>
            </MediaQuery>

        </>
    );
});

export default SubscriptionRequired;
