import {makeAutoObservable} from "mobx";
import {HOST, SUB_TYPES} from "../Constants/Constants";

class Store {
    returnUrl: string = "https://www.bevnet.com";
    host: HOST;
    hostSubTypes: SUB_TYPES;

    constructor() {
        makeAutoObservable(this);
        this.host = HOST.BEVNET;
        this.hostSubTypes = SUB_TYPES.BEVNET_NOSH;
    }

    setHost(numb: number) {
        this.host = numb;
        if (numb === 2) {
            this.hostSubTypes = SUB_TYPES.BREWBOUND
        } else {
            this.hostSubTypes = SUB_TYPES.BEVNET_NOSH
        }
    }

    setReturnUrl(returnUrl: string) {
        if (returnUrl === "") {
            if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                returnUrl = "https://www.bevnet.com";
            } else {
                let hostArray = window.location.hostname.split('.');
                returnUrl = `https://www.${hostArray[hostArray.length - 2]}.${hostArray[hostArray.length - 1]}`;
            }
        }
        this.returnUrl = returnUrl;
    }
}

export default new Store();
