import {Typography, makeStyles} from "@material-ui/core";
import {mixin} from "../../../utils/mixin";
import {APP_COLORS} from "../../../Constants/LoginMagicStyleConstants";

const useStyles = makeStyles(() => ({
    divided_block: {
        ...mixin.flex("center", "center"),
        margin: "32px 0",
        "&:before, &:after": {
            content: '""',
            display: "block",
            height: 1,
            flexGrow: 2,
            background: APP_COLORS.GRAY_LIGHT,
        },
    },
}));

const LoginDividedOr = () => {
    const classes = useStyles();

    return (
        <div className={classes.divided_block}>
            <Typography
                color="textSecondary"
                fontSize={14}
                sx={{
                    display: "block",
                    margin: "0 6px",
                }}
            >
                or
            </Typography>
        </div>
    );
};

export default LoginDividedOr;