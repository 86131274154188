import React, {FC} from "react";
import Box from "@material-ui/core/Box";
import {FormHelperText} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import registrationStore from "../../../store/registrationStore";
import {RegistrationStep} from "../../../Constants/RegistrationStep";
import Loading from "../../../Components/Loading";
import useFetch from "../../../Hooks/useFetch";
import BevnetTextField from "../../../Components/BevnetTextField/BevnetTextField";

type RegisterStep2Form = {
    code: string;
};

const RegisterStep2FormValidationSchema: Yup.SchemaOf<RegisterStep2Form> = Yup.object(
    {
        code: Yup.string()
            .required("Code is incorrect"),
    }
).defined();

const RegistrationFreeStep2: FC = () => {
    const {register, handleSubmit, formState: {errors}} = useForm<RegisterStep2Form>({
        resolver: yupResolver(RegisterStep2FormValidationSchema),
    });
    const {isLoading, serverError, fetchNow} = useFetch(`/user/RegistrationStep2`);

    async function onSubmitStep(data: RegisterStep2Form) {
        await fetchNow({
            data: {...data, email: registrationStore.email},
            onSuccess: () => {
                registrationStore.setCurrentStep(RegistrationStep.Step3);
            },
        });
    }

    return (
        <>
            <form noValidate onSubmit={handleSubmit(onSubmitStep)}>
                <BevnetTextField
                    autoFocus
                    error={!!errors.code}
                    helperText={errors.code?.message}
                    labelField="Code"
                    register={register("code")}
                    placeholder="Enter your password"
                />
                {serverError && (
                    <Box sx={{mt: 3}}>
                        <FormHelperText error>{serverError}</FormHelperText>
                    </Box>
                )}
                <Box sx={{mt: 2}}>
                    <Button
                        style={{
                            borderRadius: 5
                        }}
                        color="primary"
                        disabled={isLoading}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                    >
                        Verify
                    </Button>
                </Box>
            </form>
            <Loading isLoading={isLoading}/>
        </>
    );
};

export default RegistrationFreeStep2;
