import React, {FC, useState} from "react";
import * as Yup from "yup";
import {useForm} from "react-hook-form";
import {observer} from "mobx-react-lite";
import {yupResolver} from "@hookform/resolvers/yup";
import {Helmet} from "react-helmet-async";
import Loading from "../../Components/Loading";
import useFetch from "../../Hooks/useFetch";
import Store from "../../store/store";
import useRecaptcha from "../../Hooks/useRecaptcha";
import {HOST} from "../../Constants/Constants";
import NombaseStep1 from "./components/nombase/NombaseStep1";
import BevnetStep1 from "./components/bevnet/BevnetStep1";
import NombaseStep2 from "./components/nombase/NombaseStep2";
import BevnetStep2 from "./components/bevnet/BevnetStep2";
import NombaseStep3 from "./components/nombase/NombaseStep3";
import BevnetStep3 from "./components/bevnet/BevnetStep3";

export type LoginFormStep1 = {
    email: string;
};

export type LoginFormStep2 = {
    password: string;
};

export type LoginWrapperStep1Props = {
    onSubmit: (data: LoginFormStep1)=> Promise<void>;
    CaptchaComponent: React.ComponentType;
    serverError: string;
}

export type LoginWrapperStep2Props = {
    onSubmit: (data: LoginFormStep2)=> Promise<void>;
    onMagic: ()=> Promise<void>;
    CaptchaComponent: React.ComponentType;
    serverError: string;
}

export type LoginWrapperStep3Props = {
    email: string;
    onBack: ()=> Promise<void>;
}

export const loginFormStep1ValidationSchema: Yup.SchemaOf<LoginFormStep1> = Yup.object({
    email: Yup.string()
        .required("Email is required")
        .email("Email is incorrect"),
}).defined();

export const loginFormStep2ValidationSchema: Yup.SchemaOf<LoginFormStep2> = Yup.object({
    password: Yup.string().required("Password is required"),
}).defined();

const Login: FC = observer(() => {
    const [isRemember, setIsRemember] = useState<boolean>(true);

    const {isLoading: isLoadingStep1, serverError: serverErrorStep1, fetchNow: fetchNowStep1} = useFetch(`/user/loginStep1`);
    const {isLoading: isLoadingStep2, serverError: serverErrorStep2, fetchNow: fetchNowStep2} = useFetch(`/user/loginStep2`);
    const {isLoading: isLoadingStep2Magic, serverError: serverErrorStep2Magic, fetchNow: fetchNowStep2Magic} = useFetch(`/user/LoginStep2CreateMagicLink`);
    const [step, setStep] = useState<number>(1);
    const [email, setEmail] = useState<string>("");

    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm<LoginFormStep1>({
        resolver: yupResolver(loginFormStep1ValidationSchema),
    });

    const {
        isValid: isRecaptchaValid,
        handler: recaptchaHandler,
        Component: CaptchaComponent,
    } = useRecaptcha();

    async function onSubmitStep1(data: LoginFormStep1) {
        if (!isRecaptchaValid) {
            return;
        }
        
        const captchaToken = await recaptchaHandler.getToken();

        await fetchNowStep1({
            data: {...data, captchaToken, returnUrl: Store.returnUrl},
            onSuccess: (response: any) => {
                setStep(response.data.step);
                setEmail(data.email);
            },
        });
    }

    async function onMagicStep2() {
        await fetchNowStep2Magic({
            data: { email: email, returnUrl: Store.returnUrl },
            onSuccess: (response: any) => {
                setStep(3);
            },
        });
    }

    async function onSubmitStep2(data: LoginFormStep2) {
        await fetchNowStep2({
            data: {...data, email: email},
            onSuccess: (response: any) => {
                const authToken = response.data;
                window.location.href = `/user/loginredirect?authToken=${authToken}&returnUrl=${Store.returnUrl}&remember=${isRemember}`;
            },
        });
    }

    async function onMagicStep3() {
        window.location.href = `/`;
    }

    return (
        <>
            <Helmet>
                <title>Login | BevNET.com</title>
            </Helmet>

            {(Store.host !== HOST.NOMBASE && step === 1) && (
                <BevnetStep1
                    CaptchaComponent={CaptchaComponent}
                    onSubmit={onSubmitStep1}
                    serverError={serverErrorStep1}
                />
            )}

            {(Store.host !== HOST.NOMBASE && step === 2) && (
                <BevnetStep2
                    onMagic={onMagicStep2}
                    CaptchaComponent={CaptchaComponent}
                    onSubmit={onSubmitStep2}
                    serverError={serverErrorStep2}
                />
            )}

            {(Store.host !== HOST.NOMBASE && step === 3) && (
                <BevnetStep3 
                    email={email}
                    onBack={onMagicStep3}
                />
            )}

            {(Store.host === HOST.NOMBASE && step === 1) && (
                <NombaseStep1
                    CaptchaComponent={CaptchaComponent}
                    onSubmit={onSubmitStep1}
                    serverError={serverErrorStep1}
                />
            )}

            {(Store.host === HOST.NOMBASE && step === 2) && (
                <NombaseStep2
                    onMagic={onMagicStep2}
                    CaptchaComponent={CaptchaComponent}
                    onSubmit={onSubmitStep2}
                    serverError={serverErrorStep2}
                />
            )}

            {(Store.host === HOST.NOMBASE && step === 3) && (
                <NombaseStep3 email={email} onBack={onMagicStep3} />
            )}

            <Loading isLoading={isLoadingStep1 || isLoadingStep2 || isLoadingStep2Magic} />
        </>
    );
});

export default Login;
