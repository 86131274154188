import type { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => createStyles({
    '@global': {
        '*': {
            boxSizing: 'border-box',
            margin: 0,
            padding: 0
        },
        html: {
            '-webkit-font-smoothing': 'antialiased',
            '-moz-osx-font-smoothing': 'grayscale',
            height: '100%',
            width: '100%'
        },
        body: {
            height: '100%',
            width: '100%'
        },
        '#root': {
            height: '100%',
            width: '100%'
        }
    }
}));

const GlobalStyles: FC = () => {
    useStyles();

    return null;
};

export default GlobalStyles;
