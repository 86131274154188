import {FC} from "react";
import {observer} from "mobx-react-lite";

import store from "../../../store/store";

import MobileModeLogos from "../../../Components/MobileModeLogos";
import { useStyles } from "../styles";


const LogoHeader: FC = observer(() => {
    const classes = useStyles();

    return (
        <div className={classes.header_logo}>
            <MobileModeLogos numberHost={store.host} extraClass={classes.extra_settings_header}/>
        </div>
    );
});

export default LogoHeader;

