import {FC} from "react";
import {Typography} from "@material-ui/core";
import {useStyles} from "./styles";
import RegistrationStore from "../../../../store/registrationStore";
import NombaseReturnToLoginButton from "../../../../Components/Nombase/NombaseReturnToLoginButton/NombaseReturnToLoginButton";

const NombaseRegisterFreeStep2: FC = () => {
    const classes = useStyles();

    return (
        <>
            <Typography
                sx={{fontFamily: "adelle-sans"}}
                textAlign="center"
            >
                Check your email (
                <span className={classes.color_highlight}>
                    {RegistrationStore.email}
                </span>
                ) for a link to continue!
            </Typography>
            <NombaseReturnToLoginButton long />
        </>
    );
};

export default NombaseRegisterFreeStep2;
