import React, {FC} from "react";
import {observer} from "mobx-react-lite";
import {Helmet} from "react-helmet-async";
import LoginWrapper from "../Login/components/LoginWrapper";
import LoginRightSideHead from "../Login/components/LoginRightSideHead";

const LoginMagicInvalid: FC = observer(() => {

    return (
        <>
            <Helmet>
                <title>Login | BevNET.com</title>
            </Helmet>
            <LoginWrapper>
                <LoginRightSideHead
                    title="Invalid link"
                    description={
                        "Sorry, this link is invalid or has already been used"
                    }
                />
            </LoginWrapper>
            

         
        </>
    );
});

export default LoginMagicInvalid;
