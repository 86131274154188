import React, {FC} from "react";
import Box from "@material-ui/core/Box";
import {FormHelperText} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {RegistrationSubmitStep} from "../../../Constants/RegistrationSubmitStep";
import registrationSubmitStore from "../../../store/registrationSubmitStore";
import useFetch from "../../../Hooks/useFetch";
import Loading from "../../../Components/Loading";
import Store from "../../../store/store";
import {observer} from "mobx-react-lite";
import BevnetTextField from "../../../Components/BevnetTextField/BevnetTextField";
import {useStyles} from "../styles";
import {useCookies} from "react-cookie";

type RegisterStep1Form = {
    email: string;
};

const RegisterStep1FormValidationSchema: Yup.SchemaOf<RegisterStep1Form> = Yup.object(
    {
        email: Yup.string()
            .required("Email is required")
            .email("Email is incorrect"),
    }
).defined();

const RegistrationSubmitStep1: FC = observer(() => {
    const classes = useStyles();
    const {
        register: registerStep1,
        handleSubmit: handleSubmitStep1,
        formState: {errors},
    } = useForm<RegisterStep1Form>({
        resolver: yupResolver(RegisterStep1FormValidationSchema),
    });
    const {isLoading, serverError, fetchNow} = useFetch(`/user/RegistrationStep1WithMagicLink`);
    const [cookie, setCookie] = useCookies(['bevnet_magic_link']);

    async function onSubmitStep1(data: RegisterStep1Form) {
        await fetchNow({
            data: {
                ...data,
                returnUrl: Store.returnUrl,
                subType: Store.hostSubTypes
            },
            onSuccess: (result: any) => {
                registrationSubmitStore.setEmail(data.email);
                registrationSubmitStore.setCurrentStep(RegistrationSubmitStep.Step2);
                setCookie('bevnet_magic_link', result.data);
            },
        });
    }

    return (
        <>
            <form noValidate onSubmit={handleSubmitStep1(onSubmitStep1)}>
                <BevnetTextField
                    autoFocus
                    error={!!errors.email}
                    helperText={errors.email?.message}
                    labelField="Email Address"
                    register={registerStep1("email")}
                    placeholder="email@gmail.com"
                    classNameForLabel={classes.registerSubmitLabel}
                />
                {serverError && (
                    <Box sx={{mt: 3}}>
                        <FormHelperText error>
                            <div dangerouslySetInnerHTML={{__html: serverError}} />
                        </FormHelperText>
                    </Box>
                )}
                <Box sx={{mt: 2}}>
                    <Button
                        className={classes.registerSubmitButton}
                        disabled={isLoading}
                        size="large"
                        type="submit"
                        variant="contained"
                    >
                        Send Verification Code
                    </Button>
                </Box>
            </form>
            <Loading isLoading={isLoading}/>
        </>
    );
});

export default RegistrationSubmitStep1;
