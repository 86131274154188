import React, {FC, useEffect} from "react";
import OneColumnLayout from "../../Components/OneColumnLayout";
import RegistrationSubmitStore from "../../store/registrationSubmitStore";
import RegisterSubmitHeader from "./Components/RegisterSubmitHeader";
import Box from "@material-ui/core/Box";
import {Typography} from "@material-ui/core";
import Link from "@material-ui/core/Link";
import {Link as RouterLink, useParams} from "react-router-dom";
import Pages, {PageUrl} from "../../Constants/Pages";
import Store from "../../store/store";
import {RegistrationSubmitStep} from "../../Constants/RegistrationSubmitStep";
import RegistrationSubmitStep3 from "./Components/RegisterSubmitStep3";
import RegistrationSubmitStep2 from "./Components/RegisterSubmitStep2";
import RegistrationSubmitStep1 from "./Components/RegisterSubmitStep1";
import {observer} from "mobx-react-lite";
import {useCookies} from "react-cookie";
import useFetch from "../../Hooks/useFetch";
import registrationSubmitStore from "../../store/registrationSubmitStore";
import store from "../../store/store";

type step2SecondTitleProps = {
    email: string;
};

export interface MagicLinkParams {
    linkGuid: string;
}

interface ValidateMagicLink {
    linkGuid: string;
    cookieGuid: string;
}

const Step2SecondTitle = ({email}: step2SecondTitleProps) => {
    return (
        <div style={{textWrap: "initial"}}>
            A verification code has been send to your email {email}. The email
            comes from hello@bevnet.com. If you do not see the email,{" "}
            <strong>please check your spam folder.</strong>
        </div>
    );
};

const RegisterSubmit: FC = observer(() => {
    const headerTitle = "Create an Account";
    const {linkGuid} = useParams<MagicLinkParams>();
    const [cookie] = useCookies(['bevnet_magic_link']);

    const {
        isLoading,
        serverError,
        fetchNow
    } = useFetch(`/user/checkmagiclink`);

    useEffect(() => {
        if (linkGuid === undefined)
            return;
        
        if (cookie.bevnet_magic_link === undefined)
            return;
        
        // validate cookie\guid set email and go to step 3
        (async () => {
            const data: ValidateMagicLink = {
                cookieGuid: cookie.bevnet_magic_link,
                linkGuid: linkGuid,
            };
            await fetchNow({
                data: data,
                onSuccess: (result: any) => {
                    registrationSubmitStore.setEmail(result.data.email);
                    registrationSubmitStore.setCurrentStep(RegistrationSubmitStep.Step3);
                    store.setReturnUrl(result.data.returnUrl);
                }
            });
        })()
        
    }, [linkGuid]);
    
    return (
        <>
            <div data-cy="registrationFree">
                <OneColumnLayout title={headerTitle} isRegisterSubmit>
                    {RegistrationSubmitStore.currentStep === RegistrationSubmitStep.Step1 && (
                        <RegisterSubmitHeader
                            title={headerTitle}
                            secondTitle={"Get started by verifying your email."}
                        />
                    )}
                    {RegistrationSubmitStore.currentStep === RegistrationSubmitStep.Step2 && (
                        <RegisterSubmitHeader
                            title=""
                            largeTitle="Please enter the code sent to your email"
                            secondTitle={
                                <Step2SecondTitle
                                    email={RegistrationSubmitStore.email}
                                />
                            }
                        />
                    )}
                    {RegistrationSubmitStore.currentStep === RegistrationSubmitStep.Step3 && (
                        <RegisterSubmitHeader
                            title={headerTitle}
                            secondTitle={"Tell us a bit about yourself."}
                        />
                    )}

                    <Box
                        sx={{
                            flexGrow: 1,
                            mt: 3,
                        }}
                    >
                        {RegistrationSubmitStore.currentStep === RegistrationSubmitStep.Step1 && (
                            <RegistrationSubmitStep1/>
                        )}
                        {RegistrationSubmitStore.currentStep === RegistrationSubmitStep.Step2 && (
                            <RegistrationSubmitStep2/>
                        )}
                        {RegistrationSubmitStore.currentStep === RegistrationSubmitStep.Step3 && (
                            <RegistrationSubmitStep3/>
                        )}
                    </Box>
                    <Typography sx={{ mt: 6 }} fontWeight={"medium"}>
                        Already have an account?{" "}
                        <Link
                            component={RouterLink}
                            to={PageUrl(Pages.LOGIN, Store.returnUrl)}
                            fontWeight={"medium"}
                        >
                            Log in
                        </Link>
                    </Typography>
                </OneColumnLayout>
            </div>
        </>
    )
})

export default RegisterSubmit;