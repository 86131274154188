import NombaseLoginLogo from "./NombaseLoginLogo";
import {Typography} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import {useStyles} from "./styles";

const NombaseLoginRightSideHead = () => {
    const classes = useStyles();

    return (
        <div className={classes.login_side_head}>
            <Box sx={{mb: 4}}>
                <NombaseLoginLogo />
            </Box>
            <Box sx={{mb: 1}}>
                <Typography
                    className={classes.title_description}
                    variant="h6"
                    color="#020608"
                    fontSize={20}
                    fontWeight={500}
                >
                    Log In to Explore Nombase
                </Typography>
            </Box>
            <Box sx={{mb: 3}}>
                <Typography
                    className={classes.subtitle_description}
                    variant="body1"
                    color="#000"
                    fontSize={14}
                    fontWeight={500}
                >
                    Our online community for CPG professionals
                </Typography>
            </Box>
        </div>
    );
};

export default NombaseLoginRightSideHead;
