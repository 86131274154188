import {makeStyles} from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    outline_btn: {
        "&.MuiButton-root": {
            fontFamily: "adelle-sans",
            fontSize: 14,
            fontWeight: 500,
            textTransform: "initial",
            padding: "11px 16px",
            borderRadius: 10,
            border: "1px solid #15A6FF",
            color: "#15A6FF",
            boxShadow: "none",
            backgroundColor: "#fff",
            transitionProperty: "color, background-color, border-color",
            transitionDuration: "250ms",
            transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
            "&:hover": {
                backgroundColor: "#EEF6F9",
                boxShadow: "none",
            },
            "&.Mui-disabled": {
                color: "#98B4CE",
                backgroundColor: "#fff",
                borderColor: "#98B4CE",
            },
        },
    },
    flat_btn: {
        "&.MuiButton-root": {
            color: "#fff",
            backgroundColor: "#15A6FF",
            "&:hover": {
                backgroundColor: "#1594E1",
                boxShadow: "none",
            },
            "&.Mui-disabled": {
                color: "#fff",
                backgroundColor: "#98B4CE",
            },
        },
    },
}));
