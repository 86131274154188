import {ReactNode, memo} from "react";
import clsx from 'clsx';
import {useStyles} from "./styles";

interface IProps {
    children?: ReactNode;
    centered?: boolean;
}

const NombaseRegisterButtons = memo(({children, centered = false}: IProps) => {
    const classes = useStyles();

    return <div className={clsx(classes.register_buttons, centered ? classes.center_buttons : '')}>{children}</div>;
});

export default NombaseRegisterButtons;
