import {makeStyles} from "@material-ui/core";
import {mixin} from "../../../../utils/mixin";
import {APP_COLORS} from "../../../../Constants/LoginMagicStyleConstants";

const defaultLogoStyles = {
    fill: "#fff",
    "& path": {
        fill: "#fff",
    },
};

export const useStyles = makeStyles(() => ({
    logos_wrapper: {
        ...mixin.flex("center", "center"),
        flexDirection: "column",
        gap: 20,
        width: "100%",
        maxWidth: 340,
    },
    bevnet: {
        "& svg": defaultLogoStyles,
    },
    brewbound: {
        "& svg": defaultLogoStyles,
    },
    nosh: {
        "& svg": {
            fill: APP_COLORS.NOSH,
            "& path": {
                fill: APP_COLORS.NOSH,
            },
        },
    },
    taste_radio: {
        "& svg": defaultLogoStyles,
    },
    active_logo: {
        width: "100%",
        height: "auto",
    },
    family_of_brands: {
        width: "100%",
        ...mixin.flex("center", "space-between"),
        gap: "12px",
        alignItems: "center",
    },
    family_of_brands_logo: {
        width: "auto",
        maxHeight: 18,
    },
}));
