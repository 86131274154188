import React, { useEffect } from "react";

const LoginIframeSuccess = () => {
    useEffect(() => {
        window.parent.postMessage({
            'func': "loginFromIframe"
        }, "*");
    });
    return (
        <></>
    )
}

export default LoginIframeSuccess