import {ReactNode, memo} from "react";
import {Button} from "@material-ui/core";
import clsx from "clsx";
import {useStyles} from "./styles";

interface IProps {
    type?: "button" | "submit" | "reset";
    buttonStyle?: "outline" | "flat";
    disabled?: boolean;
    fullWidth?: boolean;
    onClick?: () => void;
    cypressData?: string;
    children?: string | ReactNode;
}

const NombaseButton = memo(
    ({
        type = "button",
        buttonStyle = "flat",
        disabled = false,
        fullWidth = false,
        children: content,
        onClick,
        cypressData,
    }: IProps) => {
        const classes = useStyles();

        const buttonClasses = {
            outline: classes.outline_btn,
            flat: clsx(classes.outline_btn, classes.flat_btn),
        };

        return (
            <Button
                size="large"
                color="primary"
                variant="contained"
                type={type}
                fullWidth={fullWidth}
                disabled={disabled}
                className={buttonClasses[buttonStyle]}
                onClick={onClick}
                data-cy={cypressData}
            >
                {content}
            </Button>
        );
    }
);

export default NombaseButton;
