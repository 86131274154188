import LogoItem from "../../LogoItem/LogoItem";
import LogoBevNet from "../../icons/LogoBevNet";
import LogoBrewbound from "../../icons/LogoBrewbound";
import LogoTasteRadio from "../../icons/LogoTasteRadio";
import LogoNOSH from "../../icons/LogoNOSH";
import CenterIcon from "./CenterIcon";

import {useStyles} from "../styles";

//interface IMobileActiveLogoBevnetProps {}
const MobileActiveLogoNosh = () => {
    const classes = useStyles();
    return (
        <>
            <LogoItem
                isCancelTopOffset
                isActive={true}
                activeColor={'#231F20'}
                svg={{
                    activeColor: '#FFD54C',
                    color: '#231F20'
                }}
                logo={<LogoNOSH/>}
            />

            <div className={classes.line}>
                <LogoItem
                    onlyIcon
                    activeColor='#ce0e2d'
                    svg={{
                        activeColor: '#fff',
                        color: '#ce0e2d'
                    }}
                    logo={<LogoBevNet/>}
                />
                <CenterIcon>
                    <LogoItem
                        onlyIcon
                        activeColor='#F15927'
                        logo={<LogoTasteRadio/>}
                    />
                </CenterIcon>
                <LogoItem
                    onlyIcon
                    activeColor='#14AAC0'
                    svg={{
                        activeColor: '#fff',
                        color: '#000'
                    }}
                    logo={<LogoBrewbound/>}
                />
            </div>
        </>
    );
};

export default MobileActiveLogoNosh;
