export const THEMES = {
    LIGHT: "LIGHT",
    DARK: "DARK",
    NATURE: "NATURE",
    LOGIN_MAGIC: "LOGIN_MAGIC",
};

export const NOMBASE_THEMES = {
    LIGHT: "light",
    DARK: "dark",
}

export const COOKIE_NAMES = {
    COLOR_SCHEME: 'colorScheme'
}

export const IMAGES = {
    BEVNET_LOGO: "/img/BevNET-Logo.svg",
    NOSH_LOGO: "/img/NOSH-Logo.svg",
    BREWBOUND_LOGO: "/img/Brewbound-Logo.svg",
    TASTERADIO_HORIZONTAL_BLACK_LOGO: "/img/TasteRadio-Horizontal-Black-Logo.svg",
    TASTERADIO_HORIZONTAL_LOGO: "/img/TasteRadio-Horizontal-Logo.svg",
    TASTERADIO_HORIZONTAL_ORANGE_LOGO:
        "/img/TasteRadio-Horizontal-Orange-Logo.svg",
    TASTERADIO_STACKED_BLACK_LOGO: "/img/TasteRadio-Stacked-Black-Logo.svg",
    TASTERADIO_STACKED_LOGO: "/img/TasteRadio-Stacked-Logo.svg",
    TASTERADIO_STACKED_ORANGE_LOGO: "/img/TasteRadio-Stacked-Orange-Logo.svg",
};

export enum SUB_TYPES {
    BEVNET_NOSH = 1,
    BREWBOUND = 2,
}

export enum HOST {
    BEVNET = 1,
    BREWBOUND = 2,
    NOSH = 3,
    TASTE_RADIO = 4,
    NOMBASE = 5,
}

export const SETTINGS = {
    RECAPTCHA_PUBLIC_KEY: "6LfHpEAUAAAAAMkPNzp5m_-6zOD7RC4apC0Eigqh"
}

export const NOMBASE_BASE_URL = "https://www.nombase.com";

export const NOMBASE_PAGES = {
    TERMS_OF_SERVICE: `${NOMBASE_BASE_URL}/terms-of-service`,
    PRIVACY_POLICY: `${NOMBASE_BASE_URL}/privacy-policy`,
};