import {memo} from "react";

interface IProps {
    show: boolean;
}

const NombaseIconShow = memo(({show}: IProps) => (
    <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d={
                show
                    ? "M14.902 16.58A9.124 9.124 0 0 1 10 18C5.507 18 1.77 14.765.984 10.5a9.152 9.152 0 0 1 2.784-5.056L1.16 2.84 2.34 1.66l16.498 16.5-1.179 1.178-2.758-2.758ZM4.946 6.624a7.47 7.47 0 0 0-2.26 3.875 7.504 7.504 0 0 0 11 4.865l-1.69-1.69a3.75 3.75 0 0 1-5.17-5.171l-1.88-1.879Zm5.816 5.815L8.06 9.738a2.083 2.083 0 0 0 2.701 2.7v.001Zm6.577 1.887-1.192-1.192a7.448 7.448 0 0 0 1.167-2.635A7.504 7.504 0 0 0 7.96 4.948L6.645 3.633A9.157 9.157 0 0 1 10 2.999c4.493 0 8.232 3.234 9.016 7.5a9.122 9.122 0 0 1-1.677 3.827Zm-7.57-7.57a3.75 3.75 0 0 1 3.974 3.974L9.77 6.756Z"
                    : "M10.5 3c4.493 0 8.232 3.233 9.016 7.5-.783 4.267-4.522 7.5-9.016 7.5-4.493 0-8.231-3.233-9.016-7.5C2.268 6.233 6.007 3 10.5 3Zm0 13.333a7.504 7.504 0 0 0 7.314-5.833 7.504 7.504 0 0 0-14.628 0 7.504 7.504 0 0 0 7.314 5.833Zm0-2.083a3.75 3.75 0 1 1 0-7.5 3.75 3.75 0 0 1 0 7.5Zm0-1.667a2.083 2.083 0 1 0 0-4.166 2.083 2.083 0 0 0 0 4.166Z"
            }
            fill="#8D999F"
        />
    </svg>
));

export default NombaseIconShow;
