import * as React from "react"
import {SVGProps} from "react"

const LogoBevNet = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 666.67 133.03"
        {...props}
    >
        <path
            d="M666.67 26.7h-34.81V133h-39.5V26.7h-34.25V0h108.56ZM110.26 95.27c0 10.38-2.78 19.46-10.45 27.22S83.2 133 73 133H0V0h73c10.32 0 19.3 2.79 26.92 10.51s10.37 16.81 10.37 27.27c0 10.29-3.39 20.23-10.15 27.69q10.14 11.3 10.15 25.68Zm-39.5-55.64a12.84 12.84 0 0 0-3.59-9.29 12.35 12.35 0 0 0-9.11-3.64H39.5v26.66h18.56a11.82 11.82 0 0 0 9.23-3.9 14.42 14.42 0 0 0 3.47-9.83Zm0 53.19a12.3 12.3 0 0 0-12.7-12.76H39.5v26.32h18.56a11.84 11.84 0 0 0 9.23-3.88 14 14 0 0 0 3.47-9.68ZM303.22 0c-10.19 0-14 8.71-15.15 13.72-1.46 6.45-13.9 59.31-14.16 60.4-.25-1.09-12.69-53.95-14.15-60.4C258.63 8.71 254.8 0 244.6 0h-27.79l38.26 133h37.69L331 0Zm-90.69 26.77V0h-76.6a21 21 0 0 0-21 21v91a21 21 0 0 0 21 21h8.9c.2 0 .39.06.59.06h67.11v-26.78h-58.05V80.06h55.94v-26.7h-55.94V26.77Zm338.97 0V0h-76.6A21 21 0 0 0 454 21v91a21 21 0 0 0 20.95 21h8.91c.2 0 .38.06.58.06h67.06v-26.78h-58.05V80.06h55.94v-26.7h-55.94V26.77ZM408.51 0v73c-5.85-11.89-29.69-60.43-31-63.16C375.64 5.79 371 0 361.39 0h-25.47v133h38V62.68s28.12 57.53 29.57 60.61c1.89 4 5.92 9.74 16.43 9.74h26.62V0Zm246.7 110.67c7 0 11.43 4.91 11.43 11.16A10.88 10.88 0 0 1 655.32 133c-6.85 0-11.33-4.85-11.33-11.1a10.88 10.88 0 0 1 11.22-11.23Zm.11 19.57c5.23 0 8.3-3.55 8.3-8.41s-3.13-8.36-8.41-8.36c-5.07 0-8.2 3.51-8.2 8.42-.01 4.74 3.13 8.35 8.31 8.35Zm-1.14-6.68v4H651V116h4.75a3.87 3.87 0 0 1 4.2 3.78 3.45 3.45 0 0 1-1.83 3.18l2.16 4.69H657l-1.67-4Zm0-4.91V121h1.3a1.17 1.17 0 0 0 1.19-1.19 1.12 1.12 0 0 0-1.19-1.13Z"
            // style={{
            //     fill: "#ce0e2d",
            // }}
            data-name="Layer 2"
        />
    </svg>
)

export default LogoBevNet
