import {makeStyles} from "@material-ui/core";
import {mixin} from "../../utils/mixin";

export const useStyles = makeStyles((theme) => ({
    item_logo: {
        ...mixin.flex("center", "center"),
        padding: 15,
        height: 125,
        width: "100%",
        "& svg": {
            height: "fit-content",
            maxHeight: 31,
            "@media(min-width: 768px)": {
                "&.taste_radio_svg": {
                    maxHeight: 55,
                },
            },
        },
        "@media(max-width: 768px)": {
            height: "auto",
            padding: 15,
            "& svg": {
                maxHeight: 24,
                "&.taste_radio_svg": {
                    maxHeight: 36,
                },
            },
        },
        "&$active": {
            height: 138,
            margin: "13px 0",
            "& svg": {
                maxHeight: 55,
                "&.taste_radio_svg": {
                    maxHeight: 89,
                    "& .cls-4": {
                        fill: "rgb(241, 89, 39)",
                    },
                    "@media(max-width: 768px)": {
                        maxHeight: 67,
                    },
                },
            },
            "@media(max-width: 768px)": {
                height: 104,
                padding: 15,
                margin: 0,
                "& svg": {
                    maxHeight: 45,
                },
            },
        },
        "&$isCancelTopOffset": {
            marginTop: 0,
        },
    },
    active: {},
    isCancelTopOffset: {},
}));
