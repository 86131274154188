import React, { FC } from "react";
import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";

type componentProps = {
  title?: string;
  secondTitle?: JSX.Element | string;
};

const Header: FC<componentProps> = ({ title, secondTitle }: componentProps) => {
  return (
    <>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
          mb: 3,
        }}
      >
        <div>
          {title && (
            <Typography color="textPrimary" gutterBottom variant="h4">
              {title}
            </Typography>
          )}

          {secondTitle && (
            <Typography color="textSecondary" variant="body2">
              {secondTitle}
            </Typography>
          )}
        </div>
      </Box>
    </>
  );
};

export default Header;
