import {memo} from "react";
import {Helmet} from "react-helmet-async";

interface IProps {
    title?: string;
}

const NombaseHelmet = memo(({title}: IProps) => {
    return (
        <Helmet>
            <link rel="stylesheet" href="https://use.typekit.net/wfz0vum.css" />
            <link
                href="https://assets-global.website-files.com/61fafd236662c077faa5abac/62584ccc00cc4255ce14b282_Nombase-Favicon-V3.png"
                rel="shortcut icon"
                type="image/x-icon"
            />
            <link
                href="https://assets-global.website-files.com/61fafd236662c077faa5abac/62584f5b04f499c23ce42bb6_Nombase-Webclip-V1.png"
                rel="apple-touch-icon"
            />
            <title>{title || "Auth"} | Nombase</title>
        </Helmet>
    );
});

export default NombaseHelmet;
