import CountryInput from "./CountryInput";
import StateInput from "./StateInput";
import BevnetTextField from "./BevnetTextField/BevnetTextField";
import React, {FC} from "react";
import {FieldError, UseFormRegisterReturn} from "react-hook-form";

interface Props {
    countryRegister: UseFormRegisterReturn,
    countryError: FieldError | undefined,

    stateRegister: UseFormRegisterReturn,
    stateError: FieldError | undefined,

    cityRegister: UseFormRegisterReturn,
    cityError: FieldError | undefined,

    watch: any,
    setValues: any
}

const Address: FC<Props> = ({
                                countryRegister,
                                countryError,
                                stateRegister,
                                stateError,
                                cityRegister,
                                cityError,
                                watch,
                                setValues
                            }) => {
    return (
        <>
            <CountryInput register={countryRegister} setValue={setValues}/>
            {watch(countryRegister.name) === "USA" && (
                <StateInput register={stateRegister}/>
            )}
            {watch(countryRegister.name) !== "USA" && (
                <BevnetTextField
                    error={!!cityError}
                    helperText={cityError?.message}
                    labelField="City"
                    register={cityRegister}
                />
            )}


        </>
    );
}

export default Address;
