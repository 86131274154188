import RegistrationStore from "../store/registrationStore";
import TextField from "@material-ui/core/TextField";
import {CircularProgress} from "@material-ui/core";
import Autocomplete from "@mui/material/Autocomplete";
import React, {FC, useEffect} from "react";
import {observer} from "mobx-react-lite";
import {UseFormRegisterReturn} from "react-hook-form";

type Props = {
    register: UseFormRegisterReturn,
    setValue: any
}

const CountryInput: FC<Props> = observer(({register, setValue}: Props) => {
    useEffect(() => {
        RegistrationStore.fetchCountries()
    }, []);

    return (
        <>
            <Autocomplete
                getOptionLabel={(option): string => option.text}
                loading={RegistrationStore.isLoadingCountries}
                options={RegistrationStore.countries}
                // inputValue={inputValue}
                onInputChange={(event, newValue) => {
                    setValue("country", newValue);
                }}

                renderInput={(params): JSX.Element => (
                    <TextField
                        label="Country"
                        margin="normal"
                        {...register}
                        variant="outlined"
                        {...params}

                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {RegistrationStore.isLoadingCountries ?
                                        <CircularProgress color="inherit" size={20}/> : null}
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                        }}
                    />
                )}
            />

        </>
    );
})

export default CountryInput