import {FC} from "react";
import clsx from "clsx";
import {observer} from "mobx-react-lite";
import {makeStyles} from "@material-ui/core";
import {mixin} from "../../../utils/mixin";
import {HOST} from "../../../Constants/Constants";
import {
    LOGIN_WRAPPER,
    APP_COLORS,
} from "../../../Constants/LoginMagicStyleConstants";
import store from "../../../store/store";
import LoginActiveLogo from "./LoginActiveLogo/LoginActiveLogo";

const useStyles = makeStyles(() => ({
    wrapper: {
        ...mixin.flex("center", "center"),
        height: "100%",
        padding: "38px",
        borderTopLeftRadius: LOGIN_WRAPPER.BORDER_RADIUS,
        borderBottomLeftRadius: LOGIN_WRAPPER.BORDER_RADIUS,
        borderLeft: LOGIN_WRAPPER.BORDER,
        borderTop: LOGIN_WRAPPER.BORDER,
        borderBottom: LOGIN_WRAPPER.BORDER,
        "@media(max-width: 768px)": {
            borderRadius: 0,
            border: "none",
        },
    },
    left_side: {
        textAlign: "center",
        width: "100%",
        alignSelf: "stretch",
        flexBasis: "100%",
        maxWidth: 415,
        "@media(max-width: 768px)": {
            maxWidth: "100%",
        },
    },
    bevnet: {
        background: APP_COLORS.BEVNET,
    },
    brewbound: {
        background: APP_COLORS.BREWBOUND,
    },
    nosh: {
        background: APP_COLORS.NOSH_BLACK,
    },
    taste_radio: {
        background: APP_COLORS.TASTE_RADIO,
    },
}));

const LoginLeftSide: FC = observer(() => {
    const classes = useStyles();

    const hostClass = {
        [HOST.BEVNET.valueOf()]: classes.bevnet,
        [HOST.BREWBOUND.valueOf()]: classes.brewbound,
        [HOST.NOSH.valueOf()]: classes.nosh,
        [HOST.TASTE_RADIO.valueOf()]: classes.taste_radio,
    };

    return (
        <div className={classes.left_side}>
            <div className={clsx(classes.wrapper, hostClass[store.host])}>
                <LoginActiveLogo numberHost={store.host} />
            </div>
        </div>
    );
});

export default LoginLeftSide;
