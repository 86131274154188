import {makeAutoObservable} from "mobx";
import {RegistrationStep} from "../Constants/RegistrationStep";
import axios from "axios";
import {selectListItem} from "../Types/SelectListItem";

class RegistrationStore {
    currentStep: RegistrationStep = RegistrationStep.Step1;
    email: string = "";
    code: string = "";

    countries: selectListItem<string>[] = [];
    isLoadingCountries: boolean = false;

    states: selectListItem<string>[] = [];
    isLoadingStates: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    setEmail(email: string){
        this.email = email;
    }

    setCurrentStep(step: RegistrationStep) {
        this.currentStep = step;
    }

    fetchCountries() {
        if (this.countries.length !== 0) {
            return;
        }
        this.isLoadingCountries = true;
        axios.get('/misc/GetCountries')
            .then((res) => {
                this.countries = res.data;
            })
            .finally(() => {
                this.isLoadingCountries = false;
            });
    }

    fetchStates() {
        if (this.states.length !== 0) {
            return;
        }
        this.isLoadingStates = true;
        axios.get('/misc/GetStates')
            .then((res) => {
                this.states = res.data;
            })
            .finally(() => {
                this.isLoadingStates = false;
            });
    }
}

export default new RegistrationStore();
