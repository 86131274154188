import React from 'react';
import {useStyles} from "../styles";
import {IChildrenProps} from "../../../interfaces";

interface ICenterIconProps extends IChildrenProps {
}

const CenterIcon = ({children}: ICenterIconProps) => {
    const classes = useStyles();
    return (
        <>
            <div className={classes.dot} data-id='dot' />
            {children}
            <div className={classes.dot} data-id='dot' />
        </>
    );
};

export default CenterIcon;
