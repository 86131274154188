import {makeAutoObservable} from "mobx";
import axios from "axios";
import {selectListItem} from "../Types/SelectListItem";
import {RegistrationSubmitStep} from "../Constants/RegistrationSubmitStep";

class RegistrationSubmitStore {
    currentStep: RegistrationSubmitStep = RegistrationSubmitStep.Step1;
    email: string = "";
    code: string = "";

    countries: selectListItem<string>[] = [];
    isLoadingCountries: boolean = false;

    states: selectListItem<string>[] = [];
    isLoadingStates: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    setEmail(email: string){
        this.email = email;
    }

    setCurrentStep(step: RegistrationSubmitStep) {
        this.currentStep = step;
    }
}

export default new RegistrationSubmitStore();
