import {makeStyles} from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    register_buttons: {
        display: "flex",
        justifyContent: "space-between",
        gap: 16,
        "@media(max-width: 650px)": {
            flexWrap: "wrap",
        },
    },
    center_buttons: {
        justifyContent: "center",
    },
}));
