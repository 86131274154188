import RegistrationStore from "../store/registrationStore";
import TextField from "@material-ui/core/TextField";
import {CircularProgress} from "@material-ui/core";
import Autocomplete from "@mui/material/Autocomplete";
import React, {FC, useEffect} from "react";
import {observer} from "mobx-react-lite";
import {UseFormRegisterReturn} from "react-hook-form";

type Props = {
    register: UseFormRegisterReturn,
    setValue?: any
}

const StateInput: FC<Props> = observer(({register}: Props) => {
    useEffect(() => {
        RegistrationStore.fetchStates()
    }, []);
    return (
        <>
            <Autocomplete
                getOptionLabel={(option): string => option.text}
                loading={RegistrationStore.isLoadingStates}
                options={RegistrationStore.states}
                renderInput={(params): JSX.Element => (
                    <TextField
                        label="States"
                        margin="normal"
                        {...register}
                        variant="outlined"
                        {...params}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {RegistrationStore.isLoadingCountries ? <CircularProgress color="inherit" size={20}/> : null}
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                        }}
                    />
                )}
            />

        </>
    );
});

export default StateInput