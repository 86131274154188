import React, {FC, PropsWithChildren} from "react";
import {UseFormRegisterReturn} from "react-hook-form";
import {Checkbox, Typography} from "@material-ui/core";
import Box from "@material-ui/core/Box";

type ComponentProps = PropsWithChildren<{
    register: UseFormRegisterReturn
}>
const BevnetCheckbox: FC<ComponentProps> = ({register, children}: ComponentProps) => {
    return (
        <Box
            sx={{
                alignItems: 'center',
                display: 'flex',
                mt: 3
            }}
        >
            <Checkbox
                color="primary"
                {...register}
            />
            <Typography
                color="textPrimary"
                variant="body1"
            >
                {children}
            </Typography>
        </Box>
    );
}

export default BevnetCheckbox