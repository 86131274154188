import {makeStyles} from "@material-ui/core";

const gridAreas = [
    ["firstName", "lastName"],
    ["company", "phone"],
    ["password", "confirm"],
];

const fields = Object.fromEntries(
    Array.from(gridAreas.flat()).map((area, i) => [
        `&:nth-child(${i + 1})`,
        {gridArea: area},
    ])
);

export const useStyles = makeStyles((theme) => ({
    color_highlight: {
        color: "#15A6FF",
    },
    create_account_form_fields: {
        marginBottom: 20,
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)",
        gridTemplateAreas: gridAreas
            .map((row) => `"${row.join(" ")}"`)
            .join(" "),
        rowGap: 20,
        columnGap: 24,
        "& div": {
            ...fields,
        },
        "@media (max-width: 650px)": {
            display: "flex",
            flexDirection: "column",
        },
    },
    password_check_list: {
        marginTop: 6,
        listStyle: "none",
        "& li": {
            fontFamily: "adelle-sans",
            fontSize: 13,
            lineHeight: "20px",
            color: "#767676",
            "&:before": {
                content: '""',
                display: "inline-block",
                width: 8,
                height: 8,
                borderRadius: "50%",
                marginRight: 8,
                backgroundColor: "#D9D9D9",
            },
            "&.valid": {
                color: "#008747",
                "&:before": {
                    backgroundColor: "#008747",
                },
            },
        },
    },
    create_account_disclaimer: {
        "&.MuiTypography-root": {
            marginTop: 8,
        },
    },
}));
