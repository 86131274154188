import React, {memo} from "react";
import clsx from "clsx";
import {HOST} from "../../Constants/Constants";
import {useStyles} from "./styles";
import LogoBevNet from "../icons/LogoBevNet";
import LogoBrewbound from "../icons/LogoBrewbound";
import LogoNOSH from "../icons/LogoNOSH";
import LogoTasteRadioFlat from "../icons/LogoTasteRadioFlat";

interface IProps {
    numberHost: HOST | null;
}

const MobileModeLogosNew = ({numberHost = null}: IProps) => {
    const classes = useStyles();

    const headerSiteClass = {
        [HOST.BEVNET]: classes.headerBevNet,
        [HOST.BREWBOUND]: classes.headerBrewbound,
        [HOST.NOSH]: classes.headerNosh,
        [HOST.TASTE_RADIO]: classes.headerTasteRadio,
        [HOST.NOMBASE]: classes.headerNombase,
    };

    const HeaderLogo = () => {
        switch (numberHost) {
            case HOST.BEVNET:
                return <LogoBevNet />;
            case HOST.BREWBOUND:
                return <LogoBrewbound />;
            case HOST.NOSH:
                return <LogoNOSH />;
            case HOST.TASTE_RADIO:
                return <LogoTasteRadioFlat />;
            default:
                return null;
        }
    };

    if (numberHost)
        return (
            <>
                <div
                    className={clsx(
                        classes.header,
                        headerSiteClass[numberHost]
                    )}
                >
                    <HeaderLogo />
                </div>
                <div className={classes.wrapper}>
                    {numberHost !== HOST.BEVNET && (
                        <LogoBevNet className={classes.logoBevnet} />
                    )}
                    {numberHost !== HOST.BREWBOUND && <LogoBrewbound />}
                    {numberHost !== HOST.NOSH && (
                        <LogoNOSH className={classes.logoNosh} />
                    )}
                    {numberHost !== HOST.TASTE_RADIO && <LogoTasteRadioFlat />}
                </div>
            </>
        );

    return null;
};

export default memo(MobileModeLogosNew);
