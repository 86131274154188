import {makeStyles} from "@material-ui/core";

export const useStyles = makeStyles(() => ({
    field_label: {
        "&.MuiTypography-root": {
            display: "block",
            fontFamily: "adelle-sans",
            fontSize: "14px",
            fontWeight: 500,
            marginBottom: "8px",
            color: "#020608",
        },
    },
    field_label_optional: {
        color: "#666",
    },
    field: {
        color: "#666",
        background: "#fff",
        margin: "5px 0 0",
        "& fieldset": {
            borderRadius: 10,
            borderColor: "#DEDEDE",
            transition: "all 250ms cubic-bezier(0.4, 0, 0.2, 1)",
        },
        "& .MuiFormControl-root": {
            marginBottom: "8px",
        },
        "& .MuiInputBase-root": {
            padding: "0",
            "&:hover fieldset": {
                borderColor: "#50BDFF",
            },
            "&.Mui-focused fieldset": {
                borderWidth: 1,
                borderColor: "#50BDFF",
            },
            "&.Mui-focused input": {
                color: "#091F2B",
            },
            "&.Mui-error": {
                fontFamily: "adelle-sans",
                borderColor: "#CE3A3A",
            },
            "&.Mui-error fieldset": {
                fontFamily: "adelle-sans",
                borderColor: "#CE3A3A",
            },
            "& :has(.MuiInputAdornment-root)": {
                paddingRight: "4px",
            },
        },
        "& input.MuiInputBase-input": {
            height: 46,
            fontFamily: "adelle-sans",
            padding: "0 14px",
            fontSize: 14,
            fontWeight: 400,
            borderRadius: "10px",
            color: "#666",
            "&::placeholder": {
                opacity: 1,
                fontFamily: "adelle-sans",
                fontSize: 14,
                fontWeight: 400,
                color: "#666",
            },
            '&[type="password"]': {
                paddingRight: "0",
            },
        },
        "& .MuiFormHelperText-root": {
            fontFamily: "adelle-sans",
            "&.Mui-error": {
                color: "#CE3A3A",
            },
        },
    },
}));
