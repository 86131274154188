import {memo} from "react";
import {FormHelperText, Box, Button} from "@material-ui/core";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import Store from "../../../../store/store";
import Pages, {PageUrl} from "../../../../Constants/Pages";
import {
    LoginFormStep1,
    LoginWrapperStep1Props,
    loginFormStep1ValidationSchema,
} from "../../index";
import LoginTextField from "../../components/LoginTextField";
import LoginWrapper from "../LoginWrapper";
import LoginRightSideHeadSteps from "../LoginRightSideHeadSteps";
import LoginDividedOr from "../LoginDividedOr";
import LoginCreateAccountLink from "../LoginCreateAccountLink";
import LoginGoogleButton from "../LoginGoogleButton";

const BevnetStep1 = memo(
    ({onSubmit, CaptchaComponent, serverError}: LoginWrapperStep1Props) => {
        const {
            register,
            handleSubmit,
            formState: {errors},
        } = useForm<LoginFormStep1>({
            resolver: yupResolver(loginFormStep1ValidationSchema),
        });

        return (
            <LoginWrapper>
                <LoginRightSideHeadSteps step={1} />
                <form noValidate onSubmit={handleSubmit(onSubmit)}>
                    <CaptchaComponent />
                    <LoginTextField
                        id="email"
                        labelText="Email"
                        autoFocus
                        error={!!errors.email}
                        helperText={errors.email?.message}
                        register={register("email")}
                        placeholder="Enter your email address"
                    />
                    {serverError && (
                        <Box sx={{mt: 3}}>
                            <FormHelperText error>{serverError}</FormHelperText>
                        </Box>
                    )}
                    <Box sx={{mt: 2}}>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            fullWidth
                        >
                            Continue
                        </Button>
                    </Box>
                    <LoginDividedOr />
                    <LoginGoogleButton />
                </form>
                <LoginCreateAccountLink
                    url={PageUrl(Pages.REGISTER, Store.returnUrl)}
                />
            </LoginWrapper>
        );
    }
);

export default BevnetStep1;
