import {useState, useEffect, ReactNode, memo} from "react";
import {Box, Typography} from "@material-ui/core";
import clsx from "clsx";
import {useTheme} from "../../../theme/nombase";
import {useStyles} from "./styles";

interface IProps {
    title: string | ReactNode;
    description?: string | ReactNode;
    centerDescription?: boolean;
    children?: ReactNode;
    shrink?: boolean;
}

const NombaseRegisterBox = memo(
    ({
        title,
        description,
        centerDescription,
        children,
        shrink = false,
    }: IProps) => {
        const classes = useStyles();
        const {isLightMode} = useTheme();
        const [fadeClass, setFadeClass] = useState("");

        useEffect(() => {
            setFadeClass("fade-enter");
        }, [title, description, children]);

        return (
            <div
                className={clsx(
                    classes.register_box,
                    shrink && classes.register_box_shrink,
                    isLightMode ? "light" : undefined
                )}
            >
                <Box
                    mb={shrink ? 2 : 4}
                    textAlign="center"
                    className={clsx(classes.register_transition, fadeClass)}
                >
                    <Typography
                        mb={description ? 2 : 0}
                        variant="h4"
                        className={classes.register_title}
                    >
                        {title}
                    </Typography>
                    {description && (
                        <Typography
                            variant="body1"
                            textAlign={centerDescription ? "center" : "left"}
                            className={classes.register_description}
                        >
                            {description}
                        </Typography>
                    )}
                </Box>
                {children}
            </div>
        );
    }
);

export default NombaseRegisterBox;
