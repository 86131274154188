import {ReactNode, memo} from "react";
import {CardContent, makeStyles} from "@material-ui/core";
import {LOGIN_WRAPPER} from "../../../Constants/LoginMagicStyleConstants";

export const useStyles = makeStyles(() => ({
    right_side: {
        background: "#FFF",
        maxWidth: 515,
        flexBasis: "100%",
        borderTop: LOGIN_WRAPPER.BORDER,
        borderRight: LOGIN_WRAPPER.BORDER,
        borderBottom: LOGIN_WRAPPER.BORDER,
        borderTopRightRadius: LOGIN_WRAPPER.BORDER_RADIUS,
        borderBottomRightRadius: LOGIN_WRAPPER.BORDER_RADIUS,
        "@media(max-width: 768px)": {
            padding: "38px 30px 10px",
            maxWidth: "100%",
            borderRadius: 0,
            border: "none",
        },
    },
    right_inner_container: {
        width: "100%",
        maxWidth: 317,
        margin: "0 auto",
        "@media(max-width: 768px)": {
            maxWidth: "350px",
        },
    },
}));

interface IProps {
    children: ReactNode;
}

const RightSide = memo(({children}: IProps) => {
    const classes = useStyles();

    return (
        <CardContent className={classes.right_side}>
            <div className={classes.right_inner_container}>{children}</div>
        </CardContent>
    );
});

export default RightSide;
