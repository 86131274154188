import {FC} from "react";
import {UseFormRegisterReturn} from "react-hook-form";
import {
    Box,
    TextField,
    Typography,
    BaseTextFieldProps,
} from "@material-ui/core";
import {FONT_WEIGHT} from "../../../Constants/LoginMagicStyleConstants";

type IProps = BaseTextFieldProps & {
    type?: "text" | "password" | "email";
    id?: string;
    labelText?: string;
    register?: UseFormRegisterReturn;
};

const LoginTextField: FC<IProps> = ({
    type = "text",
    id,
    labelText,
    register,
    ...otherProps
}: IProps) => {
    const safeId = id || `login-magic-input-${new Date().getTime()}`;

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
            }}
        >
            {labelText && (
                <Typography
                    color="textSecondary"
                    component="label"
                    htmlFor={safeId}
                    fontWeight={FONT_WEIGHT.REGULAR}
                    fontSize={14}
                >
                    {labelText}
                </Typography>
            )}
            <TextField
                variant="outlined"
                type={type}
                id={safeId}
                fullWidth
                {...otherProps}
                {...register}
            />
        </Box>
    );
};

export default LoginTextField;
