import {memo, ReactNode} from "react";
import {Typography, Box} from "@material-ui/core";
import {FONT_WEIGHT} from "../../../Constants/LoginMagicStyleConstants";

interface IProps {
    title: string | ReactNode;
    description: string | ReactNode;
}

const LoginRightSideHead = memo(({title, description}: IProps) => {
    return (
        <Box
            sx={{
                mb: 4,
                mt: 7,
                "@media(max-width: 768px)": {
                    mt: 4,
                },
            }}
        >
            <Typography
                color="textPrimary"
                fontWeight={FONT_WEIGHT.MEDIUM}
                fontSize={20}
                lineHeight={"20px"}
                textAlign="center"
                sx={{
                    mb: "8px",
                }}
            >
                {title}
            </Typography>
            <Typography
                color="textSecondary"
                fontSize={14}
                lineHeight={"18px"}
                textAlign="center"
            >
                {description}
            </Typography>
        </Box>
    );
});

export default LoginRightSideHead;
