import {FC} from "react";
import {observer} from "mobx-react-lite";
import {useStyles} from "./styles";

const NombaseLeftSide: FC = observer(() => {
    const classes = useStyles();

    return (
        <div className={classes.left_side}>
            <img
                src="./img/nombase-login-logo.webp"
                className={classes.logo_img}
                alt="Nombase Login"
            />
        </div>
    );
});

export default NombaseLeftSide;
