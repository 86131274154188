import LogoItem from "../../LogoItem/LogoItem";
import LogoBevNet from "../../icons/LogoBevNet";
import LogoBrewbound from "../../icons/LogoBrewbound";
import LogoTasteRadio from "../../icons/LogoTasteRadio";
import LogoNOSH from "../../icons/LogoNOSH";
import CenterIcon from "./CenterIcon";

import {useStyles} from "../styles";

//interface IMobileActiveLogoBevnetProps {}
const MobileActiveLogoBevnet = () => {
    const classes = useStyles();
    return (
        <>
            <LogoItem
                isCancelTopOffset
                isActive={true}
                activeColor={'#ce0e2d'}
                svg={{
                    activeColor: '#fff',
                    color: '#ce0e2d'
                }}
                logo={<LogoBevNet/>}
            />

            <div className={classes.line}>
                <LogoItem
                    onlyIcon
                    activeColor='#14AAC0'
                    svg={{
                        activeColor: '#fff',
                        color: '#000'
                    }}
                    logo={<LogoBrewbound/>}
                />
                <CenterIcon>
                    <LogoItem
                        onlyIcon
                        activeColor='#F15927'
                        logo={<LogoTasteRadio/>}
                    />
                </CenterIcon>
                <LogoItem
                    onlyIcon
                    activeColor='#54BA5D'
                    svg={{
                        activeColor: '#fff',
                        color: '#54BA5D'
                    }}
                    logo={<LogoNOSH/>}
                />
            </div>
        </>
    );
};

export default MobileActiveLogoBevnet;
