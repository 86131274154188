import {ReactNode, memo} from "react";
import {makeStyles} from "@material-ui/core";
import MediaQuery from "react-responsive";
import {mixin} from "../../../utils/mixin";
import Copyright from "../../../Components/Copyright";
import LoginLeftSide from "./LoginLeftSide";
import LoginRightSide from "./LoginRightSide";

export const useStyles = makeStyles((theme) => ({
    wrapper: {
        minHeight: "100vh",
        ...mixin.flex("center", "space-between"),
        flexDirection: "column",
    },
    content_wrapper: {
        width: "100%",
        maxWidth: 930,
        margin: "80px auto 0",
        ...mixin.flex("center", "center"),
        "@media(max-width: 768px)": {
            display: "block",
            marginTop: 0,
            border: 0,
        },
    },
}));

interface IProps {
    children: ReactNode;
}

const LoginWrapper = memo(({children}: IProps) => {
    const classes = useStyles();

    return (
        <div className={classes.wrapper}>
            <div className={classes.content_wrapper} data-cy="login">
                <LoginLeftSide />
                <LoginRightSide>{children}</LoginRightSide>
            </div>
            <MediaQuery minWidth={769}>
                <Copyright />
            </MediaQuery>
        </div>
    );
});

export default LoginWrapper;
