import {memo} from "react";
import {Link as RouterLink} from "react-router-dom";
import {Typography, Link} from "@material-ui/core";
import {
    APP_COLORS,
    FONT_WEIGHT,
} from "../../../Constants/LoginMagicStyleConstants";

interface IProps {
    url: string;
}

const LoginCreateAccountLink = memo(({url}: IProps) => {
    return (
        <Typography
            color="textSecondary"
            fontSize={14}
            left={"14px"}
            fontWeight={FONT_WEIGHT.REGULAR}
            textAlign="center"
            mt={9}
            sx={{
                justifySelf: "flex-end",
            }}
        >
            Not registered yet?{" "}
            <Link
                color={APP_COLORS.ACCENT}
                component={RouterLink}
                to={url}
                fontWeight={FONT_WEIGHT.MEDIUM}
                sx={{
                    textDecoration: "underline",
                    "&:hover": {
                        textDecoration: "none",
                    },
                }}
            >
                Create new account
            </Link>
        </Typography>
    );
});

export default LoginCreateAccountLink;
