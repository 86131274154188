import {observer} from "mobx-react-lite"
import React, {FC, useEffect, useState} from "react"

import store from "../store/store";
import {SUB_TYPES} from "../Constants/Constants";

const SubTypeText: FC = observer(() => {
        const [title, setTitle] = useState<'BevNET/NOSH' | 'Brewbound'>('BevNET/NOSH');

        useEffect(() => {
            if (store && store.hostSubTypes) {
                if (store.hostSubTypes === SUB_TYPES.BREWBOUND) {
                    setTitle('Brewbound');
                    return;
                }
                setTitle('BevNET/NOSH');
                return;
            }
            setTitle('BevNET/NOSH');
        }, [store.hostSubTypes]);

        return (
            <>
                {title}
            </>)
    }
)

export default SubTypeText;
