import React, {FC} from "react";
import Box from "@material-ui/core/Box";
import { FormGroup, FormHelperText} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import useFetch from "../../../Hooks/useFetch";
import Loading from "../../../Components/Loading";
import BevnetTextField from "../../../Components/BevnetTextField/BevnetTextField";
import Address from "../../../Components/Address";
import BevnetCheckbox from "../../../Components/BevnetCheckbox";
import registrationStore from "../../../store/registrationStore";
import Pages, {PageUrl} from "../../../Constants/Pages";
import Store from "../../../store/store";
import {useHistory} from "react-router-dom";
import {observer} from "mobx-react-lite";

type RegisterStep3Form = {
    firstName: string;
    lastName: string;
    company?: string;
    jobTitle?: string;
    phone?: string;
    mobilePhone?: string;
    country?: string;
    address?: string;
    address2?: string;
    city?: string;
    state?: string;
    zip?: string;
    password?: string;
    confirmPassword?: string;
    subscribeToBevnet?: boolean;
    subscribeToBrewbound?: boolean;
    subscribeToNosh?: boolean;
};

const RegisterStep3FormValidationSchema: Yup.SchemaOf<RegisterStep3Form> = Yup.object(
    {
        firstName: Yup.string()
            .required("First Name is required"),
        lastName: Yup.string()
            .required("Last Name is required"),
        company: Yup.string(),
        jobTitle: Yup.string(),
        phone: Yup.string(),
        mobilePhone: Yup.string(),
        country: Yup.string(),
        address: Yup.string(),
        address2: Yup.string(),
        city: Yup.string(),
        state: Yup.string(),
        zip: Yup.string(),
        password: Yup.string()
            .required("Password is required"),
        confirmPassword: Yup.string()
            .required("Confirm Password is required")
            .oneOf([Yup.ref("password"), null], "Passwords must match"),
        subscribeToBevnet: Yup.boolean(),
        subscribeToBrewbound: Yup.boolean(),
        subscribeToNosh: Yup.boolean(),
    }
).defined();

const RegistrationFreeStep3: FC = observer(() => {
    const history = useHistory();
    const {
        register: registerStep,
        handleSubmit: handleSubmitStep,
        formState: { errors },
        watch,
        setValue
    } = useForm<RegisterStep3Form>({
        resolver: yupResolver(RegisterStep3FormValidationSchema),
    });
    const {isLoading, serverError, fetchNow} = useFetch(`/user/RegistrationStep3`);

    const handleChange = (event:React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.name as keyof RegisterStep3Form, event.target.checked);
    };

    async function onSubmitStep1(data: RegisterStep3Form) {
        console.log(data);
        await fetchNow({
            data: {...data, email:registrationStore.email},
            onSuccess: () => {
                history.push(PageUrl(Pages.LOGIN, Store.returnUrl));
            },
        });
    }

    return (
        <>
            <form noValidate onSubmit={handleSubmitStep(onSubmitStep1)}>
                <BevnetTextField
                    autoFocus
                    error={!!errors.firstName}
                    helperText={errors.firstName?.message}
                    labelField="First Name"
                    register={registerStep("firstName")}
                />

                <BevnetTextField
                    error={!!errors.lastName}
                    helperText={errors.lastName?.message}
                    labelField="Last Name"
                    register={registerStep("lastName")}
                />

                <BevnetTextField
                    error={!!errors.company}
                    helperText={errors.company?.message}
                    labelField="Company"
                    register={registerStep("company")}
                />

                <BevnetTextField
                    error={!!errors.jobTitle}
                    helperText={errors.jobTitle?.message}
                    labelField="Job Title"
                    register={registerStep("jobTitle")}
                />

                <BevnetTextField
                    error={!!errors.phone}
                    helperText={errors.phone?.message}
                    labelField="Phone"
                    register={registerStep("phone")}
                />

                <BevnetTextField
                    error={!!errors.mobilePhone}
                    helperText={errors.mobilePhone?.message}
                    labelField="Mobile Phone"
                    register={registerStep("mobilePhone")}
                />

                <Address
                    countryRegister={registerStep("country")}
                    countryError={errors.country}
                    stateRegister={registerStep("state")}
                    stateError={errors.state}
                    cityRegister={registerStep("city")}
                    cityError={errors.city}
                    watch={watch}
                    setValues={setValue}
                />

                <BevnetTextField
                    error={!!errors.zip}
                    helperText={errors.zip?.message}
                    labelField="ZIP/Postal"
                    register={registerStep("zip")}
                />

                <BevnetTextField
                    error={!!errors.password}
                    helperText={errors.password?.message}
                    labelField="Password"
                    type="password"
                    register={registerStep("password")}
                />

                <BevnetTextField
                    error={!!errors.confirmPassword}
                    helperText={errors.confirmPassword?.message}
                    labelField="Confirm Password"
                    type="password"
                    register={registerStep("confirmPassword")}
                />

                <FormGroup onChange={handleChange}>
                    <BevnetCheckbox register={registerStep("subscribeToBevnet")}>BevNET - beverage news</BevnetCheckbox>
                    <BevnetCheckbox register={registerStep("subscribeToNosh")}>NOSH - food news</BevnetCheckbox>
                    <BevnetCheckbox register={registerStep("subscribeToBrewbound")}>Brewbound - beer news</BevnetCheckbox>
                </FormGroup>

                {serverError && (
                    <Box sx={{mt: 3}}>
                        <FormHelperText error>{serverError}</FormHelperText>
                    </Box>
                )}
                <Box sx={{mt: 2}}>
                    <Button
                        style={{
                            borderRadius: 5
                        }}
                        color="primary"
                        //disabled={isSubmitting}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                    >
                        Register
                    </Button>
                </Box>
            </form>
            <Loading isLoading={isLoading}/>
        </>
    );
});

export default RegistrationFreeStep3;
