import {memo} from "react";
import clsx from "clsx";
import {useStyles} from "./styles";
import {HOST} from "../../../../Constants/Constants";
import LogoBevNet from "../../../../Components/icons/LogoBevNet";
import LogoBrewbound from "../../../../Components/icons/LogoBrewbound";
import LogoNOSH from "../../../../Components/icons/LogoNOSH";
import LogoTasteRadioFlat from "../../../../Components/icons/LogoTasteRadioFlat";

interface IProps {
    numberHost: number;
}

const LoginActiveLogo = memo(({numberHost = 1}: IProps) => {
    const classes = useStyles();

    const brandLogos = {
        bevnet: (
            <LogoBevNet
                key="family-of-brands-bevnet"
                className={classes.family_of_brands_logo}
            />
        ),
        brewbound: (
            <LogoBrewbound
                key="family-of-brands-brewbound"
                className={classes.family_of_brands_logo}
            />
        ),
        nosh: (
            <LogoNOSH
                key="family-of-brands-nosh"
                className={classes.family_of_brands_logo}
            />
        ),
        taste_radio: (
            <LogoTasteRadioFlat
                key="family-of-brands-taste-radio"
                className={classes.family_of_brands_logo}
            />
        ),
    };

    const content = {
        [HOST.BEVNET.valueOf()]: {
            logo: <LogoBevNet />,
            wrapperClass: classes.bevnet,
            familyOfBrands: [
                brandLogos.nosh,
                brandLogos.brewbound,
                brandLogos.taste_radio,
            ],
        },
        [HOST.BREWBOUND.valueOf()]: {
            logo: <LogoBrewbound />,
            wrapperClass: classes.brewbound,
            familyOfBrands: [
                brandLogos.bevnet,
                brandLogos.taste_radio,
                brandLogos.nosh,
            ],
        },
        [HOST.NOSH.valueOf()]: {
            logo: <LogoNOSH />,
            wrapperClass: classes.nosh,
            familyOfBrands: [
                brandLogos.bevnet,
                brandLogos.brewbound,
                brandLogos.taste_radio,
            ],
        },
        [HOST.TASTE_RADIO.valueOf()]: {
            logo: <LogoTasteRadioFlat />,
            wrapperClass: classes.taste_radio,
            familyOfBrands: [
                brandLogos.bevnet,
                brandLogos.nosh,
                brandLogos.brewbound,
            ],
        },
    };

    return (
        <div
            className={clsx(
                classes.logos_wrapper,
                content[numberHost].wrapperClass
            )}
        >
            <div className={classes.active_logo}>
                {content[numberHost].logo}
            </div>
            <div className={classes.family_of_brands}>
                {content[numberHost].familyOfBrands.map((brand) => brand)}
            </div>
        </div>
    );
});

export default LoginActiveLogo;