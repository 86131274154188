import React, {FC} from "react";
import {Typography} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import {useForm} from "react-hook-form";
import GoogleButton from "react-google-button";
import {yupResolver} from "@hookform/resolvers/yup";
import {CardContent, FormHelperText} from "@material-ui/core";
import Link from "@material-ui/core/Link";
import {Link as RouterLink} from "react-router-dom";

import {useStyles} from "./styles";
import clsx from "clsx";
import Pages, {PageUrl} from "../../../../Constants/Pages";
import {observer} from "mobx-react-lite";
import Store from "../../../../store/store";
import NombaseLeftSide from "./NombaseLeftSide";
import NombaseLoginRightSideHead from "./NombaseLoginRightSideHead";
import {ThemeProvider, useTheme} from "../../../../theme/nombase";
import NombaseHelmet from "../../../../Components/Nombase/NombaseHelmet";
import {
     LoginFormStep2, loginFormStep2ValidationSchema, LoginWrapperStep2Props
} from "../../index";
import NombaseTextField from "../../../../Components/Nombase/NombaseTextField/NombaseTextField";
import {APP_COLORS, FONT_WEIGHT} from "../../../../Constants/LoginMagicStyleConstants";

const NombaseStep2: FC<LoginWrapperStep2Props> = observer(({onSubmit, CaptchaComponent, serverError, onMagic}: LoginWrapperStep2Props) => {
    const classes = useStyles();
    const {theme, isLightMode} = useTheme();
    

    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm<LoginFormStep2>({
        resolver: yupResolver(loginFormStep2ValidationSchema),
    });

    return (
        <ThemeProvider value={{theme, isLightMode}}>
            <NombaseHelmet />
            <div
                className={clsx(
                    classes.gridWrapper,
                    isLightMode ? "light" : undefined
                )}
            >
                <div className={classes.wrapper} data-cy="login">
                    <NombaseLeftSide />
                    <CardContent className={classes.right_side}>
                        <div className={classes.right_inner_container}>
                            <NombaseLoginRightSideHead />
                            <form noValidate onSubmit={handleSubmit(onSubmit)}>
                                <CaptchaComponent></CaptchaComponent>
                                <NombaseTextField
                                    type="password"
                                    labelField="Password"
                                    placeholder="Enter your password"
                                    helperText={errors.password?.message}
                                    register={register("password")}
                                    error={!!errors.password}
                                    autoFocus
                                />
                                <Typography
                                    color="textSecondary"
                                    fontSize={14}
                                    left={"14px"}
                                    fontWeight={FONT_WEIGHT.REGULAR}
                                    textAlign="right"
                                    sx={{
                                        justifySelf: "flex-end",
                                    }}
                                >
                                    <Link
                                        color={'#15A6FF'}
                                        sx={{fontSize: 13, fontWeight: 400}}
                                        component={RouterLink}
                                        to={PageUrl(Pages.FORGOT, Store.returnUrl)}
                                        variant="body2"
                                    >
                                        Forgot password?
                                    </Link>
                                </Typography>
                                {serverError && (
                                    <Box sx={{mt: 3}}>
                                        <FormHelperText error>
                                            {serverError}
                                        </FormHelperText>
                                    </Box>
                                )}
                                <Box sx={{mt: 2}}>
                                    <Button
                                        className={classes.btn}
                                        color="primary"
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                    >
                                        Log In
                                    </Button>
                                </Box>
                                
                                <div className={classes.divided_block}>
                                    <Typography
                                        variant="body2"
                                        color="#DEDEDE"
                                        fontSize={16}
                                        fontWeight={400}
                                    >
                                        or
                                    </Typography>
                                </div>

                                <Box sx={{mt: 2, mb: 2}}>
                                    <Button
                                        className={classes.btn}
                                        color="primary"
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        onClick={onMagic}
                                    >
                                        Sign in with Magic Link
                                    </Button>
                                </Box>

                                <GoogleButton
                                    className={classes.google_btn}
                                    label={"Continue with Google"}
                                    type={"light"}
                                    style={{width: "100%"}}
                                    onClick={() => {
                                        window.location.href =
                                            "/external/LoginGoogle?returnUrl=" +
                                            Store.returnUrl +
                                            "&remember=true";
                                    }}
                                />
                            </form>
                            <div className={classes.forgot_line}>
                                <Typography
                                    variant="body2"
                                    color="#182A4B"
                                    fontSize={12}
                                    fontWeight={400}
                                >
                                    Not registered yet?
                                </Typography>
                                <Link
                                    variant="body2"
                                    color="#15A6FF"
                                    component={RouterLink}
                                    to={PageUrl(
                                        Pages.REGISTER,
                                        Store.returnUrl
                                    )}
                                    fontWeight={500}
                                >
                                    Create new account
                                </Link>
                            </div>
                        </div>
                    </CardContent>
                </div>
            </div>
        </ThemeProvider>
    );
});

export default NombaseStep2;
