import {makeStyles} from '@material-ui/core';
import {mixin} from "../../utils/mixin";

export const useStyles = makeStyles((theme) => ({
    wrapper: {
        width: '100%',
        maxWidth: 778,
        margin: '80px auto 0',
        border: '1px solid #BFBFBF',
        position: 'relative',
        borderRadius: 21,
        '& $right_side': {
            padding: '41px 0px 32px',
            '@media(max-width: 768px)': {
                padding: '38px 30px 10px',
                borderRadius: 0
            }
        },
        '@media(max-width: 768px)': {
            display: 'block',
            marginTop: 0,
            border: 0
        }
    },
    right_side: {
        width: '100%',
        maxWidth: '100%',
        border: '1px solid #BFBFBF',
        background: '#f8f8f8',
        borderRadius: theme.shape.borderRadius,
        '@media(max-width: 768px)': {
            maxWidth: '100%',
            borderRadius: '30px 30px 0px 0px',
            boxShadow: '2px -2px 8px rgba(0, 0, 0, 0.1)'
        },
        '& $label_field': {
            fontSize: 13,
            marginBottom: '-2px !important'
        }
    },
    right_inner_container: {
        marginLeft: 64,
        marginRight: 64,
        paddingBottom: 7,
        '@media(max-width: 768px)': {
            maxWidth: '100%',
            marginLeft: 0,
            marginRight: 0,
        }
    },
    btn: {
        borderRadius: '5px !important',
        backgroundColor: '#5862CE !important'
    },
    btn_outline: {
        borderRadius: '5px !important',
        border: '1px solid #5862CE !important',
        backgroundColor: '#F8F8F8 !important',
        color: '#5862CE !important'
    },
    item_logo: {
        ...mixin.flex('center', 'center'),
        padding: 15,
        height: 125,
        width: '100%',
        '& svg': {
            height: 'fit-content',
            maxHeight: 31,
            '@media(min-width: 768px)': {
                '&.taste_radio_svg': {
                    maxHeight: 55,
                }
            }

        },
        '@media(max-width: 768px)': {
            height: 'auto',
            padding: 15,
            '& svg': {
                maxHeight: 24,
                '&.taste_radio_svg': {
                    maxHeight: 36,
                }
            },
        },
        '&$active': {
            height: 138,
            margin: '13px 0',
            '& svg': {
                maxHeight: 55,
                '&.taste_radio_svg': {
                    maxHeight: 89,
                    '& .cls-4': {
                        fill: 'rgb(241, 89, 39)'
                    },
                    '@media(max-width: 768px)': {
                        maxHeight: 67,
                    }
                }
            },
            '@media(max-width: 768px)': {
                height: 104,
                padding: 15,
                margin: 0,
                '& svg': {
                    maxHeight: 45,
                },
            },
        },
        '&$isCancelTopOffset':{
            marginTop: 0
        }
    },
    active: {},
    isCancelTopOffset: {},
    header_logo: {
        textAlign: 'center',
        width: '100%',
        '& p': {
            marginTop: 33
        },
        '@media(max-width: 768px)': {
            maxWidth: '100%',
        }
    },
    wrapper_for_logos: {
        paddingTop: 24,
        position: 'relative',
        flexWrap: 'wrap',
        borderTopRightRadius: '15%',
        ...mixin.flex('center', 'center'),
        '@media(max-width: 768px)': {
            paddingTop: 0,
            paddingBottom: 25,
        },
    },
    extra_settings_header: {
        borderRadius: '20px 20px 0px 0px',
        position: 'relative',
        overflow: 'hidden',
        '@media(max-width: 768px)': {
            borderRadius: '0px 0px 0px 0px'
        },
        '& [data-id="dot"]': {
            margin: '0 33px',
            '@media(max-width: 768px)': {
                margin: '0 17px',
            },
        },
    },
    buttons_wrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gridGap: 15,
        marginTop: 7,
        '@media(max-width: 768px)': {
            display: 'flow-root'
        },
    },
    buttons_flex: {
        flex: 1
    },
    mobile_copyright: {
        padding: '18px 0px 0px'
    }
}))
