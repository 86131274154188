import NombaseBevnetLogo from "./NombaseBevnetLogo";
import NombaseNoshLogo from "./NombaseNoshLogo";
import NombaseBrewboundLogo from "./NombaseBrewboundLogo";
import NombaseTasteRadioLogo from "./NombaseTasteRadioLogo";
import {useStyles} from "./styles";
import clsx from "clsx";
import {useTheme} from "../../../theme/nombase";

const NombaseLogos = () => {
    const classes = useStyles();
    const {isLightMode} = useTheme();

    return (
        <div
            className={clsx(
                classes.nombase_logos_wrapper,
                isLightMode ? "light" : undefined
            )}
        >
            <NombaseBevnetLogo />
            <NombaseNoshLogo />
            <NombaseBrewboundLogo />
            <NombaseTasteRadioLogo />
        </div>
    );
};

export default NombaseLogos;
