import React, { useEffect } from "react";
import {useCookies} from "react-cookie";

const Iframe = () => {
    const [cookies] = useCookies(['bevnet_auth_token']);
    useEffect(() => {
        window.parent.postMessage({
            'func': cookies.bevnet_auth_token !== undefined ? "loginFromIframe" : "logoutFromIframe",
            'token': localStorage.getItem('test_local')
        }, "*");
    });
    return (
        <></>
    )
}

export default Iframe