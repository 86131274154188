import {makeStyles} from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    nombase_form_helper_text_wrapper: {
        marginTop: "16px",
    },
    nombase_form_helper_text: {
        "&.MuiFormHelperText-root": {
            fontFamily: "adelle-sans",
            "&.Mui-error": {
                color: "#CE3A3A",
            },
        },
    },
}));
