import {makeStyles} from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    register_box: {
        padding: "5.3% 6.64%",
        minHeight: 350,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        borderRadius: 15,
        border: "2px solid rgba(21, 166, 255, 0.70)",
        color: "#091F2B",
        backgroundColor: "#fff",
        "&.light": {
            border: "1px solid rgba(21, 166, 255, 0.50)",
        },
    },
    register_box_shrink: {
        minHeight: "auto",
    },
    register_title: {
        "&.MuiTypography-root": {
            fontFamily: "adelle-sans",
            fontSize: 25,
            fontWeight: 600,
            lineHeight: "normal",
            color: "#020608",
        },
    },
    register_description: {
        "&.MuiTypography-root": {
            fontFamily: "adelle-sans",
            fontSize: 16,
            fontWeight: 400,
            lineHeight: "normal",
            color: "#091F2B",
        },
    },
    register_transition: {
        transition: "opacity 0.5s ease-in-out",
        opacity: 0,
        "&.fade-enter": {
            opacity: 1,
        },
    },
}));
