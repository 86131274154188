import {makeStyles} from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    registerSubmitButton: {
        minWidth: "200px !important",
        borderRadius: "5px !important",
        backgroundColor: "#231F20 !important",
        "&:hover": {
            backgroundColor: "#6b778c !important",
        },
    },
    registerSubmitLabel: {
        fontSize: "16px !important",
        mb: "0.5",
    },
    registerSubmitForm: {
        display: "grid",
        columnGap: "20px",
        gridTemplateColumns: "1fr",
        "@media (min-width: 600px)": {
            gridTemplateColumns: "1fr 1fr",
        },
    },
}));
