import {makeStyles} from '@material-ui/core';
import { mixin } from '../../../../utils/mixin';

const wrapperBorderRadius = 20;

export const useStyles = makeStyles(() => ({
    gridWrapper: {
        display: 'grid',
        height: '100vh',
        placeItems: 'center',
        backgroundColor: '#020608',
        '&.light': {
            backgroundColor: '#fff'
        }
    },
    wrapper: {
        display: 'flex',
        width: '100%',
        maxWidth: 930,
        fontFamily: 'adelle-sans',
        border: '2px solid rgba(21, 166, 255, 0.70)',
        borderRadius: wrapperBorderRadius,
        '& $right_side': {
            padding: '40px 15px 24px',
            '@media(max-width: 768px)': {
                padding: '32px 20px 16px',
                borderTopLeftRadius: 19,
                borderBottomLeftRadius: 19,
            }
        },
        '@media(max-width: 768px)': {
            maxWidth: '90%',
            margin: '10px 0',
        }
    },
    left_side: {
        flexBasis: '44.6%',
        '@media(max-width: 768px)': {
            display: 'none',
        }
    },
    logo_img: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        borderTopLeftRadius: 19,
        borderBottomLeftRadius: 19
    },
    left_side_head: {
        marginBottom: 28,
        width: '100%',
        maxWidth: 273,
        '@media(max-width: 768px)': {
            maxWidth: '100%',
        },
        '& *': {
            color: '#182A4B'
        },
    },
    login_side_head: {
        textAlign: 'center'
    },
    title_description: {
        '&.MuiTypography-root': {
            fontFamily: 'adelle-sans',
            fontSize: 20,
            fontWeight: 600,
            lineHeight: 'normal',
            color: '#020608'
        }
    },
    subtitle_description: {
        '&.MuiTypography-root': {
            fontFamily: 'adelle-sans',
            fontSize: 14,
            fontWeight: 400,
            lineHeight: 'normal',
            color: '#091F2B'
        }
    },
    right_side: {
        flexGrow: 1,
        backgroundColor: '#fff',
        borderTopRightRadius: 19,
        borderBottomRightRadius: 19,
        '& $remember': {
            fontSize: 13,
        },
    },
    forgot_password_text: {
        '&.MuiTypography-root': {
            fontFamily: 'adelle-sans',
            fontSize: 13,
            fontWeight: 400,
            fontStyle: 'normal',
            color: '#15A6FF',
            transition: 'color 250ms cubic-bezier(0.4, 0, 0.2, 1)',
            '&:hover': {
                color: '#1594E1'
            }
        }
    },
    rememberRoot: {
        padding: 0,
        '& .MuiIconButton-label': {
            borderRadius: 3,
            color: '#DEDEDE'
        },
        '& .Mui-checked': {
            color: 'transparent',
            '& .MuiIconButton-label': {
                color: '#15A6FF'
            }
        },
        '&:hover': {
            backgroundColor: 'transparent'
        },
        '&:hover .MuiIconButton-root': {
            backgroundColor: 'transparent'
        },
        '&:active .MuiIconButton-root': {
            backgroundColor: 'transparent'
        },
    },
    rememberLabel: {
        '&.MuiFormControlLabel-label': {
            fontFamily: 'adelle-sans',
            fontSize: 13,
            fontWeight: 400,
            lineHeight: 'normal',
            color: '#666',
        }
    },
    right_side_mail_title: {
        marginBottom: '32px !important',
        '@media(min-width: 768px)': {
            textAlign: 'center'
        }
    },
    right_inner_container: {
        width: '100%',
        maxWidth: 351,
        margin: '0 auto',
        '@media(max-width: 768px)': {
            maxWidth: '100%',
        }
    },
    btn: {
        '&.MuiButton-root': {
            fontFamily: 'adelle-sans',
            fontSize: 14,
            fontWeight: 500,
            textTransform: 'initial',
            padding: '11px 16px',
            borderRadius: 10,
            boxShadow: 'none',
            color: '#fff',
            backgroundColor: '#020608',
            '&:hover': {
                backgroundColor: '#17272F',
                boxShadow: 'none'
            }
        }
    },
    google_btn: {
        height: '45px !important',
        boxShadow: 'none !important',
        ...mixin.flex('center', 'center'),
        display: 'flex !important',
        borderRadius: '10px !important',
        padding: '11px 16px',
        border: '1px solid #DEDEDE !important',
        background: '#FFF',
        '& div': {
            width: '40px !important',
            height: '40px !important'
        },
        '& svg': {
            width: '40px !important',
            height: '40px !important'
        },
        '& span': {
            fontSize: 14,
            fontWeight: 500,
            color: '#3E3E3E'
        }
    },
    form_line: {
        ...mixin.flex('center', 'space-between'),
        margin: '-8px 0 17px',
        '@media(max-width: 768px)': {
            margin: '0 0 25px'
        },
        '& a': {
            marginLeft: 'auto',
            color: '#15A6FF',
            transition: 'color 250ms cubic-bezier(0.4, 0, 0.2, 1)',
            '&:hover': {
                color: '#1594E1'
            }
        }
    },
    divided_block: {
        display: 'flex',
        margin: '32px 0',
        alignItems: 'center',
        justifyContent: 'center',
        
        '&:before, &:after': {
            content: '""',
            display: 'block',
            height: 1,
            width: '50%',
            background: '#DEDEDE'
            
        },
        '&:before': {
            marginRight: 10
        },
        '&:after': {
            marginLeft: 10
        },
        '& p': {
            display: 'block',
            fontFamily: 'adelle-sans',
            fontSize: 14,
            fontWeight: 400,
            lineHeight: 'normal',
            margin: '0 6px',
            color: '#707070',
        }
    },
    forgot_line: {
        display: 'flex',
        margin: '40px 0 0',
        alignItems: 'center',
        justifyContent: 'center',
        '& a': {
            fontFamily: 'adelle-sans',
            fontSize: 14,
            fontWeight: 500,
            textDecoration: 'underline',
            marginLeft: 5,
            color: '#15A6FF',
            transition: 'color 250ms cubic-bezier(0.4, 0, 0.2, 1)',
            '&:hover': {
                color: '#1594E1'
            }
        },
        '& p': {
            fontFamily: 'adelle-sans',
            fontSize: 14,
            fontWeight: 400,
            color: '#707070',
        }
    },
    cl: {
        flex: 1
    },
    wrapper_for_logos: {
        paddingTop: 24,
        position: 'relative',
        ...mixin.flex('center', 'center'),
        '@media(min-width: 768px)': {
            flexDirection: 'column',
        },
        '@media(max-width: 768px)': {
            flexWrap: 'wrap',
            paddingTop: 0,
            paddingBottom: 25
        },
    },
    active: {},
    isCancelTopOffset: {},
    item_logo: {
        ...mixin.flex('center', 'center'),
        padding: 15,
        height: 125,
        width: '100%',
        '& svg': {
            height: 'fit-content',
            maxHeight: 31,
            '@media(min-width: 768px)': {
                '&.taste_radio_svg': {
                    maxHeight: 55,
                }
            }

        },
        '@media(max-width: 768px)': {
            height: 'auto',
            padding: 15,
            '& svg': {
                maxHeight: 24,
                '&.taste_radio_svg': {
                    maxHeight: 36,
                }
            },
        },
        '&$active': {
            height: 138,
            margin: '13px 0',
            '& svg': {
                maxHeight: 55,
                '&.taste_radio_svg': {
                    maxHeight: 89,
                    '& .cls-4': {
                        fill: 'rgb(241, 89, 39)'
                    },
                    '@media(max-width: 768px)': {
                        maxHeight: 67,
                    }
                }
            },
            '@media(max-width: 768px)': {
                height: 104,
                padding: 15,
                margin: 0,
                '& svg': {
                    maxHeight: 45,
                },
            },
        },
        '&$isCancelTopOffset':{
            marginTop: 0
        }
    },
    line: {
        '& svg': {
            maxHeight: 24,
            '&.taste_radio_svg': {
                maxHeight: 36,
            }
        },
        '& .bevnet_logo': {
            fill: '#ce0e2d'
        }
    },
}))
