import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";

interface ICopyrightProps {
    extraStyle?: string;
}

const useStyles = makeStyles(() => ({
    copyright_wrapper: {
        padding: '32px 0 40px',
    }
}));

const Copyright = ({ extraStyle }:ICopyrightProps) => {
    const classes = useStyles();
    return (
        <div className={clsx(classes.copyright_wrapper, extraStyle)}>
            <Typography
                variant="body2"
                color="textSecondary"
                align="center"
            >
                {"Copyright © "}
                <Link color="inherit" href="https://www.bevnet.com/">
                    BevNET
                </Link>{" "}
                1996 - {new Date().getFullYear()}
                {"."}
            </Typography>
        </div>

    );
};

export default Copyright;
