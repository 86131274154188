import {makeStyles} from '@material-ui/core';
import {mixin} from "../../utils/mixin";
//import { mixin } from '../../utils/mixin';

// '@media(max-width: 768px)': {}
export const useStyles = makeStyles(() => ({
    root: {},
    line: {
        '& svg': {
            maxHeight: 24,
            height: 'fit-content',
            '&.taste_radio_svg': {
                maxHeight: 36,
            }
        },
        '& .bevnet_logo': {
            fill: '#ce0e2d'
        },
        '& .tasteradio_logo': {
            fill: '#F15927'
        }
    },
    wrapper_for_logos_mobile: {
        '& $line': {
            padding: '22px 30px 30px',
            position: 'relative',
            ...mixin.flex('center', 'center'),
            
        }
    },
    dot: {
        width: 6,
        height: 6,
        minWidth: 6,
        margin: '0 12px',
        background: '#000',
        borderRadius: '50%',
        '& + svg': {
            margin: '0 -1px'
        }
    }
}))
