import {makeStyles} from "@material-ui/core";

export const useStyles = makeStyles(() => ({
    header: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "30px 0",
        "& svg": {
            height: "45px",
            "& path": {
                fill: "#FFF",
            },
        },
        "@media(max-width: 600px)": {
            padding: "20px 0",
            "& svg": {
                height: "auto",
                width: "200px",
            },
        },
    },
    headerBevNet: {
        backgroundColor: "#ce0e2d",
    },
    headerBrewbound: {
        backgroundColor: "#000",
    },
    headerNosh: {
        backgroundColor: "#231f20",
        "& svg": {
            "& path": {
                fill: "#FBC549",
            },
        },
    },
    headerTasteRadio: {
        backgroundColor: "#F15927",
    },
    headerNombase: {},
    wrapper: {
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        padding: "2rem 0 3.5rem",
        "& svg": {
            height: "1.7rem",
        },
        "@media(max-width: 600px)": {
            padding: "2rem 0",
            "& svg": {
                height: "1.25rem",
            },
        },
    },
    logoBevnet: {
        "& path": {
            fill: "#ce0e2d",
        },
    },
    logoNosh: {
        "& path": {
            fill: "#FBC549",
        },
    },
}));
