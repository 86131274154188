import * as React from "react"
import {SVGProps} from "react";

const LogoTasteRadio = (props: SVGProps<SVGSVGElement>) => {
    
    return (
        <svg viewBox="0 0 364 55" xmlns="http://www.w3.org/2000/svg" className="tasteradio_logo" {...props}>
            <g clipPath="url(#clip0_501_497)" className="">
                <path d="M358.941 45.4612C361.935 45.4612 363.822 47.5604 363.822 50.2306C363.835 51.1881 363.564 52.1279 363.043 52.931C362.521 53.7342 361.773 54.3645 360.893 54.7422C360.014 55.1198 359.041 55.2278 358.1 55.0524C357.159 54.8771 356.291 54.4262 355.606 53.7571C354.921 53.0879 354.45 52.2305 354.253 51.2935C354.056 50.3565 354.141 49.3821 354.498 48.4936C354.855 47.6052 355.468 46.8428 356.259 46.3029C357.05 45.7631 357.983 45.4701 358.941 45.4612ZM359.008 53.8245C361.247 53.8245 362.557 52.3019 362.557 50.2306C362.557 48.1594 361.219 46.6591 358.963 46.6591C356.797 46.6591 355.464 48.1594 355.464 50.253C355.433 50.7277 355.504 51.2034 355.67 51.6489C355.837 52.0943 356.097 52.4993 356.432 52.8369C356.767 53.1745 357.169 53.4372 357.614 53.6074C358.058 53.7777 358.533 53.8517 359.008 53.8245ZM358.526 50.9696V52.6938H357.166V47.7172H359.192C359.417 47.7012 359.643 47.7307 359.856 47.8037C360.069 47.8768 360.265 47.9919 360.433 48.1423C360.6 48.2927 360.736 48.4754 360.832 48.6793C360.927 48.8832 360.981 49.1043 360.989 49.3294C361 49.6068 360.932 49.8816 360.793 50.1222C360.655 50.3628 360.451 50.5595 360.206 50.6897L361.129 52.6938H359.724L359.008 50.9696H358.526ZM358.526 48.8703V49.8612H359.086C359.153 49.8627 359.219 49.8507 359.281 49.826C359.343 49.8012 359.399 49.7641 359.446 49.717C359.493 49.6699 359.53 49.6138 359.555 49.552C359.579 49.4901 359.591 49.4239 359.59 49.3574C359.592 49.2915 359.58 49.226 359.555 49.1651C359.53 49.1041 359.492 49.049 359.445 49.0032C359.398 48.9574 359.341 48.922 359.279 48.8991C359.218 48.8762 359.152 48.8664 359.086 48.8703H358.526Z" />
                <path d="M3.96804 8.91748H19.5584V16.7154H25.3691V28.3704H19.5584V39.3928C19.5584 41.3577 21.4561 41.8503 23.0683 41.8503C23.9162 41.8503 24.7626 41.7791 25.5986 41.6376V53.9531C23.5826 54.4395 21.5145 54.6764 19.4408 54.6584C9.23573 54.6305 3.537 49.8554 3.537 39.8126V28.3704H0.178223V16.7154H3.96804V8.91748Z" />
                <path d="M42.3084 31.1807C44.5238 31.1973 46.7159 31.6342 48.7685 32.4682V31.1807C48.7685 29.5629 47.0107 28.5105 43.9206 28.5105C40.7 28.5958 37.5374 29.387 34.656 30.828L29.4555 20.5726C34.6554 17.3922 40.6354 15.716 46.7308 15.7303C60.1659 15.7303 64.426 23.1756 64.426 31.9644V54.0708H50.7333L50.0336 51.6133C47.856 53.7181 44.7659 54.972 40.9761 54.972C33.8835 54.972 27.8433 51.3166 27.8433 42.8245C27.8433 35.2672 34.3257 31.1807 42.3084 31.1807ZM46.3781 44.056C47.9288 44.056 49.401 43.2107 49.401 41.7385C49.401 40.2662 47.9959 39.4209 46.3109 39.4209C44.626 39.4209 43.2209 40.3054 43.2209 41.7105C43.2209 43.1156 44.6931 44.028 46.3781 44.028V44.056Z" />
                <path d="M73.3436 39.5328C75.6611 41.2122 80.2962 43.1155 82.6138 43.1155C83.7334 43.1155 84.1588 42.6956 84.1588 42.3429C84.1588 41.6376 83.4535 41.4305 81.7685 40.9379C76.1705 39.1801 68.0759 36.7226 68.0759 28.0905C68.0759 20.6452 75.0285 15.7302 84.0861 15.7302C91.5985 15.7302 96.2336 18.8875 99.0438 21.6305L91.4586 30.1954C89.7064 28.6503 86.9802 27.3964 84.9313 27.3964C83.8118 27.3964 83.3807 27.889 83.3807 28.3089C83.3807 29.1541 84.366 29.4284 86.9634 30.2066C91.9512 31.6788 99.6036 34.1251 99.6036 42.1414C99.6036 50.572 92.7909 55.0167 83.2408 55.0167C77.2145 54.9351 71.424 52.6627 66.9507 48.6239L73.3436 39.5328Z" />
                <path d="M105.711 8.91748H121.301V16.7154H127.123V28.3704H121.301V39.3928C121.301 41.3577 123.193 41.8503 124.811 41.8503C125.657 41.8505 126.502 41.7793 127.336 41.6376V53.9531C125.32 54.4401 123.252 54.677 121.178 54.6584C111.001 54.6584 105.308 49.8834 105.308 39.8406V28.3704H101.949V16.7154H105.745L105.711 8.91748Z" />
                <path d="M145.524 39.0401C146.084 41.3577 148.468 42.9587 151.418 42.9587C152.609 42.9811 153.792 42.757 154.892 42.3004C155.992 41.8437 156.986 41.1645 157.811 40.3053L168.627 46.2055C166.947 48.3831 161.601 54.9832 150.506 54.9832C137.239 54.9832 129.654 45.7857 129.654 34.8977C129.654 24.2951 137.726 15.7302 149.526 15.7302C162.234 15.7302 169.539 23.6625 169.539 35.0376C169.555 36.375 169.484 37.7119 169.326 39.0401H145.524ZM153.736 31.0351C153.707 30.0392 153.284 29.0956 152.559 28.4114C151.835 27.7271 150.869 27.3581 149.873 27.3852C147.769 27.3852 146.296 29.003 146.084 31.0351H153.736Z" />
                <path d="M208.49 30.2625C207.597 29.9212 206.647 29.754 205.691 29.7699C202.248 29.7699 199.583 32.3002 199.583 36.6554V54.0707H183.573V16.7154H199.248V20.7852C200.294 19.2862 201.678 18.0542 203.289 17.1887C204.899 16.3233 206.69 15.8486 208.518 15.803L208.49 30.2625Z"/>
                <path d="M224.142 31.1807C226.357 31.1967 228.549 31.6336 230.602 32.4683V31.1807C230.602 29.5629 228.85 28.5105 225.759 28.5105C222.537 28.5958 219.373 29.3869 216.489 30.8281L211.294 20.5726C216.496 17.3904 222.478 15.7141 228.575 15.7303C242.01 15.7303 246.27 23.1756 246.27 31.9644V54.0708H232.572L231.867 51.6133C229.695 53.7181 226.605 54.9721 222.809 54.9721C215.717 54.9721 209.682 51.3166 209.682 42.8245C209.682 35.2672 216.137 31.1807 224.142 31.1807ZM228.217 44.056C229.762 44.056 231.234 43.2107 231.234 41.7385C231.234 40.2662 229.835 39.4209 228.144 39.4209C226.454 39.4209 225.054 40.3334 225.054 41.7385C225.054 43.1436 226.532 44.0281 228.217 44.0281V44.056Z" />
                <path d="M290.64 54.0708H275.329V51.8932C273.364 53.5726 270.062 54.9833 266.132 54.9833C254.886 54.9833 248.728 46.8383 248.728 35.3233C248.728 23.8083 254.886 15.8032 266.143 15.8032C269.586 15.8032 273.096 17.2754 274.641 18.6021V3.35889H290.651L290.64 54.0708ZM269.854 28.3706C266.692 28.3706 264.727 31.3207 264.727 35.3233C264.727 39.6729 266.692 42.2703 269.854 42.2703C273.297 42.2703 275.049 39.1803 275.049 35.3233C275.038 30.968 273.012 28.3706 269.854 28.3706Z" />
                <path d="M293.444 7.93232C293.444 3.45394 296.803 0 302.149 0C307.495 0 310.865 3.44275 310.865 7.93232C310.865 12.4219 307.506 15.8031 302.155 15.8031C296.803 15.8031 293.444 12.3603 293.444 7.93232ZM294.144 18.5684H310.154V54.0707H294.144V18.5684Z"/>
                <path d="M333.962 54.9832C321.389 54.9832 312.824 46.5526 312.824 35.3231C312.824 24.0936 321.389 15.7302 333.962 15.7302C346.535 15.7302 355.089 24.0712 355.089 35.3231C355.089 46.575 346.53 54.9832 333.962 54.9832ZM333.962 42.3429C337.12 42.3429 338.945 39.46 338.945 35.3231C338.945 31.1862 337.12 28.3704 333.962 28.3704C330.805 28.3704 328.975 31.1694 328.975 35.3231C328.975 39.4768 330.8 42.3429 333.962 42.3429Z" />
            </g>
        </svg>

    )
}


export default LogoTasteRadio;
