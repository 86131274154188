import React, {FC, useEffect, useState} from "react";
import Box from "@material-ui/core/Box";
import {Alert, FormHelperText, Link, Typography} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import registrationSubmitStore from "../../../store/registrationSubmitStore";
import {RegistrationSubmitStep} from "../../../Constants/RegistrationSubmitStep";
import Loading from "../../../Components/Loading";
import useFetch from "../../../Hooks/useFetch";
import Store from "../../../store/store";
import BevnetTextField from "../../../Components/BevnetTextField/BevnetTextField";
import {useStyles} from "../styles";

type RegisterStep2Form = {
    code: string;
};

const RegisterStep2FormValidationSchema: Yup.SchemaOf<RegisterStep2Form> = Yup.object(
    {
        code: Yup.string()
            .required("Code is incorrect"),
    }
).defined();

const RegistrationSubmitStep2: FC = () => {
    const classes = useStyles();
    const [isResendSuccessShow, setIsResendSuccessShow] = useState(false);
    const {register, handleSubmit, formState: {errors}} = useForm<RegisterStep2Form>({
        resolver: yupResolver(RegisterStep2FormValidationSchema),
    });
    const {isLoading, serverError, fetchNow} = useFetch(`/user/RegistrationStep2`);
    const {serverError: serverErrorResend, fetchNow: fetchNowResend} = useFetch(
        `/user/RegistrationStep1`
    );

    useEffect(() => {
        if (isResendSuccessShow) {
            const timeout = setTimeout(() => {
                setIsResendSuccessShow(false);
            }, 5000);

            return () => clearTimeout(timeout);
        }
    }, [isResendSuccessShow])

    async function onResendCode() {
        await fetchNowResend({
            data: {
                email: registrationSubmitStore.email,
                returnUrl: Store.returnUrl,
                subType: Store.hostSubTypes
            },
            onSuccess: () => {
                setIsResendSuccessShow(true);
            },
        });
    }

    async function onSubmitStep(data: RegisterStep2Form) {
        await fetchNow({
            data: {...data, email: registrationSubmitStore.email},
            onSuccess: () => {
                registrationSubmitStore.setCurrentStep(RegistrationSubmitStep.Step3);
            },
        });
    }

    return (
        <>
            <form noValidate onSubmit={handleSubmit(onSubmitStep)}>
                <BevnetTextField
                    autoFocus
                    error={!!errors.code}
                    helperText={errors.code?.message}
                    labelField="Code"
                    register={register("code")}
                    placeholder="Enter your code"
                    classNameForLabel={classes.registerSubmitLabel}
                />
                {serverError && (
                    <Box sx={{mt: 3}}>
                        <FormHelperText error>{serverError}</FormHelperText>
                    </Box>
                )}
                <Box sx={{my: 2}}>
                    <Button
                        className={classes.registerSubmitButton}
                        disabled={isLoading}
                        size="large"
                        type="submit"
                        variant="contained"
                    >
                        Verify
                    </Button>
                </Box>
                <Box>
                    <Typography variant="body1">
                        Didn't receive a code?
                    </Typography>
                    <Link
                        component="button"
                        variant="button"
                        onClick={(e: React.SyntheticEvent) => {
                            e.preventDefault();
                            onResendCode();
                        }}
                    >
                        Resend a new code
                    </Link>
                    {isResendSuccessShow && (
                        <Alert
                            icon={false}
                            variant="outlined"
                            severity="success"
                            sx={{my: 2}}
                        >
                            Code was successfully sent.
                        </Alert>
                    )}
                    {serverErrorResend && (
                        <FormHelperText error>
                            {serverErrorResend}
                        </FormHelperText>
                    )}
                </Box>
            </form>
            <Loading isLoading={isLoading} />
        </>
    );
};

export default RegistrationSubmitStep2;
