import {FC} from "react";
import {Typography} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import {CardContent} from "@material-ui/core";
import Link from "@material-ui/core/Link";
import {Link as RouterLink} from "react-router-dom";

import {useStyles} from "./styles";
import clsx from "clsx";
import Pages, {PageUrl} from "../../../../Constants/Pages";
import {observer} from "mobx-react-lite";
import Store from "../../../../store/store";
import NombaseLeftSide from "./NombaseLeftSide";
import {ThemeProvider, useTheme} from "../../../../theme/nombase";
import NombaseHelmet from "../../../../Components/Nombase/NombaseHelmet";
import {LoginWrapperStep3Props} from "../../index";
import NombaseLoginLogo from "./NombaseLoginLogo";

const NombaseStep3: FC<LoginWrapperStep3Props> = observer(({email, onBack}: LoginWrapperStep3Props) => {
    const classes = useStyles();
    const {theme, isLightMode} = useTheme();
    

  

    return (
        <ThemeProvider value={{theme, isLightMode}}>
            <NombaseHelmet />
            <div
                className={clsx(
                    classes.gridWrapper,
                    isLightMode ? "light" : undefined
                )}
            >
                <div className={classes.wrapper} data-cy="login">
                    <NombaseLeftSide />
                    <CardContent className={classes.right_side}>
                        <div className={classes.right_inner_container}>
                            <div className={classes.login_side_head}>
                                <Box sx={{mb: 4}}>
                                    <NombaseLoginLogo />
                                </Box>
                                <Box sx={{mb: 1}}>
                                    <Typography
                                        className={classes.title_description}
                                        variant="h6"
                                        color="#020608"
                                        fontSize={20}
                                        fontWeight={500}
                                    >
                                        Check your email
                                    </Typography>
                                </Box>
                                <Box sx={{mb: 3}}>
                                    <Typography
                                        className={classes.subtitle_description}
                                        variant="body1"
                                        color="#000"
                                        fontSize={14}
                                        fontWeight={500}
                                    >
                                        We've sent a magic login link to <strong>{email}</strong>
                                    </Typography>
                                </Box>
                            </div>
                                
                            <Box sx={{mt: 2}}>
                                <Button
                                    className={classes.btn}
                                    color="primary"
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    onClick={onBack}
                                >
                                    Back
                                </Button>
                            </Box>
                                
                            

                    
                            <div className={classes.forgot_line}>
                                <Typography
                                    variant="body2"
                                    color="#182A4B"
                                    fontSize={12}
                                    fontWeight={400}
                                >
                                    Not registered yet?
                                </Typography>
                                <Link
                                    variant="body2"
                                    color="#15A6FF"
                                    component={RouterLink}
                                    to={PageUrl(
                                        Pages.REGISTER,
                                        Store.returnUrl
                                    )}
                                    fontWeight={500}
                                >
                                    Create new account
                                </Link>
                            </div>
                        </div>
                    </CardContent>
                </div>
            </div>
        </ThemeProvider>
    );
});

export default NombaseStep3;
