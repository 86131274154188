import {FC} from "react";
import {useHistory} from "react-router-dom";
import {Typography} from "@material-ui/core";
import {observer} from "mobx-react-lite";
import {useStyles} from "./styles";
import Pages, {PageUrl} from "../../../../Constants/Pages";
import Store from "../../../../store/store";
import RegistrationStore from "../../../../store/registrationStore";
import NombaseRegisterButtons from "../../../../Components/Nombase/NombaseRegisterButtons/NombaseRegisterButtons";
import NombaseButton from "../../../../Components/Nombase/NombaseButton/NombaseButton";

const NombaseRegisterFreeStep4: FC = observer(() => {
    const classes = useStyles();
    const history = useHistory();

    return (
        <>
            <Typography
                sx={{fontFamily: "adelle-sans", marginBottom: 4}}
                textAlign="center"
            >
                Your account has been successfully created. A confirmation has
                been sent to your email (
                <span className={classes.color_highlight}>
                    {RegistrationStore.email}
                </span>
                ). Continue to log in and get nomming!
            </Typography>
            <NombaseRegisterButtons centered>
                <NombaseButton
                    onClick={() =>
                        history.push(PageUrl(Pages.LOGIN, Store.returnUrl))
                    }
                >
                    Continue
                </NombaseButton>
            </NombaseRegisterButtons>
        </>
    );
});

export default NombaseRegisterFreeStep4;
