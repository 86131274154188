import React, {FC, useEffect, useState} from "react";
import {Link as RouterLink, useHistory, useParams} from "react-router-dom";
import Box from "@material-ui/core/Box";
import {
    Divider,
    FormHelperText,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import Pages, {PageUrl} from "../Constants/Pages";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import OneColumnLayout from "../Components/OneColumnLayout";
import Header from "../Components/Header";
import BevnetTextField from "../Components/BevnetTextField/BevnetTextField";
import useFetch from "../Hooks/useFetch";
import Store from "../store/store";
import {observer} from "mobx-react-lite";

interface SetPasswordParams {
    uGuid: string;
    passwordResetGuid: string;
}

type SetPasswordForm = {
    password: string;
    confirmPassword: string;
};

const validationSchema: Yup.SchemaOf<SetPasswordForm> = Yup.object({
    password: Yup.string().required("Password is required"),
    confirmPassword: Yup.string()
        .required("Password is required")
        .oneOf([Yup.ref("password"), null], "Passwords must match"),
}).defined();

const SetPassword: FC = observer(() => {
    const {uGuid, passwordResetGuid} = useParams<SetPasswordParams>();
    const {register, handleSubmit, formState: {errors}} = useForm<SetPasswordForm>({
        resolver: yupResolver(validationSchema),
    });

    const {
        isLoading: isLoadingGuid,
        serverError: serverErrorGuid,
        fetchNow: fetchNowGuid
    } = useFetch(`/user/checkresetguids`);
    const {
        isLoading: isLoadingSetPwd,
        serverError: serverErrorSetPwd,
        fetchNow: fetchNowPwd
    } = useFetch(`/user/setpassword`);

    const [isExpired, setIsExpired] = useState<boolean>(false);
    const [email, setEmail] = useState<string>("");
    const history = useHistory();

    useEffect(() => {
        (async () => {
            const data: SetPasswordParams = {
                uGuid: uGuid,
                passwordResetGuid: passwordResetGuid,
            };
            await fetchNowGuid({
                data: data,
                onSuccess: (result: any) => {
                    setEmail(result.data.email)
                },
                onError: (error: any) => {
                    setIsExpired(error.response.data.isExpired);
                }
            });
        })()
    }, []);

    async function onSubmit(data: SetPasswordForm) {
        const request = {
            ...data,
            uGuid: uGuid,
            passwordResetGuid: passwordResetGuid,
        };

        await fetchNowPwd({
            data: request,
            onSuccess: () => {
                history.push(PageUrl(Pages.LOGIN, Store.returnUrl))
            }
        })
    }

    return (
        <>
            <div data-cy="setPassword">
                <OneColumnLayout isLoading={isLoadingGuid || isLoadingSetPwd} title={"Set Password"}>
                    <Header
                        title={"Set Password for Your Account"}
                        secondTitle={
                            <>
                                To set your password, please enter your information into the form
                                below.{" "}
                                <strong>
                                    Passwords must be at least 8 characters in length.
                                </strong>
                            </>
                        }
                    />
                    <Box
                        sx={{
                            flexGrow: 1,
                            mt: 3,
                        }}
                    >
                        <form noValidate onSubmit={handleSubmit(onSubmit)}>
                            {!serverErrorGuid && (
                                <>
                                    <BevnetTextField
                                        label="Email address"
                                        InputLabelProps={{shrink: true}}
                                        value={email}
                                        disabled={true}
                                        name="email"
                                    />
                                    <BevnetTextField
                                        error={!!errors.password}
                                        helperText={errors.password?.message}
                                        label="Password"
                                        type="password"
                                        register={register("password")}
                                    />
                                    <BevnetTextField
                                        error={!!errors.confirmPassword}
                                        helperText={errors.confirmPassword?.message}
                                        label="Confirm Password"
                                        type="password"
                                        register={register("confirmPassword")}
                                    />
                                </>
                            )}

                            {serverErrorGuid && (
                                <Box sx={{mt: 3}}>
                                    <FormHelperText error>{serverErrorGuid}</FormHelperText>
                                </Box>
                            )}
                            {serverErrorSetPwd && (
                                <Box sx={{mt: 3}}>
                                    <FormHelperText error>{serverErrorSetPwd}</FormHelperText>
                                </Box>
                            )}
                            {(!isExpired && !serverErrorGuid) && (
                                <Box sx={{mt: 2}}>
                                    <Button
                                        color="primary"
                                        disabled={isLoadingGuid || isLoadingSetPwd}
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                    >
                                        Set Password
                                    </Button>
                                </Box>
                            )}

                            {isExpired && (
                                <Box sx={{mt: 2}}>
                                    <Button
                                        color="primary"
                                        disabled={isLoadingGuid || isLoadingSetPwd}
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        onClick={() => {
                                            history.push(PageUrl(Pages.FORGOT, Store.returnUrl))
                                        }}
                                    >
                                        Forgot Password?
                                    </Button>
                                </Box>
                            )}
                        </form>
                    </Box>
                    <Divider sx={{my: 3}}/>
                    <Link
                        color="textSecondary"
                        component={RouterLink}
                        to={PageUrl(Pages.LOGIN, Store.returnUrl)}
                        variant="body2"
                        data-cy="return"
                    >
                        Return to Login
                    </Link>
                </OneColumnLayout>
            </div>
        </>
    );
});

export default SetPassword;
