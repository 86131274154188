import TextField from "@material-ui/core/TextField";
import React, {FC} from "react";
import {UseFormRegisterReturn} from "react-hook-form";
import {BaseTextFieldProps} from "@material-ui/core/TextField/TextField";
import {Typography} from "@material-ui/core";
import {useStyles} from "./styles";
import clsx from "clsx";

type Props = BaseTextFieldProps & {
    register?: UseFormRegisterReturn,
    labelField?: string,
    classNameForWrap?: string,
    classNameForLabel?: string,
}

const BevnetTextField: FC<Props> = ({
                                        register,
                                        labelField,
                                        classNameForWrap,
                                        classNameForLabel,
                                        ...otherProps
                                    }: Props) => {
    const classes = useStyles();
    return (
        <div className={clsx(classes.field, classNameForWrap)}>
            {labelField &&
                <Typography
                    className={clsx(classes.field_label, classNameForLabel)}
                    fontWeight={500}
                    color='#182A4B'
                >
                    {labelField}
                </Typography>}
            <TextField
                {...otherProps}
                {...register}
                fullWidth
                margin="normal"
                variant="outlined"
            />
        </div>
    );
}

export default BevnetTextField
