import { createElement, useEffect, useMemo, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { SETTINGS } from "../Constants/Constants";

export default function useRecaptcha(){
    const recaptchaRef = useRef<ReCAPTCHA>(null);
    const [isValid, setIsValid] = useState<boolean>(false);

    useEffect(() => {
        setIsValid (recaptchaRef != null && recaptchaRef.current != null);
    }, [recaptchaRef, recaptchaRef.current]);

    const Component = ()=>createElement(ReCAPTCHA, {ref: recaptchaRef, size: 'invisible', sitekey: SETTINGS.RECAPTCHA_PUBLIC_KEY} as any);

    const handler = {
        getToken: async (): Promise<string> => {
            const captchaToken = await (process.env.REACT_APP_STAGE !== 'testing' ? recaptchaRef.current?.executeAsync() : Promise.resolve(''));
            recaptchaRef.current?.reset();

            return String(captchaToken);
        }       
    }

    return { isValid, handler, Component };
}