import {memo, ReactNode} from "react";
import {Typography} from "@material-ui/core";
import clsx from "clsx";
import {useStyles} from "./styles";

interface IProps {
    children?: ReactNode;
    className?: string;
}

const NombaseCaption = memo(({children, className}: IProps) => {
    const classes = useStyles();

    return (
        <Typography className={clsx(classes.nombase_caption, className || "")}>
            {children}
        </Typography>
    );
});

export default NombaseCaption;
