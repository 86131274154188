import React, {FC} from "react";
import Box from "@material-ui/core/Box";
import {Divider} from "@material-ui/core";
import Link from "@material-ui/core/Link";
import {Link as RouterLink} from "react-router-dom";
import {observer} from "mobx-react-lite";
import RegistrationFreeStep1 from "./Components/RegisterFreeStep1";
import OneColumnLayout from "../../Components/OneColumnLayout";
import {RegistrationStep} from "../../Constants/RegistrationStep";
import Header from "../../Components/Header";
import Pages, {PageUrl} from "../../Constants/Pages";
import { HOST } from '../../Constants/Constants';
import RegistrationStore from "../../store/registrationStore"
import NombaseRegisterFree from "./Components/nombase/NombaseRegisterFree";
import RegistrationFreeStep2 from "./Components/RegisterFreeStep2";
import RegistrationFreeStep3 from "./Components/RegisterFreeStep3";
import Store from "../../store/store";

const RegistrationFree: FC = observer(() => {

    if (Store.host === HOST.NOMBASE) return <NombaseRegisterFree/>;

    return (
        <>
            <div data-cy="registrationFree">
                <OneColumnLayout title={"Free Registration"}>
                    {RegistrationStore.currentStep === RegistrationStep.Step1 && (
                        <Header title={"Free Registration"}
                                secondTitle={<>Your registration works with certain areas of BevNET, NOSH, and
                                    Brewbound. Want full access? Become a subscriber to BevNET/NOSH or
                                    Brewbound.</>}/>
                    )}
                    {RegistrationStore.currentStep === RegistrationStep.Step2 && (
                        <Header title={"Registration Verification"}
                                secondTitle={"Verify your email. Code is valid during 5 minutes."}/>
                    )}
                    {RegistrationStore.currentStep === RegistrationStep.Step3 && (
                        <Header title={"Registration Completion"}
                                secondTitle={"Before you can access your account, you must confirm & complete the following information."}/>
                    )}

                    <Box
                        sx={{
                            flexGrow: 1,
                            mt: 3,
                        }}
                    >
                        {RegistrationStore.currentStep === RegistrationStep.Step1 && (
                            <RegistrationFreeStep1/>
                        )}
                        {RegistrationStore.currentStep === RegistrationStep.Step2 && (
                            <RegistrationFreeStep2/>
                        )}
                        {RegistrationStore.currentStep === RegistrationStep.Step3 && (
                            <RegistrationFreeStep3/>
                        )}
                    </Box>
                    <Divider sx={{my: 3}}/>
                    <Link
                        color="textSecondary"
                        component={RouterLink}
                        to={PageUrl(Pages.LOGIN, Store.returnUrl)}
                        variant="body2"
                    >
                        Return to Login
                    </Link>
                </OneColumnLayout>
            </div>
        </>
    );
})

export default RegistrationFree;
