import React, {FC} from "react";
import {Typography} from "@material-ui/core";
import Box from "@material-ui/core/Box";

type componentProps = {
    title: string;
    largeTitle?: string;
    secondTitle: JSX.Element | string;
};

const RegisterSubmitHeader: FC<componentProps> = ({
    largeTitle,
    title,
    secondTitle,
}: componentProps) => {
    return (
        <Box
            sx={{
                flexDirection: "column",
                display: "flex",
                justifyContent: "space-between",
                mb: 0.25,
            }}
        >
            <Typography
                sx={{
                    fontSize: "26px !important",
                    mb: 0.5,
                }}
                color="textPrimary"
                gutterBottom
                variant="h5"
            >
                {title}
            </Typography>
            {largeTitle ? (
                <Typography
                    sx={{
                        fontSize: "32px !important",
                        fontWeight: 700,
                        mb: 0.5,
                    }}
                    color="textPrimary"
                    gutterBottom
                    variant="h2"
                >
                    {largeTitle}
                </Typography>
            ) : undefined}
            <Typography
                sx={{textWrap: "balance"}}
                color="textPrimary"
                variant="body1"
            >
                {secondTitle}
            </Typography>
        </Box>
    );
};

export default RegisterSubmitHeader;
