import React, {useEffect} from 'react';
import Store from "../store/store"

function LoginSuccess(){    
    useEffect(() => {
        window.location.href = Store.returnUrl;
    });
    
    return (
        <div>
            Redirecting...
        </div>
        
    );
}

export default LoginSuccess