import {memo} from "react";
import {Box, Button} from "@material-ui/core";
import Pages, {PageUrl} from "../../../../Constants/Pages";
import Store from "../../../../store/store";
import {LoginWrapperStep3Props} from "../../index";
import LoginWrapper from "../LoginWrapper";
import LoginRightSideHeadSteps from "../LoginRightSideHeadSteps";
import LoginCreateAccountLink from "../LoginCreateAccountLink";
import {useHistory} from "react-router-dom";

const BevnetStep3 = memo(({email, onBack}: LoginWrapperStep3Props) => {
    return (
        <LoginWrapper>
            <Box
                sx={{
                    mt: 2,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    minHeight: 395,
                }}
            >
                <LoginRightSideHeadSteps step={3} email={email} />
                <Button
                    color="primary"
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={onBack}
                >
                    Back
                </Button>
            </Box>
            <LoginCreateAccountLink
                url={PageUrl(Pages.REGISTER, Store.returnUrl)}
            />
        </LoginWrapper>
    );
});

export default BevnetStep3;
