const NombaseBevnetLogo = () => {
    return (
        <svg
            width="111"
            height="22"
            viewBox="0 0 111 22"
            fill="#fff"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_1117_3216)">
                <path
                    d="M110.252 4.41555H104.495V21.995H97.9625V4.41555H92.2983V0H110.252V4.41555Z"
                    fill="inherit"
                />
                <path
                    d="M18.2344 15.7554C18.2344 17.472 17.7746 18.9736 16.5062 20.2569C15.2378 21.5403 13.7593 21.995 12.0725 21.995H0V0H12.0725C13.7791 0 15.2642 0.4614 16.5244 1.7381C17.7846 3.01481 18.2393 4.51808 18.2393 6.24791C18.2393 7.94964 17.6787 9.59348 16.5608 10.8272C17.6787 12.073 18.2382 13.4886 18.2393 15.074L18.2344 15.7554ZM11.702 6.55386C11.7104 6.27114 11.6622 5.98961 11.5602 5.72578C11.4582 5.46195 11.3046 5.22115 11.1083 5.01751C10.9116 4.81958 10.6765 4.66405 10.4174 4.56052C10.1583 4.45699 9.88067 4.40765 9.60174 4.41555H6.53236V8.82448H9.60174C9.88789 8.83533 10.1729 8.78319 10.4367 8.67174C10.7004 8.56029 10.9365 8.39225 11.1282 8.17951C11.5155 7.72815 11.7202 7.14834 11.702 6.55386ZM11.702 15.3502C11.7126 15.0711 11.6656 14.7928 11.564 14.5326C11.4623 14.2725 11.3081 14.036 11.1111 13.8381C10.9141 13.6401 10.6783 13.4848 10.4187 13.3819C10.159 13.279 9.88091 13.2307 9.60174 13.24H6.53236V17.5927H9.60174C9.8876 17.6038 10.1724 17.552 10.4361 17.4412C10.6998 17.3303 10.9361 17.163 11.1282 16.9511C11.5156 16.5096 11.7207 15.9373 11.702 15.3502Z"
                    fill="inherit"
                />
                <path
                    d="M50.1451 0C48.46 0 47.8299 1.44043 47.6397 2.26896C47.3982 3.33564 45.341 12.0774 45.298 12.2577C45.2566 12.0774 43.1993 3.33564 42.9579 2.26896C42.771 1.44043 42.1376 0 40.4508 0H35.855L42.1823 21.995H48.4153L54.7393 0H50.1451Z"
                    fill="inherit"
                />
                <path
                    d="M35.1476 4.42712V0H22.4797C21.5587 0 20.6753 0.365894 20.024 1.01719C19.3727 1.66848 19.0068 2.55183 19.0068 3.4729V18.5221C19.0068 19.4432 19.3727 20.3266 20.024 20.9778C20.6753 21.6291 21.5587 21.995 22.4797 21.995H23.9516C23.9847 21.995 24.0161 22.005 24.0492 22.005H35.1476V17.5762H25.5475V13.24H34.7986V8.82448H25.5475V4.42712H35.1476Z"
                    fill="inherit"
                />
                <path
                    d="M91.2052 4.42712V0H78.5374C77.6192 0.00437244 76.7401 0.372196 76.0924 1.02302C75.4447 1.67384 75.081 2.55469 75.0811 3.4729V18.5221C75.0811 19.4418 75.4458 20.3239 76.0953 20.9749C76.7448 21.626 77.6261 21.9928 78.5457 21.995H80.0192C80.0523 21.995 80.082 22.005 80.1151 22.005H91.2052V17.5762H81.6051V13.24H90.8563V8.82448H81.6051V4.42712H91.2052Z"
                    fill="inherit"
                />
                <path
                    d="M67.5579 0V12.0725C66.5904 10.1061 62.6479 2.07878 62.4312 1.6273C62.122 0.957528 61.3546 0 59.7654 0H55.5532V21.995H61.8375V10.3658C61.8375 10.3658 66.4879 19.8799 66.7277 20.3892C67.0403 21.0507 67.7067 22 69.4448 22H73.8471V0H67.5579Z"
                    fill="inherit"
                />
                <path
                    d="M108.356 18.3027C109.514 18.3027 110.246 19.1147 110.246 20.1483C110.253 20.3948 110.209 20.6401 110.117 20.8689C110.025 21.0978 109.887 21.3052 109.711 21.4785C109.536 21.6517 109.327 21.787 109.097 21.8759C108.866 21.9649 108.621 22.0056 108.374 21.9955C107.241 21.9955 106.5 21.1934 106.5 20.1598C106.492 19.9139 106.535 19.669 106.625 19.4401C106.716 19.2113 106.852 19.0034 107.026 18.8293C107.2 18.6553 107.408 18.5187 107.636 18.4281C107.865 18.3375 108.11 18.2948 108.356 18.3027ZM108.374 21.5391C109.239 21.5391 109.747 20.952 109.747 20.1483C109.747 19.3445 109.229 18.7657 108.356 18.7657C107.518 18.7657 107 19.3462 107 20.1582C106.998 20.9421 107.518 21.5391 108.374 21.5391ZM108.186 20.4344V21.0959H107.66V19.1841H108.445C108.533 19.1766 108.62 19.1871 108.704 19.215C108.787 19.2429 108.863 19.2875 108.928 19.3461C108.993 19.4047 109.046 19.476 109.082 19.5557C109.118 19.6354 109.138 19.7217 109.14 19.8092C109.144 19.9165 109.118 20.0227 109.064 20.1158C109.011 20.2088 108.932 20.2848 108.837 20.3351L109.194 21.1108H108.652L108.376 20.4493L108.186 20.4344ZM108.186 19.6224V20.011H108.401C108.427 20.0115 108.452 20.0067 108.476 19.9969C108.501 19.9872 108.522 19.9727 108.541 19.9544C108.559 19.936 108.574 19.9141 108.583 19.89C108.593 19.866 108.598 19.8402 108.597 19.8142C108.598 19.7887 108.593 19.7635 108.583 19.74C108.573 19.7166 108.558 19.6954 108.54 19.6779C108.521 19.6603 108.499 19.6468 108.475 19.6381C108.452 19.6294 108.426 19.6257 108.401 19.6273L108.186 19.6224Z"
                    fill="inherit"
                />
            </g>
            <defs>
                <clipPath id="clip0_1117_3216">
                    <rect width="110.251" height="22" fill="inherit" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default NombaseBevnetLogo;
