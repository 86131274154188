import React, {FC} from "react";
import Box from "@material-ui/core/Box";
import {FormHelperText} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import useFetch from "../../../Hooks/useFetch";
import Loading from "../../../Components/Loading";
import BevnetTextField from "../../../Components/BevnetTextField/BevnetTextField";
import registrationSubmitStore from "../../../store/registrationSubmitStore";
import Pages, {PageUrl} from "../../../Constants/Pages";
import Store from "../../../store/store";
import {useHistory, useParams} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {useStyles} from "../styles";
import {useCookies} from "react-cookie";
import {MagicLinkParams} from "../RegisterSubmit";

type RegisterStep3Form = {
    firstName: string;
    lastName: string;
    company?: string;
    phone?: string;
    password?: string;
};

const RegisterStep3FormValidationSchema: Yup.SchemaOf<RegisterStep3Form> = Yup.object(
    {
        firstName: Yup.string()
            .required("First Name is required"),
        lastName: Yup.string()
            .required("Last Name is required"),
        company: Yup.string(),
        phone: Yup.string(),
        password: Yup.string()
            .required("Password is required"),
    }
).defined();

const RegistrationSubmitStep3: FC = observer(() => {
    const classes = useStyles();
    const history = useHistory();
    const {linkGuid} = useParams<MagicLinkParams>();
    const [cookie] = useCookies(['bevnet_magic_link']);
    const {
        register: registerStep,
        handleSubmit: handleSubmitStep,
        formState: {errors},
    } = useForm<RegisterStep3Form>({
        resolver: yupResolver(RegisterStep3FormValidationSchema),
    });
    const {isLoading, serverError, fetchNow} = useFetch('/user/RegistrationSubmitStep3');

    async function onSubmitStep1(data: RegisterStep3Form) {
        await fetchNow({
            data: {...data, email: registrationSubmitStore.email, magicCookieGuid: cookie.bevnet_magic_link , magicLinkGuid: linkGuid },
            onSuccess: (response: any) => {
                const authToken = response.data;
                window.location.href = `/user/loginredirect?authToken=${authToken}&returnUrl=${Store.returnUrl}&remember=true`;
            },
        });
    }

    return (
        <>
            <form noValidate onSubmit={handleSubmitStep(onSubmitStep1)}>
                <div className={classes.registerSubmitForm}>
                    <BevnetTextField
                        autoFocus
                        classNameForLabel={classes.registerSubmitLabel}
                        error={!!errors.firstName}
                        helperText={errors.firstName?.message}
                        labelField="First Name"
                        register={registerStep("firstName")}
                        placeholder="First Name"
                    />
                    <BevnetTextField
                        classNameForLabel={classes.registerSubmitLabel}
                        error={!!errors.lastName}
                        helperText={errors.lastName?.message}
                        labelField="Last Name"
                        register={registerStep("lastName")}
                        placeholder="Last Name"
                    />
                    <BevnetTextField
                        classNameForLabel={classes.registerSubmitLabel}
                        error={!!errors.company}
                        helperText={errors.company?.message}
                        labelField="Company"
                        register={registerStep("company")}
                        placeholder="Company"
                    />
                    <BevnetTextField
                        classNameForLabel={classes.registerSubmitLabel}
                        error={!!errors.phone}
                        helperText={errors.phone?.message}
                        labelField="Phone"
                        register={registerStep("phone")}
                        placeholder="xxx-xxx-xxxx"
                    />
                    <BevnetTextField
                        classNameForLabel={classes.registerSubmitLabel}
                        error={!!errors.password}
                        helperText={errors.password?.message ?? "At least 8 characters and contain at least one capital letter and one number."}
                        labelField="Password"
                        type="password"
                        register={registerStep("password")}
                        placeholder="Enter a strong password"
                    />
                </div>

                {serverError && (
                    <Box sx={{mt: 3}}>
                        <FormHelperText error>{serverError}</FormHelperText>
                    </Box>
                )}
                <Box sx={{mt: 2}}>
                    <Button
                        className={classes.registerSubmitButton}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                    >
                        Create an Account
                    </Button>
                </Box>
            </form>
            <Loading isLoading={isLoading} />
        </>
    );
});

export default RegistrationSubmitStep3;
