import { SVGProps } from "react"

const LogoNOSH = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1238.78 308.34" {...props}>
        <defs>
            <style>{".logo-nosh-cls-1{fill:#FBC549}"}</style>
        </defs>
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
                <path
                className="logo-nosh-cls-1"
                d="M185.3,5h74.6a6.31,6.31,0,0,1,6.3,6.3V297a6.31,6.31,0,0,1-6.3,6.3H182.22a6.3,6.3,0,0,1-5.46-3.15L96.88,161.64a6.3,6.3,0,0,0-11.76,3.14V297a6.3,6.3,0,0,1-6.31,6.3H6.31A6.31,6.31,0,0,1,0,297V11.31A6.31,6.31,0,0,1,6.31,5H82.39a6.31,6.31,0,0,1,5.4,3.05l79.5,132.35A6.3,6.3,0,0,0,179,137.16V11.31A6.3,6.3,0,0,1,185.3,5M623.75,152.29c0,88.87-64.26,156-160.22,156s-160.22-67.18-160.22-156C303.31,65.51,367.57,0,463.53,0S623.75,65.51,623.75,152.29m-92.63,0c0-43.39-28.37-72.6-67.59-72.6s-67.59,29.21-67.59,72.6c0,45.48,28.37,76.36,67.59,76.36s67.59-30.88,67.59-76.36m249.07-33.38C756,109.32,738.47,106,738.47,91.38c0-12.94,11.68-16.27,25.46-16.27,27,0,51.8,14.24,63,21.76A6.28,6.28,0,0,0,836.22,94l23.92-57.88a6.27,6.27,0,0,0-2.09-7.48C847.1,20.73,813.69,0,763.09,0,696.33,0,648.35,35.47,648.35,92.63c0,53.4,41.73,76.77,79.69,92.21,30,12.51,49.65,15.85,49.65,31.71,0,12.51-11.26,16.69-24.61,16.69-30.38,0-57.64-17.77-69-26.35a6.31,6.31,0,0,0-9.29,2l-30.54,54.5a6.28,6.28,0,0,0,1.56,8c12.23,9.82,51.35,37,109.81,37,72.6,0,112.24-42.14,112.24-94.29,0-57.58-47.15-79.28-87.63-95.14M1081,11.31v99.63a6.32,6.32,0,0,1-6.31,6.31h-73.76a6.31,6.31,0,0,1-6.3-6.31V11.31A6.3,6.3,0,0,0,988.33,5H910.82a6.3,6.3,0,0,0-6.3,6.3V297a6.3,6.3,0,0,0,6.3,6.3h77.51a6.3,6.3,0,0,0,6.31-6.3V199.9a6.3,6.3,0,0,1,6.3-6.3h73.76a6.31,6.31,0,0,1,6.31,6.3V297a6.3,6.3,0,0,0,6.3,6.3h77.51a6.3,6.3,0,0,0,6.31-6.3V11.31a6.3,6.3,0,0,0-6.31-6.3h-77.51a6.3,6.3,0,0,0-6.3,6.3"
                />
                <path
                className="logo-nosh-cls-1"
                d="M1212.55,252.06c16.09,0,26.23,11.25,26.23,25.61,0,14-10.14,25.6-26,25.6s-26-11.13-26-25.48S1197.22,252.06,1212.55,252.06Zm.25,44.9c12,0,19-8.16,19-19.29,0-11.39-7.17-19.18-19.3-19.18-11.62,0-18.8,8-18.8,19.3C1193.75,288.68,1200.93,297,1212.8,297Zm-2.6-15.34v9.28h-7.29V264.18h10.88c6.06,0,9.65,4.08,9.65,8.66a7.94,7.94,0,0,1-4.21,7.3l5,10.76h-7.54l-3.84-9.28Zm0-11.25v5.32h3a2.68,2.68,0,0,0,2.73-2.73,2.57,2.57,0,0,0-2.73-2.59Z"
                />
            </g>
        </g>
  </svg>
);

export default LogoNOSH;
