import {useState} from "react";
import axios from "axios";

interface fetchRequest {
    data: any;
    onError?: Function;
    onSuccess?: Function;
}

const useFetch = (url: string) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [serverError, setServerError] = useState<string>("");

    const fetchNow = async (request: fetchRequest) => {
        setIsLoading(true);
        let errorMessage = "";
        let result = null;
        let errorResult = null;

        try {
            setServerError("");
            // fetch data from a applicationURL endpoint
            result = await axios.post(url, request.data);
            //return data;
        } catch (error) {
            errorResult = error
            if (error.response.data.message !== undefined) {
                errorMessage = error.response.data.message;
            } else {
                errorMessage = "Server error";
            }
            setServerError(errorMessage);
        }

        if (request.onError !== undefined && errorMessage !== "") {
            request.onError(errorResult);
        }
        if (request.onSuccess !== undefined && errorMessage === "") {
            request.onSuccess(result);
        }
        setIsLoading(false);

        // return await axios
        //   .post(applicationURL, request.data)
        //   .then((res) => {
        //     setServerError("");
        //     if (request.onSuccess !== undefined) {
        //       request.onSuccess();
        //     }
        //     setIsLoading(false);
        //   })
        //   .catch((c) => {
        //     if (c.response.data.message !== undefined) {
        //       setServerError(c.response.data.message);
        //     } else {
        //       setServerError("Server error");
        //     }
        //     if (request.onError !== undefined) {
        //       request.onError(serverError);
        //     }
        //     setIsLoading(false);
        //   });
    };

    // useEffect(() => {
    //   if (applicationURL) {
    //     fetchNow(applicationURL, data);
    //   }
    // }, []);

    return {isLoading, serverError, fetchNow};
};

export default useFetch;
