import {ReactNode, memo} from "react";
import clsx from "clsx";
import {useStyles} from "./styles";
import {ThemeProvider, useTheme} from "../../../theme/nombase";
import NombaseRegisterLogo from "../NombaseRegisterLogo";
import NombaseLogos from "../NombaseLogos/NombaseLogos";

interface IProps {
    children?: ReactNode;
}

const NombaseRegister = memo(({children}: IProps) => {
    const classes = useStyles();
    const {theme, isLightMode} = useTheme();

    return (
        <ThemeProvider value={{theme, isLightMode}}>
            <div
                className={clsx(
                    classes.grid_wrapper,
                    isLightMode ? "light" : undefined
                )}
            >
                <div className={classes.register_wrapper}>
                    <div className={classes.register_logo_wrapper}>
                        <NombaseRegisterLogo />
                    </div>
                    <NombaseLogos />
                    {children}
                </div>
            </div>
        </ThemeProvider>
    );
});

export default NombaseRegister;
