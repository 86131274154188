const Pages = {
    LOGIN: "/",
    LOGIN_MAGIC: "/magiclogin/:guid",
    LOGIN_MAGIC_INVALID: "/magiclogin-invalid",
    REGISTER_SUBMIT: "/RegisterSubmit/:linkGuid?",
    LOGIN_SUCCESS: "/LoginSuccess",
    LOGIN_IFRAME: "/LoginIframe",
    LOGIN_IFRAME_SUCCESS: "/LoginIframeSuccess",
    SUBSCRIPTION_REQUIRED: "/SubscriptionRequired",
    FORGOT: "/Forgot",
    PASSWORD_SENT: "/PasswordSent",
    SET_PASSWORD: "/SetPassword/:uGuid/:passwordResetGuid",
    REGISTER: "/Register",
    REGISTER_FREE: "/RegisterFree",
    IFRAME: "/Iframe",

    HEALTH: "/health"
};

export default Pages;

export function PageUrl(page: string,  returnUrl: string) {
    const returnUrlString = returnUrl !== undefined ? "?returnUrl=" + returnUrl : "";
    return page + returnUrlString;
}
