import React, {FC} from "react";
import Box from "@material-ui/core/Box";
import {Divider, FormHelperText} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import {Link as RouterLink, useHistory} from "react-router-dom";
import Pages, {PageUrl} from "../Constants/Pages";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import useFetch from "../Hooks/useFetch";
import OneColumnLayout from "../Components/OneColumnLayout";
import Header from "../Components/Header";
import BevnetTextField from "../Components/BevnetTextField/BevnetTextField";
import Store from "../store/store";
import {observer} from "mobx-react-lite";

type ForgotForm = {
    email: string;
};

const validationSchema: Yup.SchemaOf<ForgotForm> = Yup.object({
    email: Yup.string().required("Email is required").email("Email is incorrect"),
}).defined();

const Forgot: FC = observer(() => {
    const {isLoading, serverError, fetchNow} = useFetch(`/user/forgotpassword`);
    const history = useHistory();
    const {register, handleSubmit, formState: {errors}} = useForm<ForgotForm>({
        resolver: yupResolver(validationSchema),
    });

    async function onSubmit(data: ForgotForm) {
        await fetchNow({
            data: {
                ...data,
                subType: Store.hostSubTypes
            },
            onSuccess: () => {
                history.push(PageUrl(Pages.PASSWORD_SENT, Store.returnUrl));
            },
        });
    }

    return (
        <>
            <div data-cy="forgot">
                <OneColumnLayout isLoading={isLoading} title={"Forgot"}>
                    <Header
                        title={"Forgot your password?"}
                        secondTitle={
                            "To retrieve your password, please enter your email address below."
                        }
                    />
                    <Box
                        sx={{
                            flexGrow: 1,
                            mt: 3,
                        }}
                    >
                        <form noValidate onSubmit={handleSubmit(onSubmit)}>
                            <BevnetTextField
                                autoFocus
                                error={!!errors.email}
                                helperText={errors.email?.message}
                                labelField="Email Address"
                                register={register("email")}
                                placeholder="Enter your password"
                            />
                            {serverError && (
                                <Box sx={{mt: 3}}>
                                    <FormHelperText error>{serverError}</FormHelperText>
                                </Box>
                            )}
                            <Box sx={{mt: 2}}>
                                <Button
                                    style={{
                                        borderRadius: 5
                                    }}
                                    color="primary"
                                    //disabled={isSubmitting}
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                >
                                    Get Password
                                </Button>
                            </Box>
                        </form>
                    </Box>
                    <Divider sx={{my: 3}}/>
                    <Link
                        color="textSecondary"
                        component={RouterLink}
                        to={PageUrl(Pages.LOGIN, Store.returnUrl)}
                        variant="body2"
                        data-cy="return"
                    >
                        Return to Login
                    </Link>
                </OneColumnLayout>
            </div>
        </>
    );
});

export default Forgot;
