import Link from "@material-ui/core/Link";
import React, {FC} from "react";

type props = {
    to: string;
    text: string;
};

const ParentRedirectLink: FC<props> = ({ to, text }: props) => {
    return (
        <>
            <Link color="textSecondary"
                  variant="body2"
                  href = {to}
                  target="_PARENT">
                {text}
            </Link>
        </>
    )
}

export default ParentRedirectLink