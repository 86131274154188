import {memo} from "react";
import {Link as RouterLink} from "react-router-dom";
import Link from "@material-ui/core/Link";
import {useStyles} from "./styles";
import Pages, {PageUrl} from "../../../Constants/Pages";
import Store from "../../../store/store";
import NombaseCaption from "../NombaseCaption/NombaseCaption";

interface IProps {
    long?: boolean;
}

const NombaseReturnToLoginButton = memo(({long = false}: IProps) => {
    const classes = useStyles();

    return (
        <NombaseCaption className={classes.nombase_return}>
            {long && <>Already have an account?</>}{" "}
            <Link
                component={RouterLink}
                to={PageUrl(Pages.LOGIN, Store.returnUrl)}
                variant="body2"
                data-cy="return"
            >
                {long ? "Log In" : "Return to Login"}
            </Link>
        </NombaseCaption>
    );
});

export default NombaseReturnToLoginButton;
