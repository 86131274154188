import type {ThemeOptions} from "@material-ui/core";
import {
    APP_COLORS,
    FONT_FAMILY,
    FONT_WEIGHT,
    INPUT,
    UI_COLORS,
} from "../Constants/LoginMagicStyleConstants";

export const loginMagicThemeOptions: ThemeOptions = {
    palette: {
        text: {
            primary: APP_COLORS.OFF_BLACK,
            secondary: APP_COLORS.DARK_BLUE,
            disabled: APP_COLORS.GRAY_MEDIUM,
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: "none",
                    borderRadius: INPUT.BORDER_RADIUS,
                    padding: INPUT.PADDING,
                    boxShadow: "none",
                },
                containedPrimary: {
                    color: "#fff",
                    backgroundColor: UI_COLORS.BUTTON_COLOR_PRIMARY,
                    "&:hover": {
                        backgroundColor: UI_COLORS.BUTTON_COLOR_PRIMARY_HOVER,
                    },
                },
                containedSecondary: {
                    color: "#fff",
                    backgroundColor: UI_COLORS.BUTTON_COLOR_SECONDARY,
                    "&:hover": {
                        backgroundColor: UI_COLORS.BUTTON_COLOR_SECONDARY_HOVER,
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: INPUT.BORDER_RADIUS,
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: UI_COLORS.BORDER_COLOR_HOVER,
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: UI_COLORS.BORDER_COLOR_FOCUS,
                        borderWidth: 1,
                    },
                    "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
                        borderColor: UI_COLORS.BORDER_COLOR_LIGHT,
                    },
                },
                notchedOutline: {
                    borderColor: UI_COLORS.BORDER_COLOR,
                    transition:
                        "border-color 0.15s ease, outline-width 0.15s ease",
                },
                input: {
                    padding: INPUT.PADDING,
                    color: APP_COLORS.DARK_BLUE,
                    transition: "color 0.15s ease",
                    "&::placeholder": {
                        color: APP_COLORS.GRAY_MEDIUM,
                    },
                },
            },
        },
    },
    typography: {
        button: {
            fontWeight: FONT_WEIGHT.MEDIUM,
        },
        fontFamily: FONT_FAMILY,
        h1: {
            fontWeight: FONT_WEIGHT.MEDIUM,
            fontSize: "3.5rem",
        },
        h2: {
            fontWeight: FONT_WEIGHT.MEDIUM,
            fontSize: "3rem",
        },
        h3: {
            fontWeight: FONT_WEIGHT.MEDIUM,
            fontSize: "2.25rem",
        },
        h4: {
            fontWeight: FONT_WEIGHT.MEDIUM,
            fontSize: "2rem",
        },
        h5: {
            fontWeight: FONT_WEIGHT.MEDIUM,
            fontSize: "1.5rem",
        },
        h6: {
            fontWeight: FONT_WEIGHT.MEDIUM,
            fontSize: "1.125rem",
        },
        overline: {
            fontWeight: FONT_WEIGHT.MEDIUM,
        },
    },
};
