import React, {PropsWithChildren} from "react";
import {Helmet} from "react-helmet-async";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import {Card, CardContent} from "@material-ui/core";
import Copyright from "../Copyright";
import Loading from "../Loading";
import {FC} from "react";
import store from "../../store/store";
import MobileModeLogos from "../MobileModeLogos/MobileModeLogos";
import MobileModeLogosForSubmit from '../MobileModeLogosForSubmit/MobileModeLogosForSubmit';
import {useStyles} from "./styles";
import {observer} from "mobx-react-lite";

type ComponentProps = PropsWithChildren<{
    isLoading?: boolean;
    title: string;
    isRegisterSubmit?: boolean;
}>;

const OneColumnLayout: FC<ComponentProps> = observer(({children, isLoading, title, isRegisterSubmit = false}: ComponentProps) => {
        const classes = useStyles();
        return (
            <>
                <Helmet>
                    <title>{title} | BevNET.com</title>
                </Helmet>
                <Box
                    sx={{
                        backgroundColor: "background.paper",
                        display: "flex",
                        flexDirection: "column",
                        //minHeight: "100vh",
                    }}
                >
                    <Container maxWidth="sm" sx={{pt: "0px"}} style={{
                        paddingLeft: 0,
                        paddingRight: 0,
                    }}>
                        {isRegisterSubmit ? <MobileModeLogosForSubmit numberHost={store.host}/> : <MobileModeLogos numberHost={store.host}/>}
                        <Card>
                            <CardContent
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    p: 4,
                                }}
                                className={classes.wrapper}
                            >
                                {children}
                            </CardContent>
                        </Card>
                    </Container>
                </Box>
                <Box mt={8}>
                    <Copyright/>
                </Box>
                <Loading isLoading={isLoading ?? false}/>
            </>
        );
    }
)

export default OneColumnLayout;
