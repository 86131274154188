import {FC, useEffect, useCallback} from "react";
import {useLocation} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {useCookies} from "react-cookie";
import useFetch from "../../../../Hooks/useFetch";
import {RegistrationStep} from "../../../../Constants/RegistrationStep";
import Store from "../../../../store/store";
import RegistrationStore from "../../../../store/registrationStore";
import NombaseHelmet from "../../../../Components/Nombase/NombaseHelmet";
import NombaseRegisterWrapper from "../../../../Components/Nombase/NombaseRegisterWrapper/NombaseRegisterWrapper";
import NombaseRegisterBox from "../../../../Components/Nombase/NombaseRegisterBox/NombaseRegisterBox";
import NombaseRegisterFreeStep1 from "./NombaseRegisterFreeStep1";
import NombaseRegisterFreeStep2 from "./NombaseRegisterFreeStep2";
import NombaseRegisterFreeStep3 from "./NombaseRegisterFreeStep3";
import NombaseRegisterFreeStep4 from "./NombaseRegisterFreeStep4";

interface ValidateMagicLink {
    linkGuid: string;
    cookieGuid: string;
}

const NombaseRegisterFree: FC = observer(() => {
    const {search} = useLocation();
    const [cookie] = useCookies(["bevnet_magic_link"]);
    const {fetchNow} = useFetch(`/user/checkmagiclink`);
    const linkGuid = new URLSearchParams(search).get("linkGuid") || "";

    const checkMagicLink = useCallback(async () => {
        if (!linkGuid || !cookie.bevnet_magic_link) return;

        const data: ValidateMagicLink = {
            cookieGuid: cookie.bevnet_magic_link,
            linkGuid: linkGuid,
        };

        await fetchNow({
            data: data,
            onSuccess: ({
                data: {email, returnUrl},
            }: {
                data: {email: string; returnUrl: string};
            }) => {
                RegistrationStore.setEmail(email);
                RegistrationStore.setCurrentStep(RegistrationStep.Step3);
                Store.setReturnUrl(returnUrl);
            },
        });
    }, [linkGuid, cookie.bevnet_magic_link]);

    const firstStepsHeadingTexts = {
        title: "Welcome to Nombase",
        description: "Let’s get started by verifying your email.",
    };

    const heading = {
        [RegistrationStep.Step1]: {
            title: firstStepsHeadingTexts.title,
            description: firstStepsHeadingTexts.description,
            component: <NombaseRegisterFreeStep1 />,
        },
        [RegistrationStep.Step2]: {
            title: firstStepsHeadingTexts.title,
            description: firstStepsHeadingTexts.description,
            component: <NombaseRegisterFreeStep2 />,
        },
        [RegistrationStep.Step3]: {
            title: "Create Your Account",
            description:
                "Before you can access your account, you must confirm & complete the following information.",
            component: (
                <NombaseRegisterFreeStep3
                    magicCookieGuid={cookie.bevnet_magic_link}
                    magicLinkGuid={linkGuid}
                />
            ),
        },
        [RegistrationStep.Step4]: {
            title: "Well done!",
            description: "",
            component: <NombaseRegisterFreeStep4 />,
        },
    };

    useEffect(() => {
        checkMagicLink();
    }, [checkMagicLink]);

    return (
        <>
            <NombaseHelmet title="Register Free" />
            <NombaseRegisterWrapper data-cy="registrationFree">
                <NombaseRegisterBox
                    title={heading[RegistrationStore.currentStep].title}
                    description={
                        heading[RegistrationStore.currentStep].description
                    }
                    centerDescription
                    shrink={
                        RegistrationStore.currentStep === RegistrationStep.Step4
                    }
                >
                    {heading[RegistrationStore.currentStep].component}
                </NombaseRegisterBox>
            </NombaseRegisterWrapper>
        </>
    );
});

export default NombaseRegisterFree;
