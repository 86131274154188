import {FC} from "react";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm, FormProvider} from "react-hook-form";
import {observer} from "mobx-react-lite";
import {useStyles} from "./styles";
import useFetch from "../../../../Hooks/useFetch";
import {NOMBASE_PAGES} from "../../../../Constants/Constants";
import {RegistrationStep} from "../../../../Constants/RegistrationStep";
import RegistrationStore from "../../../../store/registrationStore";
import Loading from "../../../../Components/Loading";
import NombaseRegisterButtons from "../../../../Components/Nombase/NombaseRegisterButtons/NombaseRegisterButtons";
import NombaseButton from "../../../../Components/Nombase/NombaseButton/NombaseButton";
import NombaseTextField from "../../../../Components/Nombase/NombaseTextField/NombaseTextField";
import NombaseCaption from "../../../../Components/Nombase/NombaseCaption/NombaseCaption";
import NombaseFormHelperText from "../../../../Components/Nombase/NombaseFormHelperText/NombaseFormHelperText";
import NombaseReturnToLoginButton from "../../../../Components/Nombase/NombaseReturnToLoginButton/NombaseReturnToLoginButton";

export type RegisterStep3FormNombase = {
    firstName: string;
    lastName: string;
    company?: string;
    phone?: string;
    password?: string;
    confirmPassword?: string;
};

export const RegisterStep3FormValidationSchemaNombase: Yup.SchemaOf<RegisterStep3FormNombase> = Yup.object(
    {
        firstName: Yup.string().required("First Name is required"),
        lastName: Yup.string().required("Last Name is required"),
        company: Yup.string(),
        phone: Yup.string(),
        password: Yup.string()
            .required("Password is required")
            .matches(
                /^(?=.*[A-Z])(?=.*\d).{8,}$/,
                "Password must match all the requirements below"
            ),
        confirmPassword: Yup.string()
            .required("Confirm Password is required")
            .oneOf([Yup.ref("password"), null], "Passwords must match"),
    }
).defined();

interface IProps {
    magicCookieGuid: string;
    magicLinkGuid: string;
}

const NombaseRegisterFreeStep3: FC<IProps> = observer(
    ({magicCookieGuid, magicLinkGuid}: IProps) => {
        const classes = useStyles();
        const {
            register,
            handleSubmit,
            watch,
            formState: {errors, isValid, ...formStateProps},
            ...methods
        } = useForm<RegisterStep3FormNombase>({
            resolver: yupResolver(RegisterStep3FormValidationSchemaNombase),
        });
        const {isLoading, serverError, fetchNow} = useFetch(
            `/user/RegistrationSubmitStep3`
        );

        async function onSubmitStep3(data: RegisterStep3FormNombase) {
            await fetchNow({
                data: {
                    ...data,
                    email: RegistrationStore.email,
                    magicCookieGuid,
                    magicLinkGuid,
                },
                onSuccess: () =>
                    RegistrationStore.setCurrentStep(RegistrationStep.Step4),
            });
        }

        return (
            <>
                <FormProvider
                    register={register}
                    handleSubmit={handleSubmit}
                    watch={watch}
                    formState={{errors, isValid, ...formStateProps}}
                    {...methods}
                >
                    <form noValidate onSubmit={handleSubmit(onSubmitStep3)}>
                        <div className={classes.create_account_form_fields}>
                            <NombaseTextField
                                labelField="First Name"
                                placeholder="First Name"
                                helperText={errors.firstName?.message}
                                register={register("firstName")}
                                error={!!errors.firstName}
                                autoFocus
                            />
                            <NombaseTextField
                                labelField="Last Name"
                                placeholder="Last Name"
                                helperText={errors.lastName?.message}
                                register={register("lastName")}
                                error={!!errors.lastName}
                            />
                            <NombaseTextField
                                labelField="Company"
                                placeholder="Company"
                                helperText={errors.company?.message}
                                register={register("company")}
                                error={!!errors.company}
                                showOptional
                            />
                            <NombaseTextField
                                type="tel"
                                labelField="Phone"
                                placeholder="xxx-xxx-xxxx"
                                helperText={errors.phone?.message}
                                register={register("phone")}
                                error={!!errors.phone}
                                showOptional
                            />
                            <div>
                                <NombaseTextField
                                    type="password"
                                    labelField="Create Password"
                                    placeholder="Password"
                                    helperText={errors.password?.message}
                                    register={register("password")}
                                    error={!!errors.password}
                                />
                                <ul className={classes.password_check_list}>
                                    <li
                                        className={
                                            /[A-Z]/.test(
                                                watch("password") || ""
                                            )
                                                ? "valid"
                                                : ""
                                        }
                                    >
                                        Must include 1 uppercase character
                                    </li>
                                    <li
                                        className={
                                            /\d/.test(watch("password") || "")
                                                ? "valid"
                                                : ""
                                        }
                                    >
                                        Must include 1 number
                                    </li>
                                    <li
                                        className={
                                            (watch("password")?.length ?? 0) >=
                                            8
                                                ? "valid"
                                                : ""
                                        }
                                    >
                                        8 character minimum
                                    </li>
                                </ul>
                            </div>
                            <NombaseTextField
                                type="password"
                                labelField="Confirm Password"
                                placeholder="Confirm Password"
                                helperText={errors.confirmPassword?.message}
                                register={register("confirmPassword")}
                                error={!!errors.confirmPassword}
                            />
                        </div>
                        {serverError && (
                            <NombaseFormHelperText
                                message={serverError}
                                error
                            />
                        )}
                        <NombaseRegisterButtons>
                            <NombaseButton
                                type="submit"
                                disabled={isLoading || !isValid}
                            >
                                Create Account
                            </NombaseButton>
                        </NombaseRegisterButtons>
                        <NombaseCaption
                            className={classes.create_account_disclaimer}
                        >
                            By clicking “Create Account”, I agree to BevNET’s{" "}
                            <a
                                href={NOMBASE_PAGES.TERMS_OF_SERVICE}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Terms of Service
                            </a>{" "}
                            and{" "}
                            <a
                                href={NOMBASE_PAGES.PRIVACY_POLICY}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Privacy Policy
                            </a>
                            .
                        </NombaseCaption>
                    </form>
                    <Loading isLoading={isLoading} />
                </FormProvider>
                <NombaseReturnToLoginButton long />
            </>
        );
    }
);

export default NombaseRegisterFreeStep3;
