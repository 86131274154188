import clsx from 'clsx';
import React, {memo} from 'react';
import {HOST} from "../../Constants/Constants";
import MobileActiveLogoBevnet from "./components/MobileActiveLogoBevnet";
import MobileActiveLogoBrewbound from "./components/MobileActiveLogoBrewbound";
import MobileActiveLogoNosh from "./components/MobileActiveLogoNosh";
import MobileActiveLogoTasteRadio from "./components/MobileActiveLogoTasteRadio";
import {useStyles} from './styles';

interface IMobileModeActiveLogoProps {
    numberHost: HOST | null;
    extraClass?: string
}

const MobileModeLogos = ({numberHost = null, extraClass}: IMobileModeActiveLogoProps) => {
    const classes = useStyles();

    if (numberHost) {
        return (
            <div className={clsx(classes.wrapper_for_logos_mobile, extraClass)}>
                {numberHost === HOST.BEVNET && (
                    <MobileActiveLogoBevnet/>
                )}
                {numberHost === HOST.BREWBOUND && (
                    <MobileActiveLogoBrewbound/>
                )}
                {numberHost === HOST.NOSH && (
                    <MobileActiveLogoNosh/>
                )}
                {numberHost === HOST.TASTE_RADIO && (
                    <MobileActiveLogoTasteRadio/>
                )}
            </div>
        );
    }
    return null
};

export default memo(MobileModeLogos);
