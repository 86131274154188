import {FC} from "react";
import {useForm, FormProvider} from "react-hook-form";
import {useCookies} from "react-cookie";
import {observer} from "mobx-react-lite";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {RegistrationStep} from "../../../../Constants/RegistrationStep";
import Store from "../../../../store/store";
import RegistrationStore from "../../../../store/registrationStore";
import useFetch from "../../../../Hooks/useFetch";
import Loading from "../../../../Components/Loading";
import NombaseRegisterButtons from "../../../../Components/Nombase/NombaseRegisterButtons/NombaseRegisterButtons";
import NombaseButton from "../../../../Components/Nombase/NombaseButton/NombaseButton";
import NombaseTextField from "../../../../Components/Nombase/NombaseTextField/NombaseTextField";
import NombaseReturnToLoginButton from "../../../../Components/Nombase/NombaseReturnToLoginButton/NombaseReturnToLoginButton";

export type RegisterStep1FormNombase = {
    email: string;
};

export const RegisterStep1FormValidationSchemaNombase: Yup.SchemaOf<RegisterStep1FormNombase> = Yup.object(
    {
        email: Yup.string()
            .required("Email is required")
            .email("Email is incorrect"),
    }
).defined();

const NombaseRegisterFreeStep1: FC = observer(() => {
    const [, setCookie] = useCookies(["bevnet_magic_link"]);
    const {
        register,
        handleSubmit,
        formState: {errors, isValid, ...formStateProps},
        ...methods
    } = useForm<RegisterStep1FormNombase>({
        resolver: yupResolver(RegisterStep1FormValidationSchemaNombase),
    });
    const {isLoading, serverError, fetchNow} = useFetch(
        `/user/RegistrationStep1WithMagicLink`
    );

    async function onSubmitStep1(data: RegisterStep1FormNombase) {
        await fetchNow({
            data: {
                ...data,
                subType: Store.hostSubTypes,
            },
            onSuccess: ({data: cookieGuid}: {data: string}) => {
                RegistrationStore.setEmail(data.email);
                RegistrationStore.setCurrentStep(RegistrationStep.Step2);
                setCookie("bevnet_magic_link", cookieGuid);
            },
        });
    }

    return (
        <>
            <FormProvider
                register={register}
                handleSubmit={handleSubmit}
                formState={{errors, isValid, ...formStateProps}}
                {...methods}
            >
                <form noValidate onSubmit={handleSubmit(onSubmitStep1)}>
                    <NombaseTextField
                        type="email"
                        labelField="Email"
                        placeholder="Enter your email address"
                        helperText={
                            errors.email?.message || (
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: serverError,
                                    }}
                                />
                            )
                        }
                        register={register("email")}
                        error={!!errors.email || !!serverError}
                        autoFocus
                    />
                    <NombaseRegisterButtons>
                        <NombaseButton
                            type="submit"
                            disabled={isLoading || !isValid}
                        >
                            Verify Email
                        </NombaseButton>
                    </NombaseRegisterButtons>
                </form>
                <Loading isLoading={isLoading} />
            </FormProvider>
            <NombaseReturnToLoginButton long />
        </>
    );
});

export default NombaseRegisterFreeStep1;
