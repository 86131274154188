import React, {FC} from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import * as Yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {Card, CardContent, Divider, FormHelperText, Typography,} from "@material-ui/core";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {Helmet} from "react-helmet-async";
import Pages, {PageUrl} from "../Constants/Pages";
import Loading from "../Components/Loading";
import useFetch from "../Hooks/useFetch";
import BevnetTextField from "../Components/BevnetTextField/BevnetTextField";
import Store from "../store/store";
import {observer} from "mobx-react-lite";
import store from "../store/store";
import {SUB_TYPES} from "../Constants/Constants";
import ParentRedirectLink from "../Components/ParentRedirectLink";
import GoogleButton from "react-google-button";
import MobileModeLogos from "../Components/MobileModeLogos/MobileModeLogos";

type LoginIframeForm = {
    email: string;
    password: string;
};

const validationSchema: Yup.SchemaOf<LoginIframeForm> = Yup.object({
    email: Yup.string().required("Email is required").email("Email is incorrect"),
    password: Yup.string().required("Password is required"),
}).defined();

const LoginIframe: FC = observer(() => {
    const history = useHistory();
    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const isFromArticle = query.get('isFromArticle') || false;

    const {isLoading, serverError, fetchNow} = useFetch(`/user/LoginIframe?returnUrl=${Store.returnUrl}`);

    const {register, handleSubmit, formState: {errors}} = useForm<LoginIframeForm>({
        resolver: yupResolver(validationSchema),
    });

    async function onSubmit(data: LoginIframeForm) {
        await fetchNow({
            data: data,
            onSuccess: () => {
                console.log(document.cookie);
                history.push(PageUrl(Pages.LOGIN_IFRAME_SUCCESS, Store.returnUrl));
            },
        });
    }

    let {lang} = useParams<{ lang: string }>();
    console.log(lang);
    return (
        <>
            <Helmet>
                <title>
                    {store.hostSubTypes === SUB_TYPES.BEVNET_NOSH ? "Login | BevNET.com & NOSH.com" : "Login | Brewbound.com"}
                </title>
            </Helmet>
            <Box
                sx={{
                    backgroundColor: "background.paper",
                    display: "flex",
                    flexDirection: "column",
                    //minHeight: "100vh",
                }}
            >
                <Container maxWidth="xs" sx={{pt: "5px"}} style={{
                    padding: 0
                }}>
                    <MobileModeLogos numberHost={store.host}/>
                    <Card style={{
                        background: '#f8f8f8',
                        border: '1px solid #BFBFBF'
                    }}>
                        <Grid container>
                            <Grid item lg={12} md={12} sm={12} xs={12}>

                                <CardContent
                                    style={{
                                        padding: 0
                                    }}
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        // p: 4,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            flexGrow: 1
                                        }}
                                        style={{
                                            padding: 25
                                        }}
                                    >
                                        <Typography color="textPrimary" gutterBottom variant="h4" style={{
                                            marginBottom: 20
                                        }}>
                                            Log in
                                        </Typography>
                                        <form noValidate onSubmit={handleSubmit(onSubmit)}>
                                            <BevnetTextField
                                                autoFocus
                                                error={!!errors.email}
                                                helperText={errors.email?.message}
                                                labelField="Email Address"
                                                register={register("email")}
                                            />

                                            <BevnetTextField
                                                error={!!errors.password}
                                                helperText={errors.password?.message}
                                                labelField="Password"
                                                type="password"
                                                register={register("password")}
                                            />
                                            {serverError && (
                                                <Box sx={{mt: 3}}>
                                                    <FormHelperText error>{serverError}</FormHelperText>
                                                </Box>
                                            )}
                                            <Box sx={{mt: 2}}>
                                                <Button
                                                    style={{borderRadius: 5}}
                                                    color="primary"
                                                    fullWidth
                                                    size="large"
                                                    type="submit"
                                                    variant="contained"
                                                >
                                                    Log In
                                                </Button>
                                            </Box>
                                            <Box sx={{mt: 2}}>
                                                <GoogleButton
                                                    label={"Continue with Google"}
                                                    type={"light"}
                                                    style={{width: "100%"}}
                                                    onClick={() => {
                                                        window.parent.location.href =
                                                            "/external/LoginGoogleIframe?returnUrl=" + Store.returnUrl +"&isFromArticle=" + isFromArticle;
                                                        return false;
                                                    }}
                                                />
                                            </Box>
                                        </form>
                                        <Divider sx={{my: 3}}/>
                                        <ParentRedirectLink
                                            to={store.hostSubTypes === SUB_TYPES.BEVNET_NOSH ? "https://app.bevnet.com/subscribe" : "https://app.bevnet.com/subscribe/brewbound"}
                                            text="Become an Insider to get full access"/>
                                    </Box>

                                </CardContent>
                            </Grid>
                        </Grid>
                    </Card>
                </Container>
            </Box>
            <Loading isLoading={isLoading}/>
        </>
    );
});

export default LoginIframe;
