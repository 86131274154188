import React, {memo} from "react";
import {FormHelperText, Box, Button, Typography} from "@material-ui/core";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import Store from "../../../../store/store";
import Pages, {PageUrl} from "../../../../Constants/Pages";
import {
    LoginFormStep2,
    LoginWrapperStep2Props,
    loginFormStep2ValidationSchema,
} from "../../index";
import LoginTextField from "../../components/LoginTextField";
import LoginWrapper from "../LoginWrapper";
import LoginRightSideHeadSteps from "../LoginRightSideHeadSteps";
import LoginCreateAccountLink from "../LoginCreateAccountLink";
import LoginDividedOr from "../LoginDividedOr";
import LoginGoogleButton from "../LoginGoogleButton";
import {Link as RouterLink} from "react-router-dom";
import Link from "@material-ui/core/Link";
import {APP_COLORS, FONT_WEIGHT} from "../../../../Constants/LoginMagicStyleConstants";

const BevnetStep2 = memo(
    ({
        onSubmit,
        CaptchaComponent,
        serverError,
        onMagic,
    }: LoginWrapperStep2Props) => {
        const {
            register,
            handleSubmit,
            formState: {errors},
        } = useForm<LoginFormStep2>({
            resolver: yupResolver(loginFormStep2ValidationSchema),
        });
        

        return (
            <LoginWrapper>
                <LoginRightSideHeadSteps step={2} />
                <form noValidate onSubmit={handleSubmit(onSubmit)}>
                    <CaptchaComponent />
                    <LoginTextField
                        type="password"
                        id="password"
                        labelText="Password"
                        error={!!errors.password}
                        helperText={errors.password?.message}
                        register={register("password")}
                        placeholder="Enter password"
                        autoFocus
                    />
                    <Typography
                        color="textSecondary"
                        fontSize={14}
                        left={"14px"}
                        fontWeight={FONT_WEIGHT.REGULAR}
                        textAlign="right"
                        sx={{
                            justifySelf: "flex-end",
                        }}
                    >
                        <Link
                            color={APP_COLORS.ACCENT}
                            sx={{fontSize: 13, fontWeight: 400}}
                            component={RouterLink}
                            to={PageUrl(Pages.FORGOT, Store.returnUrl)}
                            variant="body2"
                        >
                            Forgot password?
                        </Link>
                    </Typography>
                    {serverError && (
                        <Box sx={{mt: 3}}>
                            <FormHelperText error>{serverError}</FormHelperText>
                        </Box>
                    )}
                    <Box sx={{mt: 2}}>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            fullWidth
                        >
                            Log In
                        </Button>
                    </Box>
                    <LoginDividedOr />
                    <Box
                        sx={{display: "flex", flexDirection: "column", gap: 2}}
                    >
                        <Button
                            variant="contained"
                            color="secondary"
                            fullWidth
                            onClick={onMagic}
                        >
                            Sign in with Magic Link
                        </Button>
                        <LoginGoogleButton />
                    </Box>
                </form>
                <LoginCreateAccountLink
                    url={PageUrl(Pages.REGISTER, Store.returnUrl)}
                />
            </LoginWrapper>
        );
    }
);

export default BevnetStep2;
