import {useState, useEffect} from "react";
import {observer} from "mobx-react-lite";
import {Link, Typography} from "@material-ui/core";
import {HOST} from "../../../Constants/Constants";
import {FONT_WEIGHT} from "../../../Constants/LoginMagicStyleConstants";
import Store from "../../../store/store";
import SubTypeText from "../../../Components/SubTypeText";
import LoginRightSideHead from "./LoginRightSideHead";

type IUrls =
    | "https://app.bevnet.com/subscribe"
    | "https://app.bevnet.com/subscribe/brewbound";

interface IProps {
    step: number;
    email?: string;
}

const LoginRightSideHeadSteps = observer(({step, email}: IProps) => {
    const {host} = Store;
    const [link, setLink] = useState<IUrls>("https://app.bevnet.com/subscribe");

    useEffect(() => {
        if (host !== HOST.BREWBOUND) {
            setLink("https://app.bevnet.com/subscribe");
            return;
        }
        setLink("https://app.bevnet.com/subscribe/brewbound");
    }, [host]);

    if (step === 3)
        return (
            <LoginRightSideHead
                title="Check your email!"
                description={
                    <Typography
                        color="textSecondary"
                        variant="body2"
                        fontSize={16}
                        lineHeight={"19.4px"}
                        fontWeight={400}
                    >
                        We've sent a magic login link to{" "}
                        <Typography
                            component="strong"
                            color="textPrimary"
                            fontWeight={FONT_WEIGHT.MEDIUM}
                        >
                            {email}
                        </Typography>
                    </Typography>
                }
            />
        );

    return (
        <LoginRightSideHead
            title="Log In to Access Content"
            description={
                <>
                    <Typography
                        color="textSecondary"
                        fontSize={14}
                        lineHeight={"18px"}
                        fontWeight={FONT_WEIGHT.MEDIUM}
                        component="strong"
                    >
                        Please note:
                    </Typography>{" "}
                    a <SubTypeText /> Insider subscription might be required for
                    full access.{" "}
                    <Link
                        color="textSecondary"
                        fontSize={14}
                        lineHeight={"18px"}
                        href={link}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {" "}
                        <u>Learn more</u>
                    </Link>
                </>
            }
        />
    );
});

export default LoginRightSideHeadSteps;
