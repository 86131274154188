import {ReactNode, MouseEvent, memo, useState} from "react";
import {UseFormRegisterReturn, useFormContext} from "react-hook-form";
import {
    TextField,
    Typography,
    InputAdornment,
    IconButton,
} from "@material-ui/core";
import {useStyles} from "./styles";
import NombaseIconShow from "../NombaseIcons/NombaseIconShow";

interface IProps {
    type?: "text" | "password" | "email" | "tel";
    labelField: string;
    placeholder: string;
    helperText?: string | ReactNode;
    register?: UseFormRegisterReturn;
    error?: boolean;
    autoFocus?: boolean;
    showOptional?: boolean;
}

const NombaseTextField = memo(
    ({
        type = "text",
        labelField,
        placeholder,
        helperText,
        register,
        error = false,
        autoFocus = false,
        showOptional = false,
    }: IProps) => {
        const classes = useStyles();
        const formContext = useFormContext();
        const isPasswordField = type === "password";
        const fallbackId = labelField.replace(/\s/g, "-").toLowerCase();
        const [showPassword, setShowPassword] = useState(false);

        const handleClickShowPassword = () => setShowPassword((show) => !show);

        const handleMouseDownPassword = (
            event: MouseEvent<HTMLButtonElement>
        ) => {
            event.preventDefault();
        };

        const handleBlur = async () => {
            if (register?.name && formContext?.trigger)
                await formContext.trigger(register.name);
        };

        return (
            <div className={classes.field}>
                {labelField && (
                    <Typography
                        component="label"
                        htmlFor={register?.name || fallbackId}
                        className={classes.field_label}
                    >
                        {labelField}
                        {showOptional && (
                            <>
                                {" "}
                                <span className={classes.field_label_optional}>
                                    (optional)
                                </span>
                            </>
                        )}
                    </Typography>
                )}
                <TextField
                    {...register}
                    id={register?.name || fallbackId}
                    fullWidth
                    type={
                        isPasswordField
                            ? showPassword
                                ? "text"
                                : "password"
                            : type
                    }
                    variant="outlined"
                    placeholder={placeholder}
                    error={error}
                    autoFocus={autoFocus}
                    helperText={helperText}
                    onBlur={handleBlur}
                    InputProps={{
                        endAdornment: isPasswordField ? (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    <NombaseIconShow show={showPassword} />
                                </IconButton>
                            </InputAdornment>
                        ) : undefined,
                    }}
                />
            </div>
        );
    }
);

export default NombaseTextField;
