const NombaseTasteRadioLogo = () => {
    return (
        <svg
            width="150"
            height="22"
            viewBox="0 0 150 22"
            fill="#fff"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_1117_3187)">
                <path
                    d="M147.872 18.1846C149.101 18.1846 149.875 19.0243 149.875 20.0924C149.881 20.4753 149.77 20.8513 149.556 21.1725C149.342 21.4938 149.035 21.7459 148.674 21.897C148.313 22.048 147.914 22.0912 147.527 22.0211C147.141 21.9509 146.785 21.7706 146.504 21.5029C146.223 21.2353 146.03 20.8923 145.949 20.5175C145.868 20.1427 145.903 19.7529 146.049 19.3975C146.196 19.0422 146.447 18.7372 146.772 18.5213C147.097 18.3053 147.479 18.1882 147.872 18.1846ZM147.9 21.5299C148.819 21.5299 149.356 20.9209 149.356 20.0924C149.356 19.2639 148.807 18.6638 147.882 18.6638C146.993 18.6638 146.446 19.2639 146.446 20.1013C146.433 20.2912 146.462 20.4815 146.53 20.6596C146.599 20.8378 146.705 20.9998 146.843 21.1349C146.98 21.2699 147.146 21.375 147.328 21.4431C147.51 21.5112 147.705 21.5408 147.9 21.5299ZM147.702 20.3879V21.0776H147.144V19.087H147.976C148.068 19.0806 148.16 19.0924 148.248 19.1216C148.335 19.1508 148.416 19.1969 148.485 19.257C148.553 19.3172 148.609 19.3902 148.648 19.4718C148.688 19.5534 148.71 19.6418 148.713 19.7318C148.717 19.8428 148.689 19.9527 148.633 20.049C148.576 20.1452 148.492 20.2239 148.391 20.276L148.77 21.0776H148.194L147.9 20.3879H147.702ZM147.702 19.5482V19.9446H147.932C147.959 19.9452 147.987 19.9404 148.012 19.9305C148.037 19.9206 148.06 19.9057 148.08 19.8869C148.099 19.8681 148.114 19.8456 148.124 19.8209C148.135 19.7962 148.139 19.7697 148.139 19.743C148.139 19.7167 148.135 19.6905 148.124 19.6661C148.114 19.6417 148.099 19.6197 148.079 19.6014C148.06 19.5831 148.037 19.5689 148.011 19.5597C147.986 19.5506 147.959 19.5467 147.932 19.5482H147.702Z"
                    fill="inherit"
                />
                <path
                    d="M2.21332 3.56738H8.61064V6.68657H10.995V11.3486H8.61064V15.7575C8.61064 16.5435 9.38935 16.7405 10.0509 16.7405C10.3988 16.7405 10.7461 16.712 11.0892 16.6554V21.5816C10.262 21.7762 9.41334 21.871 8.56241 21.8638C4.37486 21.8526 2.03644 19.9426 2.03644 15.9255V11.3486H0.658203V6.68657H2.21332V3.56738Z"
                    fill="inherit"
                />
                <path
                    d="M17.9459 12.4722C18.8549 12.4788 19.7545 12.6536 20.5967 12.9872V12.4722C20.5967 11.8251 19.8754 11.4041 18.6074 11.4041C17.2859 11.4382 15.9882 11.7547 14.8058 12.3311L12.6718 8.22892C14.8055 6.95677 17.2594 6.2863 19.7606 6.29203C25.2735 6.29203 27.0216 9.27015 27.0216 12.7857V21.6282H21.403L21.1158 20.6452C20.2223 21.4871 18.9543 21.9887 17.3992 21.9887C14.4888 21.9887 12.0103 20.5265 12.0103 17.1297C12.0103 14.1068 14.6703 12.4722 17.9459 12.4722ZM19.6158 17.6223C20.2521 17.6223 20.8563 17.2842 20.8563 16.6953C20.8563 16.1064 20.2797 15.7683 19.5883 15.7683C18.8969 15.7683 18.3203 16.1221 18.3203 16.6841C18.3203 17.2461 18.9244 17.6111 19.6158 17.6111V17.6223Z"
                    fill="inherit"
                />
                <path
                    d="M30.6809 15.813C31.6319 16.4848 33.5338 17.2461 34.4848 17.2461C34.9442 17.2461 35.1188 17.0781 35.1188 16.9371C35.1188 16.6549 34.8294 16.5721 34.138 16.375C31.8409 15.6719 28.5193 14.6889 28.5193 11.2361C28.5193 8.258 31.3723 6.29199 35.0889 6.29199C38.1716 6.29199 40.0736 7.55489 41.2267 8.65209L38.1142 12.078C37.3952 11.46 36.2765 10.9585 35.4358 10.9585C34.9764 10.9585 34.7995 11.1555 34.7995 11.3234C34.7995 11.6616 35.2038 11.7713 36.2696 12.0825C38.3163 12.6714 41.4564 13.65 41.4564 16.8565C41.4564 20.2287 38.6609 22.0066 34.7421 22.0066C32.2693 21.9739 29.8932 21.065 28.0576 19.4494L30.6809 15.813Z"
                    fill="inherit"
                />
                <path
                    d="M43.9626 3.56738H50.3599V6.68657H52.7489V11.3486H50.3599V15.7575C50.3599 16.5435 51.1363 16.7405 51.8002 16.7405C52.1473 16.7406 52.4939 16.7121 52.8361 16.6554V21.5816C52.009 21.7764 51.1603 21.8712 50.3094 21.8638C46.1333 21.8638 43.7972 19.9537 43.7972 15.9366V11.3486H42.4189V6.68657H43.9764L43.9626 3.56738Z"
                    fill="inherit"
                />
                <path
                    d="M60.2993 15.616C60.529 16.543 61.5076 17.1834 62.7181 17.1834C63.2068 17.1924 63.6921 17.1027 64.1436 16.92C64.595 16.7374 65.0028 16.4657 65.3414 16.122L69.7793 18.4821C69.0902 19.3532 66.8965 21.9932 62.3437 21.9932C56.8996 21.9932 53.7871 18.3142 53.7871 13.959C53.7871 9.71795 57.0995 6.29199 61.9417 6.29199C67.156 6.29199 70.1537 9.46492 70.1537 14.0149C70.1601 14.5499 70.1309 15.0847 70.0664 15.616H60.2993ZM63.6691 12.4139C63.6571 12.0156 63.4834 11.6382 63.1863 11.3645C62.8892 11.0908 62.4928 10.9431 62.0841 10.954C61.2204 10.954 60.6163 11.6011 60.529 12.4139H63.6691Z"
                    fill="inherit"
                />
                <path
                    d="M86.1368 12.1051C85.7704 11.9686 85.3805 11.9017 84.9883 11.9081C83.5756 11.9081 82.4822 12.9202 82.4822 14.6623V21.6284H75.9126V6.68628H82.3444V8.31416C82.7738 7.71456 83.3418 7.22178 84.0026 6.8756C84.6634 6.52941 85.3984 6.33954 86.1483 6.32129L86.1368 12.1051Z"
                    fill="inherit"
                />
                <path
                    d="M92.5593 12.4722C93.4684 12.4786 94.368 12.6533 95.2101 12.9872V12.4722C95.2101 11.8251 94.4911 11.4041 93.2231 11.4041C91.9009 11.4382 90.6024 11.7547 89.4192 12.3311L87.2875 8.22893C89.4218 6.95606 91.8766 6.28554 94.3786 6.29204C99.8915 6.29204 101.64 9.27016 101.64 12.7857V21.6282H96.0187L95.7292 20.6452C94.838 21.4872 93.57 21.9887 92.0126 21.9887C89.1022 21.9887 86.626 20.5265 86.626 17.1297C86.626 14.1068 89.2745 12.4722 92.5593 12.4722ZM94.2316 17.6223C94.8655 17.6223 95.4697 17.2842 95.4697 16.6953C95.4697 16.1064 94.8954 15.7683 94.2017 15.7683C93.508 15.7683 92.9337 16.1333 92.9337 16.6953C92.9337 17.2573 93.5401 17.6111 94.2316 17.6111V17.6223Z"
                    fill="inherit"
                />
                <path
                    d="M119.846 21.6285H113.564V20.7575C112.757 21.4292 111.402 21.9935 109.79 21.9935C105.175 21.9935 102.648 18.7355 102.648 14.1295C102.648 9.5235 105.175 6.32146 109.794 6.32146C111.207 6.32146 112.647 6.91037 113.281 7.44105V1.34375H119.851L119.846 21.6285ZM111.317 11.3484C110.019 11.3484 109.213 12.5285 109.213 14.1295C109.213 15.8693 110.019 16.9083 111.317 16.9083C112.73 16.9083 113.449 15.6723 113.449 14.1295C113.444 12.3874 112.613 11.3484 111.317 11.3484Z"
                    fill="inherit"
                />
                <path
                    d="M120.997 3.17293C120.997 1.38158 122.375 0 124.569 0C126.763 0 128.146 1.3771 128.146 3.17293C128.146 4.96875 126.767 6.32122 124.571 6.32122C122.375 6.32122 120.997 4.94412 120.997 3.17293ZM121.284 7.42738H127.854V21.6283H121.284V7.42738Z"
                    fill="inherit"
                />
                <path
                    d="M137.623 21.9932C132.464 21.9932 128.949 18.6209 128.949 14.1291C128.949 9.63734 132.464 6.29199 137.623 6.29199C142.782 6.29199 146.292 9.62838 146.292 14.1291C146.292 18.6299 142.78 21.9932 137.623 21.9932ZM137.623 16.9371C138.918 16.9371 139.667 15.7839 139.667 14.1291C139.667 12.4744 138.918 11.3481 137.623 11.3481C136.327 11.3481 135.576 12.4677 135.576 14.1291C135.576 15.7906 136.325 16.9371 137.623 16.9371Z"
                    fill="inherit"
                />
            </g>
            <defs>
                <clipPath id="clip0_1117_3187">
                    <rect
                        width="149.217"
                        height="22"
                        fill="inherit"
                        transform="translate(0.658203)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export default NombaseTasteRadioLogo;
