import {makeStyles} from '@material-ui/core';
//import { mixin } from '../../utils/mixin';

// '@media(max-width: 768px)': {}
export const useStyles = makeStyles((theme) => ({
    wrapper: {
        // width: '100%',
        // maxWidth: 505,
        border: '1px solid #BFBFBF',
        background: '#f8f8f8',
        borderRadius: theme.shape.borderRadius,
    }
}))
