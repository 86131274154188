const NombaseBrewboundLogo = () => {
    return (
        <svg
            width="126"
            height="22"
            viewBox="0 0 126 22"
            fill="#fff"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_1117_3203)">
                <path
                    d="M7.05979 0H0.0195312V22H7.05979C9.64493 22 11.0467 20.6249 11.0467 18.037V12.9773C11.0467 11.9636 10.3048 11.1894 9.31499 11.0244V10.97C10.3048 10.8051 11.0467 10.0346 11.0467 9.01713V3.96297C11.0467 1.37506 9.64493 0 7.05979 0ZM7.39249 17.2702C7.39249 18.0121 7.00726 18.3697 6.29208 18.3697H3.78712V12.8151H6.28655C7.03214 12.8151 7.39249 13.2004 7.39249 13.9165V17.2702ZM7.39249 8.11026C7.39249 8.79963 7.00449 9.18487 6.28655 9.18487H3.78712V3.63026H6.28655C7.03214 3.63026 7.39249 4.01458 7.39249 4.72976V8.11026Z"
                    fill="inherit"
                />
                <path
                    d="M20.3801 0H13.0376V22H16.3379C16.397 22.0054 16.4565 21.9977 16.5123 21.9775C16.5681 21.9573 16.6188 21.9251 16.6607 21.8832C16.7026 21.8412 16.7349 21.7906 16.755 21.7348C16.7752 21.679 16.7829 21.6195 16.7775 21.5604V14.6298H18.6751L20.5184 21.6148C20.5737 21.8627 20.711 22 20.9857 22H24.3689C24.3689 22 24.2675 21.6553 24.2307 21.5051L22.3331 14.2999C23.6528 13.7229 24.3404 12.5128 24.3404 10.6696V3.96297C24.3404 1.37506 22.9681 0 20.3801 0ZM20.6004 9.90005C20.6004 10.6152 20.2152 11.0005 19.5 11.0005H16.7775V3.6275H19.5C20.2152 3.6275 20.6004 4.01181 20.6004 4.72699V9.90005Z"
                    fill="inherit"
                />
                <path
                    d="M36.6733 0H26.1401V22H37.1129V18.8103C37.1129 18.508 37.0033 18.3697 36.6733 18.3697H29.798V12.8151H35.0513C35.3278 12.8151 35.4909 12.6502 35.4909 12.3479V9.6254C35.4909 9.32219 35.3259 9.15722 35.0513 9.15722H29.798V3.65791H36.6733C37.0033 3.65791 37.1129 3.46529 37.1129 3.18973V0H36.6733Z"
                    fill="inherit"
                />
                <path
                    d="M55.9333 0H53.3205C52.9906 0 52.7676 0.192619 52.7436 0.522559L51.6155 13.3921H51.4782L48.7834 0.49491C48.7004 0.16497 48.5069 0 48.1779 0H46.5282C46.1982 0 46.0056 0.16497 45.9227 0.49491L43.2011 13.3921H43.0592L41.9911 0.522559C41.9634 0.192619 41.7431 0 41.4132 0H38.2207C38.2207 0 38.2659 0.411964 38.2778 0.577856L40.533 21.4738C40.5469 21.6525 40.5764 21.9963 40.5764 21.9963H43.861C44.191 21.9963 44.3836 21.8313 44.4656 21.5291L47.353 9.0752L50.2101 21.5051C50.293 21.835 50.4865 22 50.8156 22H54.0311C54.0311 22 54.0735 21.6655 54.0882 21.4774L56.4559 0.577856L56.5149 0H55.9333Z"
                    fill="inherit"
                />
                <path
                    d="M65.1062 0H58.0659V22H65.1062C67.6867 22 69.094 20.6249 69.094 18.037V12.9773C69.094 11.9636 68.3512 11.1894 67.3614 11.0244V10.97C68.3512 10.8051 69.094 10.0346 69.094 9.01713V3.96297C69.094 1.37506 67.6913 0 65.1062 0ZM65.4361 17.2702C65.4361 18.0121 65.0509 18.3697 64.3366 18.3697H61.8335V12.8151H64.3366C65.0739 12.8151 65.4361 13.2004 65.4361 13.9146V17.2702ZM65.4361 8.1121C65.4361 8.79963 65.0509 9.18487 64.3366 9.18487H61.8335V3.63026H64.3366C65.0739 3.63026 65.4361 4.01458 65.4361 4.72976V8.1121Z"
                    fill="inherit"
                />
                <path
                    d="M77.9342 0H74.8551C72.2745 0 70.8921 1.37506 70.8921 3.96297V18.0398C70.8921 20.6203 72.2671 22.0028 74.8551 22.0028H77.9351C80.5203 22.0028 81.8981 20.6277 81.8981 18.0398V3.96297C81.8944 1.37506 80.5193 0 77.9342 0ZM78.3462 17.3523C78.3462 18.0951 77.9618 18.4527 77.2467 18.4527H75.6513C74.9371 18.4527 74.5518 18.0951 74.5518 17.3523V4.64773C74.5518 3.93255 74.9371 3.54732 75.6513 3.54732H77.2513C77.9664 3.54732 78.3508 3.93255 78.3508 4.64773L78.3462 17.3523Z"
                    fill="inherit"
                />
                <path
                    d="M106.098 0.000398621H104.936C104.633 0.000398621 104.495 0.165369 104.495 0.440012V12.0451H104.33L100.153 0.357987C100.126 0.249498 100.061 0.154053 99.9706 0.0885897C99.88 0.023127 99.7691 -0.00811859 99.6577 0.000398621H96.4956V22.0004H99.7959C100.098 22.0004 100.236 21.8354 100.236 21.5608V9.90044H100.373L104.58 21.6428C104.607 21.7513 104.672 21.8466 104.763 21.9121C104.853 21.9775 104.964 22.0088 105.076 22.0004H108.238V0.000398621H106.098Z"
                    fill="inherit"
                />
                <path
                    d="M117.078 0H110.039V22H117.078C119.659 22 121.041 20.6249 121.041 18.037V3.98785C121.039 1.40271 119.664 0 117.078 0ZM117.408 17.2702C117.408 18.0121 117.024 18.3697 116.309 18.3697H113.779V3.63026H116.309C117.024 3.63026 117.408 4.01458 117.408 4.7574V17.2702Z"
                    fill="inherit"
                />
                <path
                    d="M93.9547 0H91.4249C91.2782 0 91.1375 0.0582594 91.0338 0.161962C90.9301 0.265664 90.8719 0.406315 90.8719 0.552972V17.2702C90.8719 18.0121 90.4876 18.3697 89.7724 18.3697H88.3448C87.6296 18.3697 87.2453 18.0121 87.2453 17.2702V0.552972C87.2453 0.406315 87.187 0.265664 87.0833 0.161962C86.9796 0.0582594 86.839 0 86.6923 0L83.5054 0V18.0398C83.5054 20.6203 84.8795 22.0028 87.4683 22.0028H90.5484C93.1289 22.0028 94.5114 20.6277 94.5114 18.0398V0H93.9547Z"
                    fill="inherit"
                />
                <path
                    d="M123.724 18.2207C124.91 18.2207 125.659 19.0502 125.659 20.1091C125.659 21.1404 124.911 21.9975 123.743 21.9975C122.575 21.9975 121.827 21.1763 121.827 20.1183C121.827 19.0603 122.595 18.2207 123.724 18.2207ZM123.742 21.533C124.627 21.533 125.148 20.9303 125.148 20.1091C125.148 19.2704 124.618 18.6953 123.724 18.6953C122.867 18.6953 122.341 19.2879 122.341 20.1183C122.338 20.9211 122.871 21.533 123.742 21.533ZM123.551 20.4013V21.0851H123.012V19.1147H123.815C124.262 19.1147 124.527 19.416 124.527 19.7534C124.531 19.8631 124.504 19.9717 124.449 20.0668C124.394 20.162 124.314 20.2399 124.217 20.2916L124.586 21.0851H124.033L123.75 20.4013H123.551ZM123.551 19.5718V19.9644H123.77C123.796 19.9649 123.823 19.9601 123.847 19.9501C123.872 19.9402 123.894 19.9254 123.913 19.9067C123.932 19.8879 123.947 19.8656 123.957 19.841C123.966 19.8164 123.971 19.79 123.971 19.7635C123.971 19.7374 123.966 19.7115 123.956 19.6875C123.946 19.6634 123.931 19.6417 123.912 19.6237C123.893 19.6056 123.871 19.5917 123.847 19.5828C123.822 19.5739 123.796 19.5701 123.77 19.5718H123.551Z"
                    fill="inherit"
                />
            </g>
            <defs>
                <clipPath id="clip0_1117_3203">
                    <rect
                        width="125.639"
                        height="22"
                        fill="inherit"
                        transform="translate(0.0195312)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export default NombaseBrewboundLogo;
