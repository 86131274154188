import Box from "@material-ui/core/Box";
import {observer} from "mobx-react-lite";
import Link from "@material-ui/core/Link";
import {Divider} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {Link as RouterLink, useHistory} from "react-router-dom";

import Pages, {PageUrl} from "../../Constants/Pages";
import OneColumnLayout from "../../Components/OneColumnLayout";
import Header from "../../Components/Header";
import SubscribeButton from "../../Components/SubscribeButton";
import Store from "../../store/store";
import {HOST} from "../../Constants/Constants";
import NombaseRegister from "./components/nombase/NombaseRegister";

const Register = observer(() => {
    const history = useHistory();
    return (
        <>
            {(() => {
                switch (Store.host) {
                    case HOST.NOMBASE:
                        return <NombaseRegister />;
                    default:
                        return (
                            <div data-cy="register">
                                <OneColumnLayout title={"Register"}>
                                    <Header
                                        title={"Register"}
                                        secondTitle={
                                            "Your registration works with certain areas of BevNET, NOSH, and Brewbound. Want full access? Become a subscriber to BevNET/NOSH or Brewbound."
                                        }
                                    />
                                    <Box
                                        sx={{
                                            flexGrow: 1,
                                            mt: 3,
                                        }}
                                    >
                                        <Box sx={{mt: 2}}>
                                            <Button
                                                style={{
                                                    borderRadius: 5,
                                                }}
                                                color="primary"
                                                fullWidth
                                                size="large"
                                                type="submit"
                                                variant="contained"
                                                onClick={() => {
                                                    history.push(
                                                        PageUrl(
                                                            Pages.REGISTER_FREE,
                                                            Store.returnUrl
                                                        )
                                                    );
                                                }}
                                                data-cy="freeRegistration"
                                            >
                                                Free Registration (limited
                                                access)
                                            </Button>
                                        </Box>
                                        <Box sx={{mt: 2}}>
                                            <SubscribeButton />
                                        </Box>
                                    </Box>
                                    <Divider sx={{my: 3}} />
                                    <Link
                                        color="textSecondary"
                                        component={RouterLink}
                                        to={PageUrl(
                                            Pages.LOGIN,
                                            Store.returnUrl
                                        )}
                                        variant="body2"
                                        data-cy="return"
                                    >
                                        Return to Login
                                    </Link>
                                </OneColumnLayout>
                            </div>
                        );
                }
            })()}
        </>
    );
});

export default Register;
