import {ReactChild, ReactFragment, ReactPortal} from "react";

export interface IChildrenProps {
    children?:
        | ReactChild
        | ReactFragment
        | ReactPortal
        | boolean
        | null
        | undefined;
}

export const hostNames: { [key: string]: number } = {
    'bevnet.com': 1,
    'localhost:3101': 1,

    'localhost:3102': 2,
    'brewbound.com': 2,

    'localhost:3103': 3,
    'nosh.com': 3,

    'localhost:5001': 4,
    'tasteradio.com': 4,

    'localhost:3105': 5,
    'nombase.com': 5,
}
