import {makeStyles} from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    nombase_caption: {
        "&.MuiTypography-root": {
            fontSize: 13,
            color: "#707070",
        },
        "& a": {
            fontFamily: "adelle-sans",
            fontSize: 13,
            fontWeight: 500,
            color: "#15A6FF",
            transition: "color 250ms cubic-bezier(0.4, 0, 0.2, 1)",
            textDecoration: "none",
            "&:hover": {
                color: "#1594E1",
                textDecoration: "underline",
            },
        },
    },
}));
